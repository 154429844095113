import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';

const EventMetrics = ({ eventMetrics }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

    // Aseguramos que eventMetrics es un objeto
    eventMetrics = eventMetrics || {};

    const formatData = (data, key) => {
        if (!data) return [];
        const genderMapping = {
            male: 'Hombre',
            female: 'Mujer',
            other: 'Otros'
        };
        return Object.entries(data).map(([label, value]) => {
            const porcentaje = value != null ? parseFloat(parseFloat(value).toFixed(1)) : 0;
            return {
                [key]: genderMapping[label] || label,
                porcentaje: porcentaje,
            };
        });
    };

    const hasNonZeroPercentage = (data) => {
        return data.some(item => item.porcentaje > 0);
    };

    const ageDistributionData = formatData(eventMetrics.event_ages_distribution, 'ageRange');
    const genderDistributionData = formatData(eventMetrics.event_genders_distribution, 'gender');

    let ratingDistributionData = [];
    let averageRating = null;
    if (eventMetrics.event_rating_distribution) {
        ratingDistributionData = formatData(eventMetrics.event_rating_distribution.rating_distribution, 'rating');
        averageRating = eventMetrics.event_rating_distribution.average_rating;
    }

    const totalAttendees = eventMetrics.event_attendees || 0;
    const noAttendees = totalAttendees === 0;

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Métricas del Evento</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                {/* Tarjeta de Total de Asistentes */}
                <div className="bg-gray-100 p-6 rounded-lg text-center shadow-lg">
                    <h3 className="text-lg font-semibold">Total de Asistentes</h3>
                    <p className="text-4xl font-bold text-custom-primary">{totalAttendees}</p>
                </div>

                {/* Tarjeta de Invitaciones Aceptadas */}
                <div className="bg-gray-100 p-6 rounded-lg text-center shadow-lg">
                    <h3 className="text-lg font-semibold">Invitaciones Aceptadas</h3>
                    {eventMetrics.event_accepted_invitations != null && !noAttendees ? (
                        <p className="text-2xl font-bold text-green-700">
                            {parseFloat(eventMetrics.event_accepted_invitations).toFixed(1)}%
                        </p>
                    ) : (
                        <p className="text-center text-gray-500">No disponible</p>
                    )}
                </div>
            </div>

            {/* Gráficos de Distribución por Edad y Género */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                {/* Distribución por Edad */}
                <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
                    <h3 className="text-lg font-semibold mb-4">Distribución por Edad</h3>
                    {!noAttendees && ageDistributionData.length > 0 && hasNonZeroPercentage(ageDistributionData) ? (
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={ageDistributionData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ageRange" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="porcentaje" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    ) : (
                        <p className="text-center text-gray-500">No disponible</p>
                    )}
                </div>

                {/* Distribución por Género */}
                <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
                    <h3 className="text-lg font-semibold mb-4">Distribución por Género</h3>
                    {!noAttendees && genderDistributionData.length > 0 && hasNonZeroPercentage(genderDistributionData) ? (
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={genderDistributionData}
                                    dataKey="porcentaje"
                                    nameKey="gender"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                    label
                                >
                                    {genderDistributionData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    ) : (
                        <p className="text-center text-gray-500">No disponible</p>
                    )}
                </div>
            </div>

            {/* Gráfico de Distribución de Calificaciones */}
            <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-4">
                <h3 className="text-lg font-semibold mb-4">Distribución de Calificaciones</h3>
                {ratingDistributionData.length > 0 && hasNonZeroPercentage(ratingDistributionData) ? (
                    <>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={ratingDistributionData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="rating" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="porcentaje" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                        {averageRating != null ? (
                            <p className="text-lg font-semibold">
                                Calificación Promedio: {parseFloat(averageRating).toFixed(1)}{' '}
                                <span role="img" aria-label="star">⭐</span>
                            </p>
                        ) : (
                            <p className="text-center text-gray-500">Calificación promedio no disponible.</p>
                        )}
                    </>
                ) : (
                    <p className="text-center text-gray-500">No disponible</p>
                )}
            </div>
        </div>
    );
};

export default EventMetrics;
