import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './calendar.css';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatDate, formatTimeRange, formatDateTime, parseDate } from '../../services/format';
import { getEventsCalendar } from '../../services/api';  // Importar la función desde api.js
import { Tooltip } from 'react-tooltip';

const CalendarView = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const events = await getEventsCalendar(getAccessTokenSilently);
                setEvents(events);
            } catch (error) {
                console.error('Error al obtener eventos', error);
            }
        };

        fetchEvents();
    }, [getAccessTokenSilently]);

    const areDatesOnSameDay = (date1, date2) => {
        const d1 = parseDate(date1);
        const d2 = new Date(date2);


        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    };

    const tileContent = ({ date, view }) => {
        // Filtrar eventos que ocurren en la fecha actual
        if (view === 'month') { 
            const eventDetails = events.filter(event => areDatesOnSameDay(event.start_date, date));
    
            // Ordenar eventos por la hora de inicio
            eventDetails.sort((a, b) => parseDate(a.start_date) - parseDate(b.start_date));
    
            if (eventDetails.length > 3) {
                return (
                    <div 
                        className="event-list"
                        data-tooltip-id={`tooltip-${format(date, 'yyyy-MM-dd')}`}
                        data-tooltip-content={`${eventDetails.length} Eventos`}
                        data-tooltip-place="top"
                        data-tooltip-delay-show="20"
                    >
                        <div className="event-item event-item-multiple">
                            <span className="event-time">
                                {eventDetails.length} Eventos
                            </span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="event-list">
                        {eventDetails.map(event => (
                            <div 
                                key={event.id_event} 
                                className="event-item"
                                data-tooltip-id={`tooltip-${event.id_event}`}
                                data-tooltip-content={`${format(parseDate(event.start_date), 'HH:mm')} ${event.title}`}
                                data-tooltip-place="top"
                                data-tooltip-delay-show="20"
                            >
                                <span className="event-time">
                                    {format(new Date(parseDate(event.start_date)), 'HH:mm')} - {event.title}
                                </span>
                            </div>
                        ))}
                    </div>
                );
            }
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
    };

    const handleEventDetails = (id) => {
        navigate(`/event/${id}`);
    };

    const getEventDetails = () => {
        if (!selectedDate) return <p className="text-gray-500 italic">Selecciona una fecha para ver los eventos.</p>;

        // Filter events on the selected date
        const eventsOnSelectedDate = events.filter(event => areDatesOnSameDay(event.start_date, selectedDate));
        
        // Sort events by start_date
        eventsOnSelectedDate.sort((a, b) => parseDate(a.start_date) - parseDate(b.start_date));

        if (eventsOnSelectedDate.length === 0) {
            return <p className="text-gray-500 italic">No tienes eventos creados en esta fecha.</p>;
        }

        const defaultImage = '../../images/evento_default_foto.png'; // Ruta de la imagen predeterminada

        return eventsOnSelectedDate.map((event) => {
            // Verificar si link_photo contiene múltiples URLs separadas por comas y usar solo la primera
            const linkPhoto = event.link_photo && event.link_photo.includes(',')
                ? event.link_photo.split(',')[0].trim() // Tomar la primera URL
                : event.link_photo;

            const imageUrl = linkPhoto && !linkPhoto.endsWith('null') ? linkPhoto : defaultImage;

            return (
                <div key={event.id_event} className="bg-white shadow-md rounded-lg p-4 mb-4">
                    <img 
                        src={imageUrl}
                        className="mb-4 rounded-lg h-32 w-full object-cover" 
                    />
                    <h2 className="text-xl font-semibold mb-2">{event.title}</h2>
                    <p className="text-gray-600 mb-2 font-semibold">
                        Fecha: {formatDateTime(event.start_date)} - {formatDateTime(event.end_date)} hrs
                    </p>
                    <p className="text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">{event.description}</p>

                    <button
                        onClick={() => handleEventDetails(event.id_event)}
                        className="mt-2 bg-custom-primary hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded inline-block"
                    >
                        Ver Detalles
                    </button>
                </div>
            );
        });
    };

    return (
        <div className="calendar-container">
            <div className="calendar">
                <Calendar
                    tileContent={tileContent}
                    onClickDay={handleDateClick}
                    className="custom-calendar"
                    value = {selectedDate}
                    navigationLabel={({ date }) => format(date, 'MMMM yyyy', { locale: es }).toUpperCase()}
                    prev2Label={<span className="arrow">‹‹</span>}
                    prevLabel={<span className="arrow">‹</span>}
                    nextLabel={<span className="arrow">›</span>}
                    next2Label={<span className="arrow">››</span>}
                />
            </div>
            <div className="event-details-container">
                <h2 className="text-2xl font-bold mb-4">Eventos: {selectedDate ? format(selectedDate, 'PPP', { locale: es }) : ''}</h2>
                {getEventDetails()}
            </div>
            {events.map(event => (
                <Tooltip
                    key={event.id_event}
                    id={`tooltip-${event.id_event}`}
                    place="top"
                    effect="solid"
                />
            ))}
            {events.reduce((dates, event) => {
                const dateKey = format(parseDate(event.start_date), 'yyyy-MM-dd');
                if (!dates.includes(dateKey)) dates.push(dateKey);
                return dates;
            }, []).map(dateKey => (
                <Tooltip
                    key={dateKey}
                    id={`tooltip-${dateKey}`}
                    content={`${events.filter(event => areDatesOnSameDay(event.start_date, new Date(dateKey))).length} Eventos`}
                    place="top"
                    effect="solid"
                />
            ))}
        </div>
    );
};

export default CalendarView;
