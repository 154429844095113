import React, { useState, useEffect } from 'react';
import { removeMemberFromGroup, addMemberToGroup, updateGroupName, deleteUserGroup } from '../../services/api.js';
import { useAuth0 } from "@auth0/auth0-react";
import { FaEdit } from 'react-icons/fa';

const GroupDetailsModal = ({ isOpen, onClose, groupId, groups, followers, onGroupUpdated, onGroupDeleted}) => {
    const [group, setGroup] = useState(null); // Detalles del grupo actual
    const [groupName, setGroupName] = useState('');
    const [groupMembers, setGroupMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFollowers, setSelectedFollowers] = useState([]);
    const [addingMembers, setAddingMembers] = useState(false);
    const [isEditingGroupName, setIsEditingGroupName] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    // Busca el grupo localmente cuando se abra el modal
    useEffect(() => {
        if (isOpen && groupId && groups) {
            const selectedGroup = groups.find((g) => g.group_id === groupId) || null;
            setGroup(selectedGroup);
            setGroupName(selectedGroup?.group_name || '');
            setGroupMembers(selectedGroup?.members || []);
        }
    }, [isOpen, groupId, groups]);

    // Resto del código para manejar actualizaciones, eliminar miembros, etc.
    const handleRemoveMember = async (memberId) => {
        try {
            const token = await getAccessTokenSilently();
            await removeMemberFromGroup(token, groupId, memberId);
            setGroupMembers(groupMembers.filter((member) => member.id_user !== memberId));
        } catch (error) {
            console.error('Error removing member:', error);
        }
    };

    const handleAddMembers = async () => {
        try {
            const token = await getAccessTokenSilently();
            const newMembers = selectedFollowers.filter(
                (followerId) => !groupMembers.some((member) => member.id_user === followerId)
            );
            await Promise.all(newMembers.map((followerId) =>
                addMemberToGroup(token, groupId, followerId)
            ));
            setGroupMembers([...groupMembers, ...followers.filter((f) => newMembers.includes(f.id_user))]);
            setAddingMembers(false);
        } catch (error) {
            console.error('Error adding members:', error);
        }
    };

    const handleUpdateGroupName = async () => {
        try {
            const token = await getAccessTokenSilently();
            await updateGroupName(token, groupId, groupName);
            onGroupUpdated({ ...group, group_name: groupName });
            setIsEditingGroupName(false);
        } catch (error) {
            console.error('Error updating group name:', error);
        }
    };

    const handleDeleteGroup = async () => {
        try {
            const token = await getAccessTokenSilently();
            await deleteUserGroup(token, groupId);
            onGroupDeleted(groupId);
            onClose();
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    if (!isOpen || !group) return null;

    const filteredFollowers = followers.filter((follower) =>
        follower.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-lg">
                <h2 className="text-2xl text-custom-blue font-bold mb-4 flex justify-between">
                    <span>Detalles del Grupo</span>
                    <button onClick={onClose} className="text-custom-wine-red">×</button>
                </h2>

                {!addingMembers ? (
                    <>
                        {/* Nombre del grupo */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-bold mb-2">Nombre del Grupo:</label>
                            <div className="flex items-center">
                                {isEditingGroupName ? (
                                    <>
                                        <input
                                            type="text"
                                            className="flex-grow p-2 border border-gray-300 rounded-md"
                                            value={groupName}
                                            onChange={(e) => setGroupName(e.target.value)}
                                        />
                                        <button
                                            onClick={handleUpdateGroupName}
                                            className="ml-2 bg-custom-blue text-white px-4 py-2 rounded-md"
                                        >
                                            Guardar
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <span className="text-gray-700 text-lg">{groupName}</span>
                                        <FaEdit
                                            onClick={() => setIsEditingGroupName(true)}
                                            className="ml-2 text-gray-800 cursor-pointer"
                                        />
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Miembros del grupo */}
                        <h3 className="text-md font-bold text-gray-700 mb-3">Miembros del Grupo</h3>
                        <div className="overflow-y-auto max-h-64 mb-4">
                            <ul>
                                {groupMembers.map((member) => (
                                    <li
                                        key={member.id_user}
                                        className="flex items-center justify-between mb-2"
                                    >
                                        <span>{member.name}</span>
                                        <button
                                            onClick={() => handleRemoveMember(member.id_user)}
                                            className="bg-custom-wine-red text-white px-2 py-1 rounded-md"
                                        >
                                            ×
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex space-x-4">
                            <button
                                onClick={() => setAddingMembers(true)}
                                className="bg-custom-blue text-white px-4 py-2 rounded-md"
                            >
                                Añadir Miembros
                            </button>
                            <button
                                onClick={handleDeleteGroup}
                                className="bg-custom-wine-red text-white px-4 py-2 rounded-md"
                            >
                                Eliminar Grupo
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        {/* Agregar miembros */}
                        <h3 className="text-lg font-semibold mb-3">{groupName}</h3>
                        <input
                            type="text"
                            placeholder="Buscar por nombre"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="p-2 border border-gray-300 rounded-lg w-full mb-4"
                        />
                        <div className="overflow-y-auto max-h-64 mb-4">
                            <ul>
                                {filteredFollowers.map((follower) => {
                                    const isMember = groupMembers.some((member) => member.id_user === follower.id_user);
                                    return (
                                        <li
                                            key={follower.id_user}
                                            className="flex items-center justify-between mb-2"
                                        >
                                            <span>{follower.name}</span>
                                            <button
                                                className={`px-3 py-1 rounded-full ${
                                                    isMember
                                                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                                        : 'bg-gray-300 text-gray-700'
                                                }`}
                                                disabled={isMember}
                                                onClick={() =>
                                                    setSelectedFollowers((prev) =>
                                                        prev.includes(follower.id_user)
                                                            ? prev.filter((id) => id !== follower.id_user) // Deselecciona si ya está seleccionado
                                                            : [...prev, follower.id_user] // Selecciona si no está seleccionado
                                                    )
                                                }
                                            >
                                                {isMember
                                                    ? 'Ya es miembro'
                                                    : selectedFollowers.includes(follower.id_user)
                                                    ? 'Seleccionado'
                                                    : 'Seleccionar'}
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={handleAddMembers}
                                className="bg-green-800 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                            >
                                Añadir
                            </button>
                            <button
                                onClick={() => setAddingMembers(false)}
                                className="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded-md"
                            >
                                Volver
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default GroupDetailsModal;
