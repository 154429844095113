import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { getUserAvailability} from '../../services/api.js';

const FollowersModal = ({
    isOpen,
    onClose,
    followers,
    selectedFollowers,
    handleFollowerSelect,
    groups,
    selectedGroups,
    handleGroupSelect
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableHours, setAvailableHours] = useState([]);
    const [loadingAvailability, setLoadingAvailability] = useState(false);
    const [availabilityError, setAvailabilityError] = useState(null);
    const [selectedHour, setSelectedHour] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const [availableUsers, setAvailableUsers] = useState([]);
    const [expandedHours, setExpandedHours] = useState({});


    const filteredGroups = groups.filter(group =>
        group.group_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredFollowers = followers.filter(follower =>
        follower.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDateChange = async (date) => {
        setSelectedDate(date);
        setSelectedHour(null); 
        setLoadingAvailability(true);
    
        try {
            const token = await getAccessTokenSilently();
            const formattedDate = new Date(date).toISOString().split("T")[0];
    
           
            const followersAvailability = await Promise.all(
                followers.map(async (follower) => {
                    try {
                        const availability = await getUserAvailability(
                            token,
                            follower.id_user,
                            formattedDate,
                            formattedDate
                        );
    
                        //console.log(`Usuario: ${follower.name}`, "Disponibilidad recibida:", availability);
    
                        
                        if (Array.isArray(availability)) {
                            return { ...follower, availability };
                        } else if (availability.detail && availability.detail.includes("private")) {
                            console.warn(`Usuario privado excluido: ${follower.id_user}`);
                            return null; 
                        } else {
                            console.warn(`Respuesta inesperada para el usuario ${follower.id_user}:`, availability);
                            return null;
                        }
                    } catch (error) {
                        console.error(`Error fetching availability for user ${follower.id_user}:`, error);
                        return null;
                    }
                })
            );
    
            const validFollowers = followersAvailability.filter(Boolean);
            const groupedByHour = groupUsersByHour(validFollowers);
    
            setAvailableHours(groupedByHour);
            setAvailableUsers(validFollowers);
        } catch (error) {
            console.error("Error fetching availability for date:", error);
            setAvailableHours([]);
            setAvailableUsers([]);
        } finally {
            setLoadingAvailability(false);
        }
    };
    
    const groupUsersByHour = (followersAvailability) => {
        const groupedHours = Array.from({ length: 24 }, (_, hour) => ({
            hour: `${hour}:00`,
            users: [],
        }));
    
        followersAvailability.forEach((user) => {
            if (user.availability && Array.isArray(user.availability)) {
                const busyHours = new Set();
    
                user.availability.forEach(({ start_date, end_date }) => {
                    const startHourUTC = new Date(start_date).getHours();
                    const endHourUTC = new Date(end_date).getHours();
                    const startHour = (startHourUTC - 3 + 24) % 24;
                    const endHour = (endHourUTC - 3 + 24) % 24;
                    const endMinutes = new Date(end_date).getMinutes();
    
                    for (let hour = startHour; hour < endHour; hour++) {
                        busyHours.add(hour);
                    }
                    if (endMinutes > 0) {
                        busyHours.add(endHour);
                    }
                });
                groupedHours.forEach(({ hour }, index) => {
                    if (!busyHours.has(index)) {
                        groupedHours[index].users.push(user);
                    }
                });
            } else if (user.availability && user.availability.length === 0) {
                groupedHours.forEach(({ users }) => {
                    users.push(user);
                });
            } else {
                console.warn(`Unexpected data for user ${user.id_user}:`, user.availability);
            }
        });
    
        return groupedHours;
    };
    

    const toggleGroupSelection = (groupId) => {
        const selectedGroup = groups?.find(g => g.group_id === groupId);
        if (!selectedGroup) return;

        if ((selectedGroups || []).includes(groupId)) {
            handleGroupSelect(groupId);

            selectedGroup.members?.forEach(member => {
                const stillSelectedByOtherGroup = groups
                    ?.filter(g => g.group_id !== groupId && selectedGroups.includes(g.group_id))
                    .some(g => g.members.some(m => m.id_user === member.id_user));

                if (!stillSelectedByOtherGroup) {
                    handleFollowerSelect(member.id_user, false);
                }
            });
        } else {
            handleGroupSelect(groupId);

            selectedGroup.members?.forEach(member => {
                if (!selectedFollowers.includes(member.id_user) && followers.some(follower => follower.id_user === member.id_user)) {
                    handleFollowerSelect(member.id_user, true);
                }
            });
        }
    };

    const toggleHourExpansion = (hour) => {
        setExpandedHours((prev) => ({
            ...prev,
            [hour]: !prev[hour], // Alterna entre expandido y colapsado
        }));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        {/* Calendario */}
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <h3 className="text-lg font-semibold mb-4">Selecciona una fecha</h3>
                                <Calendar
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    className="border rounded-lg"
                                    formatShortWeekday={(locale, date) => ['D', 'L', 'M', 'X', 'J', 'V', 'S'][date.getDay()]}
                                />
                            </div>


                            <div className="col-span-2">
                                <h3 className="text-lg font-semibold mb-4">Usuarios disponibles</h3>
                                {loadingAvailability ? (
                                    <p>Cargando usuarios disponibles...</p>
                                ) : availabilityError ? (
                                    <p className="text-red-500">{availabilityError}</p>
                                ) : availableHours.length > 0 ? (
                                    <div className="space-y-6 overflow-y-scroll max-h-80">
                                        {availableHours.map(({ hour, users }) => (
                                            <div key={hour}>
                                                <h4 className="font-semibold">
                                                    {hour} - {((parseInt(hour.split(":")[0]) + 1) % 24)}:00
                                                </h4>
                                                <div className="grid grid-cols-3 gap-4 p-2">
                                                    {users.slice(0, expandedHours[hour] ? users.length : 3).map((user) => (
                                                        <div
                                                            key={user.id_user}
                                                            className="flex flex-col items-center"
                                                        >
                                                            <img
                                                                src={user.profile_link || "https://via.placeholder.com/50"}
                                                                alt={user.name}
                                                                className="w-12 h-12 rounded-full mb-2 object-cover"
                                                            />
                                                            <span className="text-sm text-gray-700">
                                                                {user.name}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                {users.length > 3 && (
                                                    <button
                                                        className="text-blue-500 text-sm mt-2"
                                                        onClick={() => toggleHourExpansion(hour)}
                                                    >
                                                        {expandedHours[hour] ? "Ocultar" : "Ver más"}
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-center">No hay usuarios disponibles para este día.</p>
                                )}
                            </div>



                        </div>


                        {/* Barra de búsqueda */}
                        <div className="mt-6">
                            <input
                                type="text"
                                placeholder="Buscar por nombre"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="p-2 border border-gray-300 rounded-lg w-full mb-4"
                            />
                        </div>

                        {/* Grupos */}
                        <div>
                            <h4 className="text-lg font-semibold mb-4">Grupos</h4>
                            {filteredGroups.map(group => (
                                <div key={group.group_id} className="flex items-center justify-between mb-2">
                                    <span>{group.group_name}</span>
                                    <button
                                        className={`px-3 py-1 rounded-full ${selectedGroups.includes(group.group_id) ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                                        onClick={() => toggleGroupSelection(group.group_id)}
                                    >
                                        {selectedGroups.includes(group.group_id) ? 'Seleccionado' : 'Seleccionar'}
                                    </button>
                                </div>
                            ))}
                        </div>

                        {/* Seguidores */}
                        <div className="mt-6">
                            <h4 className="text-lg font-semibold mb-4">Seguidores</h4>
                            {filteredFollowers.map(follower => (
                                <label key={follower.id_user} className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedFollowers.includes(follower.id_user)}
                                        onChange={() => handleFollowerSelect(follower.id_user)}
                                        className="mr-2"
                                    />
                                    <img
                                        src={follower.profile_link || 'https://via.placeholder.com/50'}
                                        alt={follower.name}
                                        className="w-10 h-10 rounded-full object-cover mr-2"
                                    />
                                    <span>{follower.name}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded inline-block"
                            onClick={onClose}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FollowersModal;
