import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserInfoById, createFollowerRequest, deleteFollowerRequest, getUserEvents, acceptFollowerRequest, ReportUser, getReport} from '../../services/api';
import { useAuth0 } from "@auth0/auth0-react";
import { PhoneIcon, GlobeAltIcon, KeyIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import config from '../../configroutes';
import EventCardProfile from '../../components/EventCard/EventCardProfile.js'
import ReportModal from '../../components/Report/ReportModal';
import AlertReportModal from '../../components/Report/AlertReportModal';


const OtherProfileView = () => {
    const { id } = useParams(); // El id del usuario que estás buscando
    const { getAccessTokenSilently, user } = useAuth0(); // Para obtener el token de autenticación
    const [userProfile, setUserProfile] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [requestPending, setRequestPending] = useState(false);
    const [events, setEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const navigate = useNavigate(); 
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [reportErrorMessage, setReportErrorMessage] = useState('');

    const isCurrentUser = user && user.email === userProfile?.email;

    // Obtener el perfil del usuario
    const fetchUserProfile = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently(); // Obtener el token para autenticación
            const userInfo = await getUserInfoById(token, id); // Obtener los datos del usuario
            setUserProfile(userInfo);
            setRequestPending(userInfo.im_following_requested); // Establecer el estado de solicitud pendiente si ya está solicitada
            const userEvents = await getUserEvents(token, id, false);
            const pastUserEvents = await getUserEvents(token, id, true);
            setEvents(userEvents);
            setPastEvents(pastUserEvents);
            setError('');
            console.log(pastUserEvents)
            //console.log('Perfil del usuario:', userInfo);
            //console.log('Eventos del usuario:', userEvents);
        } catch (error) {
            console.error('Error al cargar el perfil del usuario:', error.message); // Mostrar el error en consola
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFollow = async (followId) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await createFollowerRequest(token, followId);
            fetchUserProfile();
        } catch (error) {
            console.error('Error al seguir o dejar de seguir al usuario:', error.message);
        }
    };

    const handleUnfollow = async (requestId) => {
        try {
            const token = await getAccessTokenSilently();
            await deleteFollowerRequest(token, requestId);
            fetchUserProfile();
        } catch (error) {
            console.error('Error al rechazar la solicitud:', error);
        }
    };

    // Rechazar una solicitud de seguidor
    const declineRequest = async (id) => {
        try {
            const token = await getAccessTokenSilently();
            await deleteFollowerRequest(token, id);
            fetchUserProfile();
        } catch (error) {
            console.error('Error al rechazar la solicitud:', error);
        }
    };
  
  // Aceptar una solicitud de seguidor
    const acceptRequest = async (id) => {
        try {
            const token = await getAccessTokenSilently();
            await acceptFollowerRequest(token, id);
            fetchUserProfile();
        } catch (error) {
        console.error('Error al aceptar la solicitud:', error);
        }
    };

    const handleReport = () => {
        setIsReportModalOpen(true);
    };

    const handleReportSubmit = async (reason) => {
        //console.log("Razón del reporte:", reason);
        
        try {
            let report;
            const token = await getAccessTokenSilently(); // Esperar el token correctamente
            report = await ReportUser(token, userProfile.id_user, reason); // Asegurarse de llamar a ReportUser de manera asíncrona
            setIsReportModalOpen(false); // Cierra el modal después de enviar el reporte
            //console.log("Reporte enviado:", report);

            if (report.alreadyReported) {
                setReportErrorMessage(report.message); // Mensaje controlado
            } else {
                //console.log("Reporte enviado exitosamente");
            }

        } catch (error) {
            console.error("Error al enviar el reporte:", error);
    }
};


    const handleNavigateFollowing = () => {
        navigate(`/following/${id}`);
    };

    const handleNavigateFollowers = () => {
        navigate(`/followers/${id}`);
    };

    const handleGoBack = () => {
        navigate(-1); // Esta función retrocede a la página anterior
    };

    useEffect(() => {
        fetchUserProfile();
    }, [id]);

    // Si los datos aún se están cargando
    if (isLoading) {
        return <div>Cargando perfil...</div>;
    }

    // Si hay un error
    if (error) {
        return <div>Error: {error}</div>;
    }

    // Si el perfil no fue encontrado
    if (!userProfile) {
        return <div>No se encontró el perfil del usuario.</div>;
    }

    const canViewFollowers =  isCurrentUser || userProfile.profile_privacy === false || userProfile.im_following;
    const canViewFollowing = isCurrentUser || userProfile.profile_privacy === false || userProfile.im_following;
    const canViewEvents = isCurrentUser || userProfile.profile_privacy === false || userProfile.im_following || isCurrentUser;

    // Mostrar la información del usuario
    return (
        <div className="container mx-auto mt-10 max-w-4xl">
            <div className="flex items-center justify-between mb-4">
            {   /* Flecha de retroceso */}
                <button onClick={handleGoBack} className="flex items-center text-gray-500 hover:text-black">
                    <ArrowLeftIcon className="h-6 w-6" />
                    <span className="ml-2">Volver</span>
                </button>
    
                {/* Botón de reportar */}
                {!isCurrentUser && (
                    <button 
                        className="py-2 px-3 bg-red-500 text-white text-sm rounded-md hover:bg-red-600"
                        onClick={handleReport}
                    >
                        Reportar
                    </button>
                )}
            </div>

            <ReportModal 
                isOpen={isReportModalOpen} 
                onClose={() => setIsReportModalOpen(false)} 
                onSubmit={handleReportSubmit} 
            />
            
            {/* Mostrar la solicitud de seguimiento si existe */}
            {userProfile.my_follower_requested && !userProfile.my_follower && (
                <div className="bg-gray-100 p-4 rounded-md flex justify-between items-center mb-4">
                    <p>{userProfile.name} quiere seguirte</p>
                    <div className="space-x-4">
                        <button 
                            className="py-2 px-4 bg-custom-primary text-white rounded-md hover:bg-blue-600 mr-2"
                            onClick={() => acceptRequest(userProfile.id_follower_request)}
                        >
                            Aceptar
                        </button>
                        <button 
                            className="py-2 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-400 mr-2"
                            onClick={() => declineRequest(userProfile.id_follower_request)}
                        >
                            Rechazar
                        </button>
                    </div>
                </div>
            )}
            
            {/* Encabezado del perfil */}
            <div className="flex items-center justify-between py-5 border-b">
                {/* Imagen y datos del usuario */}
                <div className="flex items-center space-x-5">
                    {/* Imagen de perfil */}
                    <img 
                        src={userProfile.profile_link ? `${config.images_route}/${userProfile.profile_link}` : '/images/imagen_perfil_default.png'} 
                        alt={`Avatar de ${userProfile.name}`} 
                        className="w-16 h-16 rounded-full object-cover" 
                    />
                    <div>
                        <h2 className="text-2xl font-bold">{userProfile.name}</h2>
                        <p className="text-sm text-gray-500">{userProfile.first_name} {userProfile.last_name}</p>
                        <p className="text-sm text-gray-500">{userProfile.description}</p>
                        <div className="flex items-center space-x-2">
                            <GlobeAltIcon className="h-5 w-5 text-gray-500" />
                            <a href={userProfile.website || '#'} className="text-blue-500 hover:underline">{userProfile.website}</a>
                        </div>
                        <div className="flex items-center space-x-2">
                            <KeyIcon className="h-5 w-5 text-gray-500" />
                            <p className="text-sm text-gray-500">
                                {userProfile.profile_privacy ? 'Privado' : 'Público'}
                            </p>
                        </div>
                    </div>
                </div>
    
                {/* Estadísticas de seguidores */}
                <div className="flex space-x-10 text-center">
                    <div className={`text-center ${!canViewEvents ? 'text-gray-500' : 'text-black'}`}>
                        <span className="block text-lg font-bold">{userProfile.events}</span>
                        <span className="text-sm text-gray-500">Eventos</span>
                        <span className="block text-xs text-gray-500"> (Públicos) </span>
                    </div>
                    <div className={`cursor-pointer ${canViewFollowers ? 'text-black' : 'text-gray-500'}`} onClick={canViewFollowers ? handleNavigateFollowers : null}>
                        <span className="block text-lg font-bold">{userProfile.followed_by}</span>
                        <span className="text-sm text-gray-500">Seguidores</span>
                    </div>
                    <div className={`cursor-pointer ${canViewFollowing ? 'text-black' : 'text-gray-500'}`} onClick={canViewFollowing ? handleNavigateFollowing : null}>
                        <span className="block text-lg font-bold">{userProfile.follows_to}</span>
                        <span className="text-sm text-gray-500">Seguidos</span>
                    </div>
                </div>
            </div>
    
            {/* Botón de seguir o dejar de seguir, solo mostrar si NO es el usuario actual */}
            {isCurrentUser ? (
                <div className="flex justify-center mt-5">
                    <button 
                        className="text-black font-bold py-2 px-4 rounded border hover:bg-gray-200 bg-blue-400 text-white" 
                        onClick={() => navigate('/profile')}
                    >
                        Ir a mi perfil
                    </button>
                </div>
            ) : (
                <div className="flex justify-center mt-5">
                    {userProfile.im_following || userProfile.im_following_requested ? (
                        <button
                            className="py-2 px-4 bg-gray-400 text-white rounded-md hover:bg-gray-200 mr-2"
                            onClick={() => handleUnfollow(userProfile.id_my_request)}
                        >
                            {userProfile.im_following ? 'Dejar de seguir' : 'Cancelar solicitud'}
                        </button>
                    ) : (
                        <button
                            style={{ backgroundColor: '#3a5cc8', color: 'white' }}
                            className="text-black font-bold py-2 px-4 rounded border hover:bg-gray-200"
                            onClick={() => handleFollow(userProfile.id_user)}
                        >
                            Seguir
                        </button>
                    )}
                </div>
            )}
    
            {/* Mostrar eventos del usuario */}
            {canViewEvents && (
                <>
                    {/* Mostrar eventos del usuario */}
                    <div className="mt-8">
                        <h3 className="text-xl font-semibold mb-4">Eventos</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {!userProfile.im_following && !isCurrentUser? (
                                <p>Seguir usuario para ver eventos actuales.</p>
                            ) : events.length === 0 ? (
                                <p>No hay eventos disponibles.</p>
                            ) : (
                                events.map(event => {
                                    // Validar la foto del evento antes de enviarla
                                    const eventPhoto = event.link_photo ? `${config.images_route}/${event.link_photo}` : '/images/evento_default_foto.png';
                                    return (
                                        <EventCardProfile
                                            key={event.id_event}
                                            id_event={event.id_event}
                                            link_photo={eventPhoto}  // Pasar la foto validada al componente
                                            title={event.title}
                                            organizer={{ id: userProfile.id_user, name: userProfile.name}}  // Si el organizador es el perfil actual, usa userProfile
                                            start_date={event.start_date}
                                            end_date={event.end_date}
                                            location={event.location}
                                        />
                                    );
                                })
                            )}
                        </div>
                    </div>
    
                    {/* Mostrar eventos pasados del usuario */}
                    <div className="mt-8">
                        <h3 className="text-xl font-semibold mb-4">Eventos Pasados</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {pastEvents.length === 0 ? (
                                <p>No hay eventos pasados.</p>
                            ) : (
                                pastEvents.map(event => {
                                    // Validar la foto del evento antes de enviarla
                                    const eventPhoto = event.link_photo ? `${config.images_route}/${event.link_photo}` : '/images/evento_default_foto.png';
                                    return (
                                        <EventCardProfile
                                            key={event.id_event}
                                            id_event={event.id_event}
                                            link_photo={eventPhoto}  // Pasar la foto validada al componente
                                            title={event.title}
                                            organizer={{ id: userProfile.id_user, name: userProfile.name}} // Si el organizador es el perfil actual, usa userProfile
                                            start_date={event.start_date}
                                            end_date={event.end_date}
                                            location={event.location}
                                        />
                                    );
                                })
                            )}
                        </div>
                    </div>
                </>
            )}
            <AlertReportModal
                isOpen={!!reportErrorMessage}
                title="Error"
                message={reportErrorMessage}
                onClose={() => setReportErrorMessage("")}
            />
        </div>
    );
};


export default OtherProfileView;
