import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from './UserContex';
import { fetchProfileData, getUserInfo } from '../../services/api';
import SearchBar from '../SearchBar/SearchBar'; // Importar el componente SearchBar
import { IoMenu, IoHomeOutline } from "react-icons/io5"; // Importar el ícono de menú y el ícono de Home
import { MdSavedSearch } from "react-icons/md"; // Importar ícono para Feed
import { FaRegCalendarPlus, FaRegCalendarAlt } from "react-icons/fa"; // Importar ícono para Crear Evento y Calendario
import { CiInboxIn, CiViewList } from "react-icons/ci"; // Importar ícono para Inbox y Mis Eventos
import { SlGraph } from "react-icons/sl"; // Importar ícono para Métricas
import { IoMdLogOut } from "react-icons/io"; // Importar ícono para Logout

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const { userName, setUserName } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({ profileLink: '' });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  // Función para cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const getUserData = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const userInfo = await getUserInfo(token);
          setUserInfo(userInfo);
          setUserName(userInfo.name);
        } catch (error) {
          console.error("Error fetching user info", error);
        }
      }
    };
    getUserData();
  }, [isAuthenticated, getAccessTokenSilently, setUserName]);

  const logoutWithConfirmation = () => {
    if (window.confirm("¿Estás seguro de que quieres cerrar sesión?")) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
    }
  };

  return (
    <div className="bg-custom-primary text-white p-2 relative">
      <nav className="flex items-center justify-between py-0.4 px-2 relative">
        {/* Sección del logo a la izquierda */}
        <div className="flex items-center z-10"> {/* Agregar z-index */}
          <RouterNavLink to="/dashboard" className="flex items-center">
          <img src="/images/Logo_Web.png" alt="Home" className="max-h-11 w-auto mr-6" /> {/* Logo */}
          </RouterNavLink>
        </div>

        {/* Sección del buscador centrado solo si está autenticado */}
        {isAuthenticated && (
          <div className="absolute left-1/2 transform -translate-x-1/2 z-20">
            <SearchBar /> {/* Colocamos la barra de búsqueda en el centro */}
          </div>
        )}

        {/* Sección de opciones e íconos a la derecha */}
        <div className="flex items-center space-x-4 relative z-10">
          <ul className="flex space-x-4 items-center">
            {!isAuthenticated && (
              <li>
                <button
                  className="bg-white bg-opacity-30 border-2 border-white text-custom-blue py-2 px-4 rounded-full transition-all duration-300 hover:bg-white hover:text-custom-blue hover:font-bold"
                  onClick={loginWithRedirect}
                >
                  Iniciar Sesión
                </button>
              </li>
            )}
            {isAuthenticated && (
              <li className="flex space-x-4 items-center">
                {/* Ícono Feed */}
                <RouterNavLink to="/feed" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Feed">
                  <MdSavedSearch className="text-white w-6 h-6 mx-auto" />
                  <span className="text-xs">Feed</span>
                </RouterNavLink>

                {/* Ícono Crear Evento */}
                <RouterNavLink to="/create_event" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Crear Evento">
                  <FaRegCalendarPlus className="text-white w-6 h-6 mx-auto" />
                  <span className="text-xs">Crear Evento</span>
                </RouterNavLink>

                {/* Ícono Inbox */}
                <RouterNavLink to="/inbox" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Inbox">
                  <CiInboxIn className="text-white w-6 h-6 mx-auto" />
                  <span className="text-xs">Inbox</span>
                </RouterNavLink>

                {/* Ícono Métricas */}
                <RouterNavLink to="/metrics" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Métricas">
                  <SlGraph className="text-white w-6 h-6 mx-auto" />
                  <span className="text-xs">Métricas</span>
                </RouterNavLink>

                {/* Ícono Calendario */}
                <RouterNavLink to="/calendar" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Calendario">
                  <FaRegCalendarAlt className="text-white w-6 h-6 mx-auto" />
                  <span className="text-xs">Calendario</span>
                </RouterNavLink>

                {/* Ícono Mis Eventos */}
                <RouterNavLink to="/my_events" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Mis Eventos">
                  <CiViewList className="text-white w-6 h-6 mx-auto" />
                  <span className="text-xs">Mis Eventos</span>
                </RouterNavLink>

                {/* Foto del usuario que redirige directamente a "Mi Perfil" */}
                <RouterNavLink to="/profile" className={({ isActive }) => `hover:underline text-center ${isActive ? 'underline' : ''}`} title="Mi Perfil">
                  <img
                    src={userInfo.profileLink}
                    alt="User Avatar"
                    className="w-11 h-11 rounded-full cursor-pointer mx-auto"
                  />
                </RouterNavLink>


              </li>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
