import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import NavBar from "./components/NavBar/navbar_two";
import { UserProvider } from "./components/NavBar/UserContex";
import Home from './views/Home/Home';
import NotFoundpage from './views/NotFoundpage';
import Profile from './views/Profile/profile';
import CreateEvent from './views/My_Events/create_event';
import MyEvents from './views/My_Events/my_events';
import EditProfile from './views/Profile/editProfile';
import EditProfileEventsView from './views/Profile/editProfileEventsView';
import FirstEditProfile from './views/Profile/firstEditProfile';
import EventDetails from './views/My_Events/event_details';
import EditEvent from './views/My_Events/edit_event';
import Followers from './views/followers';
import Following from './views/following';
import CalendarView from './views/Calendar/calendar';
import Feed from './views/Feed/Feed';
import OtherProfileView from './views/Other/other_profiles';
import InboxView from './views/Inbox/inbox';
import Metrics from './views/Metrics/MetricsView';
import Dashboard from './views/Dashboard/Dashboard';
import OtherEvent from './views/Other/other_events';
import Mobile from './views/Mobile';
import ResendVerification from './components/ResendVerification/ResendVerification';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

const App = () => {
  const location = useLocation();  

  return (
    <div id="app" className="flex flex-col min-h-screen">
      <UserProvider>
        {location.pathname.startsWith("/form_inicial") || location.pathname.startsWith("/mobile/event") ? null : <NavBar />}
        <div className="flex-1">
          <Routes>
            {/* Rutas no protegidas */}
            <Route path="/" element={<Home />} />
            <Route path="/mobile/event/:eventId" element={<Mobile />} />
            <Route path="/resend-verification" element={<ResendVerification />} />

            {/* Rutas protegidas */}
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/edit-profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
            <Route path="/metrics" element={<ProtectedRoute><Metrics /></ProtectedRoute>} />
            <Route path="/edit-profile-events-view" element={<ProtectedRoute><EditProfileEventsView /></ProtectedRoute>} />
            <Route path="/create_event" element={<ProtectedRoute><CreateEvent /></ProtectedRoute>} />
            <Route path="/my_events" element={<ProtectedRoute><MyEvents /></ProtectedRoute>} />
            <Route path="/detail/:eventId" element={<ProtectedRoute><EventDetails /></ProtectedRoute>} />
            <Route path="/event/:eventId/edit" element={<ProtectedRoute><EditEvent /></ProtectedRoute>} />
            <Route path="/followers/:userId" element={<ProtectedRoute><Followers /></ProtectedRoute>} />
            <Route path="/following/:userId" element={<ProtectedRoute><Following /></ProtectedRoute>} />
            <Route path="/calendar" element={<ProtectedRoute><CalendarView /></ProtectedRoute>} />
            <Route path="/feed" element={<ProtectedRoute><Feed /></ProtectedRoute>} />
            <Route path="/form_inicial/:id_user" element={<ProtectedRoute><FirstEditProfile /></ProtectedRoute>} />
            <Route path="/profile/:id" element={<ProtectedRoute><OtherProfileView /></ProtectedRoute>} />
            <Route path="/inbox" element={<ProtectedRoute><InboxView /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/event/:eventId" element={<ProtectedRoute><OtherEvent /></ProtectedRoute>} />

            {/* Página no encontrada */}
            <Route path="*" element={<NotFoundpage />} />
          </Routes>
        </div>
      </UserProvider>
    </div>
  );
}

export default App;
