import React, { useState, useEffect } from 'react';

const Modal = ({ isOpen, onClose, onSubmit, initialValues }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (isOpen) {
            setTitle(initialValues.title || '');
            setDescription(initialValues.content || '');
        }
    }, [isOpen, initialValues]);

    if (!isOpen) return null;

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" // z-50 asegura prioridad alta
            style={{ zIndex: 50 }} // Asegura que el modal esté al frente
        >
            <div 
                className="bg-white p-8 rounded-lg shadow-lg relative" 
                style={{ width: '700px', height: 'auto', maxWidth: '90%', maxHeight: '90%' }}
            >
                <h2 className="text-xl font-bold mb-4">Enviar Aviso</h2>
                <input
                    className="w-full p-3 mb-4 border border-gray-300 rounded text-lg"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Título del Aviso"
                    style={{ fontSize: '18px' }}
                />
                <textarea
                    className="w-full p-3 border border-gray-300 rounded text-lg"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Descripción del Aviso"
                    rows="5"
                    style={{ fontSize: '18px' }}
                />
                <div className="mt-4 flex justify-end space-x-2">
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded">Cancelar</button>
                    <button onClick={() => onSubmit({ title, description })} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Enviar</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
