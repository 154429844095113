import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { PhoneIcon, GlobeAltIcon, KeyIcon } from '@heroicons/react/solid';
import { fetchProfileData, updateProfile  } from '../../services/api';

const EditProfile = () => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [user_id ,setUserId] = useState('');
    const [editData, setEditData] = useState({
        name: '',
        phone: '',
        website: '',
        description: '',
        profile_privacy: 'Público'
    });
    const [imageFile, setImageFile] = useState(null);
    const [linkOfAPicture, setLinkOfAPicture] = useState('');

    const typeOptions = {
        public: 'Público',
        private: 'Privado',
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await getAccessTokenSilently();
                const { profileData, profileLink } = await fetchProfileData(token);
                setUserId(profileData.id);
                profileData.profile_privacy = profileData.profile_privacy ? 'private' : 'public';
                setEditData(profileData);
                setLinkOfAPicture(profileLink);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [getAccessTokenSilently]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            if (/^[0-9+()\s]*$/.test(value) && value.length <= 15 || value === "") {
                setEditData(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            }
        } 
        else {
            setEditData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (editData.phone.length < 8) {
            alert("El mínimo de caracteres para el número de contacto son 8.");
            return; 
        }
        try {
            const token = await getAccessTokenSilently();
            await updateProfile(token, user_id, editData, imageFile);
            navigate('/profile');
        } catch (error) {
            alert('Error al actualizar el Perfil.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="bg-100 flex items-center flex-col items-start justify-start">  
                <div className="max-w-6xl w-full bg-white shadow-lg rounded overflow-hidden mt-6 bg-custom-primary" >
                    <div className="px-6 py-4 bg-custom-primary">  
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <img  src={linkOfAPicture} className="h-16 w-16 rounded-full object-cover mr-4" />
                                <div>
                                    <input type="text" id="name" name="name" value={editData.name} onChange={handleChange} className="text-2xl font-bold text-white bg-transparent border-none"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="px-6 py-8">
                        <div className="mb-6">
                            <h2 className="text-lg font-semibold text-gray-800 mb-2">Descripción de la Organización</h2>
                            <textarea id="description" name="description" value={editData.description} onChange={handleChange} className="form-input block w-full p-2 border rounded" rows="1"></textarea>
                        </div>
                        <div className="mb-6">
                            <h2 className="text-lg font-semibold text-gray-800 mb-2">Información de Contacto</h2>
                            <div className="flex items-center">
                                <PhoneIcon className="h-5 w-5 mr-2" />
                                <input id="phone" name="phone" type="text" value={editData.phone} onChange={handleChange} className="form-input block w-full p-2 border rounded"/>
                            </div>
                            <div className="flex items-center">
                                <GlobeAltIcon className="h-5 w-5 mr-2" />
                                <input id="website" name="website" type="text" value={editData.website} onChange={handleChange} className="form-input block w-full p-2 border rounded"/>
                            </div>
                            <div className="mb-6">
                            <div className="flex items-center">
                                <KeyIcon className="h-5 w-5 mr-2" />
                                <select className="form-input block w-full p-2 border rounded" value={editData.profile_privacy} onChange={handleChange} name="profile_privacy">
                                    {Object.entries(typeOptions).map(([key, value]) => (
                                        <option key={key} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                            <div className="mb-6">
                                <label className="text-lg font-semibold text-gray-800 mb-2">Foto del Perfil:</label>
                                <input 
                                    type="file"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    accept="image/png, image/jpeg"
                                    onChange={e => setImageFile(e.target.files[0])}
                                />
                                <p className="text-gray-500 text-xs mt-1">Si no se proporciona una imagen, se mantendrá la imagen actual. Formatos aceptados: jpg, jpeg, png.</p>
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="bg-custom-primary hover:bg-[#2C4570] text-white font-semibold py-2 px-4 rounded inline-block">
                                Guardar cambios
                            </button>
                            <button onClick={() => navigate('/profile')} type="button" className="ml-4 bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded inline-block">
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
