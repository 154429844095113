import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { IoDownloadOutline } from 'react-icons/io5'; // Importar el ícono

const AttendeesModal = ({ isOpen, onClose, attendees }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showColumnSelector, setShowColumnSelector] = useState(false); // Nuevo estado para el selector de columnas
  const [selectedColumns, setSelectedColumns] = useState([
    'Nombre',
    'Apellido',
    'Apodo',
    'Correo',
    'Teléfono',
    'Rut',
  ]); // Inicialmente, todas las columnas están seleccionadas
  const menuRef = useRef(null);

  // Manejar clics fuera del menú
  useEffect(() => {
    if (!isOpen) return; // Solo activa el listener si el modal está abierto

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Función para generar y descargar el Excel
  const exportToExcel = () => {
    const data = attendees.map((attendee) => {
      const row = {};
      if (selectedColumns.includes('Nombre')) {
        row['Nombre'] = attendee.first_name;
      }
      if (selectedColumns.includes('Apellido')) {
        row['Apellido'] = attendee.last_name;
      }
      if (selectedColumns.includes('Apodo')) {
        row['Apodo'] = attendee.name;
      }
      if (selectedColumns.includes('Correo')) {
        row['Correo'] = attendee.email;
      }
      if (selectedColumns.includes('Teléfono')) {
        row['Teléfono'] = attendee.phone;
      }
      if (selectedColumns.includes('Rut')) {
        row['Rut'] = attendee.rut;
      }
      return row;
    });

    if (data.length === 0 || selectedColumns.length === 0) {
      alert('No hay datos o columnas seleccionadas para exportar.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Asistentes');

    XLSX.writeFile(workbook, 'Lista_Asistentes.xlsx');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      {/* Fondo del modal */}
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Fondo oscuro */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* Contenido del modal */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full"
          style={{ maxHeight: '90vh' }}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <div className="flex items-center">
                  <h3
                    className="text-2xl leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Lista de Asistentes
                  </h3>
                  {/* Botón de menú de opciones */}
                  <div className="relative ml-2" ref={menuRef}>
                    <button
                      onClick={() => {
                        setShowMenu(false); // Cerrar el menú si está abierto
                        setShowColumnSelector(true); // Mostrar el selector de columnas
                      }}
                      className="text-black-600 hover:text-gray-800 focus:outline-none"
                    >
                      <IoDownloadOutline size={20} /> {/* Ícono de descarga */}
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <div
                    className="overflow-y-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                    style={{
                      maxHeight: 'calc(3 * 150px)',
                      overflowY: 'auto',
                      paddingRight: '1rem',
                    }}
                  >
                    {attendees.length > 0 ? (
                      attendees.map((attendee) => (
                        <div
                          key={attendee.id_attendee}
                          className="flex flex-col items-center p-2 bg-gray-100 rounded-lg shadow w-40"
                        >
                          <Link
                            to={`/profile/${attendee.id_user}`}
                            className="flex flex-col items-center bg-white border border-gray-200 p-4 rounded-lg shadow-md hover:bg-gray-100 transition-colors duration-300"
                          >
                            <img
                              src={
                                attendee.profile_link ||
                                '/images/imagen_perfil_default.png'
                              }
                              alt={attendee.name}
                              className="w-16 h-16 rounded-full object-cover mb-2"
                            />
                            <div className="mt-2 w-full">
                              <p className="text-lg font-semibold text-center break-words">
                                {attendee.name}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <p className="col-span-full text-center">
                        No hay asistentes disponibles.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Botón de cerrar */}
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="bg-custom-wine-red hover:bg-custom-wine-red-light text-white font-semibold py-2 px-4 rounded inline-block"
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>

      {/* Modal para seleccionar columnas */}
      {showColumnSelector && (
        <div className="fixed inset-0 z-20 flex items-center justify-center">
          {/* Fondo bloqueador */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Contenido del modal */}
          <div className="relative bg-white p-6 rounded shadow-md text-center z-30">
            <h2 className="text-2xl font-bold mb-4">Seleccionar Columnas</h2>
            <form className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                {[
                  'Nombre',
                  'Apellido',
                  'Apodo',
                  'Correo',
                  'Teléfono',
                  'Rut',
                ].map((column) => (
                  <label key={column} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedColumns.includes(column)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedColumns([...selectedColumns, column]);
                        } else {
                          setSelectedColumns(
                            selectedColumns.filter((col) => col !== column)
                          );
                        }
                      }}
                      className="form-checkbox"
                    />
                    <span className="ml-2">{column}</span>
                  </label>
                ))}
              </div>
            </form>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => {
                  exportToExcel();
                  setShowColumnSelector(false);
                }}
                className={`${
                  selectedColumns.length === 0
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-custom-primary hover:bg-custom-lightblue'
                } text-white font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out`}
                disabled={selectedColumns.length === 0}
              >
                Descargar
              </button>
              <button
                onClick={() => setShowColumnSelector(false)}
                className="bg-custom-wine-red hover:bg-custom-wine-red-light text-white font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttendeesModal;
