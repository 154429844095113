import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../configroutes';
import {formatFullDateTime} from '../../services/format';
import { CalendarIcon, LocationMarkerIcon, UserIcon } from '@heroicons/react/solid';

const EventCardProfile = ({ id_event, link_photo, title, organizer, start_date, end_date, location }) => {
  const defaultImage = '../../images/evento_default_foto.png'; // Ruta de la imagen predeterminada
  const new_start_date = formatFullDateTime(start_date);
  const new_end_date = formatFullDateTime(end_date);

  // Verificar si link_photo contiene múltiples URLs separadas por comas y usar solo la primera
  const linkPhoto = link_photo && link_photo.includes(',')
    ? link_photo.split(',')[0].trim() // Tomar la primera URL
    : link_photo;

  const imageUrl = linkPhoto && !linkPhoto.endsWith('null') ? linkPhoto : defaultImage;

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-64 mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-xl relative">
      {/* Imagen oprimible */}
      <Link to={`/event/${id_event}`} className="block h-48">
        <img 
          src={imageUrl} 
          alt={title} 
          className="w-full h-full object-cover" 
        />
      </Link>

      {/* Parte inferior: Información */}
      <div className="p-4">
        {/* Título oprimible */}
        <Link to={`/event/${id_event}`} className="text-xl font-bold mb-2 block text-blue-600 hover:underline">
          {title}
        </Link>
        <div className="flex items-start text-gray-600 mb-2">
            <CalendarIcon className="h-5 w-5 mr-2 mt-1" />
            <div>
                <p className="font-semibold">{new_start_date} - </p>
                <p className="font-semibold">{new_end_date}</p>
            </div>
        </div>
        <div className="flex items-start text-gray-600 mb-2">
          <LocationMarkerIcon className="h-5 w-5 mr-2 mt-1" />
          <div className="w-48">
            <p>{location ? location : 'Por definir'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCardProfile;
