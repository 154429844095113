import axios from 'axios';
import config from '../configroutes.js';
import { convertUTCToLocal } from './format.js';


// ENDPOINT QUE NO ESTÁN:

// usuarios
// 1. POST /api/user/signup -> Crea un nuevo usuario, este endpoint es usado sólo por Auth0.
// 4. DELETE /api/user/{user_id} -> Elimina un usuario existente.
// 5. GET /api/user/search/?name={name}&page={page} -> Busca en la base de datos a los usuarios cuyo nombre de usuario, nombre o apellido sea el indicado.
// 7. GET /api/username/exists/?name={name}
// 8. POST /api/user/create/endpoint -> Guarda un arn de un endpoint de un usuario para recibir notificaciones.
// 9. DELETE /api/user/delete/endpoint

// eventos
// 1. GET /api/event/my/?from_date={from_date}&to_date={to_date}&timezone={timezone}
// 5. PUT /api/event/{id_event}
// GET /teaser/api/event/search/?description={description}&page={page}&only_followed={only_followed}
// GET /teaser/api/event/{event_id}


// asistencia
// 1. POST /api/attendee/attend -> El usuario autenticado se inscribe a un evento público.
// 4. GET /api/attendee/my/?page -> Obtiene las asistencias pendientes que se tienen, accepted = None .
// 5. DELETE /api/attendee/{attendee_id} -> Elimina una asistencia existente.
// 6. PUT /api/attendee/{attendee_id} -> Se utiliza solo y exclusivamente para que un usuario acepte una invitación o la rechace
// 7. GET /api/attendee/accepted/following/ -> Obtiene todos los asistentes aceptados de los eventos a los que el usuario autenticado sigue

//review
// 1. GET /api/review/{id_review} -> Obtiene una review segun el id de la review.
// 2. POST /api/review/create -> crea una nueva reseña
// 3. PUT /api/review/{id_review} -> Actualiza una reseña existente
// 4. DELETE /api/review/{id_review} -> Cambia la visibilidad de una review a false

// feed
// 1. GET /api/feed/
// 2. POST /api/feed/reload

// post
// 2. GET /api/post/{post_id} -> Obtiene un post específico a través de su ID
// 3. GET /api/post/myposts/ -> Obtiene los posts del usuario actual.

// templates
// 2. GET /api/event_template/{template_id} -> Obtiene una plantilla de evento por su ID.


// notificaciones (no las puse, mobile)

// grupos de usuarios
// 1. POST /api/user_group/create -> Crea un nuevo grupo de usuarios
// 2. GET /api/user_group/allmy -> Obtiene todos los grupos de usuarios a los que pertenece el usuario autenticado.
// 3. DELETE /api/user_group/{group_id} -> Elimina un grupo de usuarios.



/// ENDPOINTS DE USUARIOS

// 2. GET /api/user/me -> Función para obtener la información del usuario que inicio sesión
const fetchUserData = async (token) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/user/me`;
    const response = await axios.get(url, configaxios);
    return response.data;
};

// 2. GET /api/user/me -> Para obtener todos los atributos de un perfil
const getUserInfo = async (token) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`, 
        }
    };
    const url = `${config.route}/api/user/me`; 
    const response = await axios.get(url, configaxios);
    const userInfo = {
        id: response.data.id_user,
        name: response.data.name,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        phone: response.data.phone,
        website: response.data.website,
        description: response.data.description,
        profileLink: `${config.images_route}/${response.data.profile_link}`,
        profile_privacy: response.data.profile_privacy,
        followers: response.data.followers,
        following: response.data.following
    };
    return userInfo;
};

// 2. GET /api/user/me -> Para obtener alguno de los atributos de un perfil
const fetchProfileData = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get(`${config.route}/api/user/me`, { headers });
    const profileData = {
        id: response.data.id_user,
        name: response.data.name,
        phone: response.data.phone,
        website: response.data.website,
        description: response.data.description,
        profile_privacy: response.data.profile_privacy,
        profile_link: response.data.profile_link
    };
    return { profileData, profileLink: `${config.images_route}/${response.data.profile_link}` };
};

export const checkOrganizerStatus = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    try {
        const response = await axios.get(`${config.route}/api/user/me`, { headers });
        return response.data.organizer; // Retorna solo el campo 'organizer'
    } catch (error) {
        console.error('Error al verificar el estado de organizador:', error);
        throw error; // Lanza el error para que pueda ser manejado donde se llama la función
    }
};

// 3. GET /api/user/{id_user} -> Función para obtener la información de un usuario específico por su ID
const getUserInfoById = async (token, userId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`, 
        }
    };
    
    // Endpoint con el ID del usuario
    const url = `${config.route}/api/user/${userId}`;

    try {
        const response = await axios.get(url, configaxios);
        return response.data; // Devolvemos la respuesta completa para verificar que los datos lleguen correctamente
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error('Usuario no encontrado');
        } else {
            throw new Error('Error al obtener información del usuario');
        }
    }
};

// 1. POST /api/image/upload
// 6. PUT /api/user/{id_user} -> Para actualizar atributos de un perfil
const updateProfile = async (token, userId, editData, imageFile) => {
    const isPrivacyPrivate = editData.profile_privacy === 'private';
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    let imageURL = editData.profile_link;
    if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);
        try {
            const uploadResponse = await axios.post(`${config.route}/api/image/upload`, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                }
            });
            imageURL = `${uploadResponse.data.image_url}`;
        } catch (error) {
            console.error("Error uploading new image: ", error);
            throw error; 
        }
    }
    const updateData = {
        ...editData,
        profile_privacy: isPrivacyPrivate,
        profile_link: imageURL  
    };
    delete updateData.id;
    try {
        const response = await axios.put(`${config.route}/api/user/${userId}`, updateData, configaxios);
        return response.data;
    } catch (error) {
        console.error("Error updating profile: ", error);
        throw error;
    }
};


/// ENDPOINTS DE EVENTOS


// Para obtener todos los eventos (públicos)
// 2. GET /api/event/search/?description={description}&page={page}&only_followed={only_followed}
// services/api.js

const getAllEvents = async (token, page = 1) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };

    try {
        const urlAllEvents = `${config.route}/api/event/search/?description=&page=${page}&only_followed=false&page_size=12`;
        const response = await axios.get(urlAllEvents, configaxios);

        if (Array.isArray(response.data.events)) {
            const events = response.data.events.map(event => ({
                ...event,
                start_date: convertUTCToLocal(event.start_date),
                end_date: convertUTCToLocal(event.end_date),
                link_photo: `${config.images_route}/${event.link_photo}`
            }));

            return {
                events: events,
                total_pages: response.data.total_pages,
                total_results: response.data.total_results,
                page: response.data.page
            };
        } else {
            throw new Error("No se encontraron eventos en la respuesta");
        }

    } catch (error) {
        console.error('Error fetching all events:', error.response || error.message);
        throw error;
    }
};

  
// 3. GET /api/event/{event_id} -> Para obtener los detalles de un evento en particular
const getEventDetails = async (token, eventId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/event/${eventId}`;
    const response = await axios.get(url, configaxios);

    const updatedEvent = {
        ...response.data,
        start_date: convertUTCToLocal(response.data.start_date),
        end_date: convertUTCToLocal(response.data.end_date),
        link_photo: `${config.images_route}/${response.data.link_photo}`,
    };
    return updatedEvent;
};

// 4. GET /feed/?only_followed={only_followed}&mixed={mixed}&size={size} -> Para obtener el feed de eventos
const getFeed = async (token, onlyFollowed = false, mixed = false, size = 20) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };

    const url = `${config.route}/feed/?only_followed=${onlyFollowed}&mixed=${mixed}&size=${size}`;
    
    try {
        const response = await axios.get(url, configaxios);
        const events = response.data;

        // Modificar link_photo y convertir fechas de cada evento
        const updatedEvents = events.map(event => {
            // Si `link_photo` contiene una coma, solo toma la primera parte
            let linkPhoto = event.link_photo?.split(',')[0];

            // Verificar si `link_photo` existe y no empieza con la ruta configurada
            if (linkPhoto && !linkPhoto.startsWith(`${config.images_route}/`)) {
                linkPhoto = `${config.images_route}/${linkPhoto}`;
            }

            // Convertir fechas de UTC a hora local
            const startDate = convertUTCToLocal(event.start_date);
            const endDate = convertUTCToLocal(event.end_date);

            // Retornar el evento actualizado con la nueva `link_photo` y fechas convertidas
            return {
                ...event,
                start_date: startDate,
                end_date: endDate,
                link_photo: linkPhoto
            };
        });

        return updatedEvents;
    } catch (error) {
        console.error('Error fetching the feed:', error.response || error.message);
        throw error;
    }
};


// 5. POST /feed/reload -> Para recargar el feed de eventos recomendados
const reloadFeed = async (token) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    
    const url = `${config.route}/feed/reload`;
    
    try {
        const response = await axios.post(url, {}, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error reloading the feed:', error.response || error.message);
        throw error;
    }
};

// 6. GET /api/event/user/{id_user} -> obtiene evento de un usuario
const getUserEvents = async (token, userId, past) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/event/user/${userId}?past=${past}`;

    try {
        const response = await axios.get(url, configaxios);

        const updatedEvents = response.data.map(event => ({
            ...event,
            start_date: convertUTCToLocal(event.start_date),
            end_date: convertUTCToLocal(event.end_date),
        }));

        return updatedEvents; // Devuelve la lista de eventos
    } catch (error) {
        console.error('Error fetching user events:', error);
        throw error; // Lanza el error para manejarlo en el componente
    }
};



// Se usa para foto de los eventos
// 4. POST /api/event/create -> Crea un evento en la base de datos
const createEvent = async (token, eventData, imageFiles, selectedFollowers, typeOfEvent) => {
    
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    if (imageFiles && imageFiles.length > 0) {
        const imageURLs = await uploadImages(token, imageFiles);
        eventData.link_photo = imageURLs;
    }

    
    const url = `${config.route}/api/event/create`;
    const response = await axios.post(url, eventData, configaxios);

    if (typeOfEvent === 'private' && selectedFollowers.length > 0) {
        const url2 = `${config.route}/api/attendee/invite`;
        const requestBody2 = {
            id_event: response.data[0].id_event,
            id_user: selectedFollowers
        };
        const response2 = await axios.post(url2, requestBody2, configaxios);
    }

    return response.data;
};

const updateEvent = async (token, eventId, eventData, imageFiles) => {
   
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    if (imageFiles && imageFiles.length > 0) {
        const imageURLs = await uploadImages(token, imageFiles);
        eventData.link_photo = imageURLs;
    } else {
        eventData.link_photo = null
    }
    console.log(eventData);

    const url = `${config.route}/api/event/${eventId}`;
    const response = await axios.put(url, eventData, configaxios);
    return response.data;
}


// 1. POST /api/image/upload -> Sube imagen a la base de datos y retorna nombre de la imagen
const uploadImages = async (token, imageFiles) => {
    const imageURLs = [];
    const imageUploadConfig = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
        }
    };

    for (const imageFile of imageFiles) {
        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            const uploadResponse = await axios.post(`${config.route}/api/image/upload`, formData, imageUploadConfig);
            imageURLs.push(uploadResponse.data.image_url); // Agregar la URL de cada imagen a la lista
        } catch (error) {
            console.error(`Error uploading image ${imageFile.name}:`, error.message);
        }
    }

    return imageURLs; // Devuelve las URLs de las imágenes cargadas
};


// 7. DELETE /api/event/{event_id} -> Para eliminar un evento en particular
const deleteEvent = async (token, eventId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/event/${eventId}`;
    const response = await axios.delete(url, configaxios);
    return response.data;
};

// 8. GET /api/event/allmy -> Para obtener todos los eventos del organizador en linea
const getEvents = async (token, userId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };

    const urlPast = `${config.route}/api/event/allmy?past=true&owned=true`;
    const urlUpcoming = `${config.route}/api/event/allmy?past=false&owned=true`;

    const [responsePast, responseUpcoming] = await Promise.all([
        axios.get(urlPast, configaxios),
        axios.get(urlUpcoming, configaxios)
    ]);

    // Ordenar eventos pasados por fecha en orden inverso (los más antiguos primero)
    const sortedPastEvents = responsePast.data.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    // Combinar eventos pasados ordenados con eventos futuros
    const allEvents = [...sortedPastEvents, ...responseUpcoming.data];


    const updatedEvents = allEvents.map(event => ({
        ...event,
        start_date: convertUTCToLocal(event.start_date),
        end_date: convertUTCToLocal(event.end_date),
        link_photo: `${config.images_route}/${event.link_photo}`
    }));

    return updatedEvents;
};

// Para obtener eventos en el calendario
export const getEventsCalendar = async (getAccessTokenSilently) => {
    try {
        const token = await getAccessTokenSilently();
        const configaxios = {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        };

        const urlPast = `${config.route}/api/event/allmy?past=true&owned=true`;
        const urlUpcoming = `${config.route}/api/event/allmy?past=false&owned=true`;

        const [responsePast, responseUpcoming] = await Promise.all([
            axios.get(urlPast, configaxios),
            axios.get(urlUpcoming, configaxios)
        ]);

        const allEvents = [...responsePast.data, ...responseUpcoming.data];

        return allEvents.map(event => ({
            ...event,
            start_date: convertUTCToLocal(event.start_date),
            end_date: convertUTCToLocal(event.end_date),
            link_photo: `${config.images_route}/${event.link_photo}`
        }));
    } catch (error) {
        console.error('Error al obtener eventos', error);
        throw error;
    }
};

// ENDPOINTS DE IMAGENES (se usan en otros lados también)
// 2. GET /api/image/{image_name} -> Obtiene una imagen de la base de datos.



// ENDPOINTS DE SOLICITUDES

// 1. POST /api/request/create -> Crea una solicitud de seguimiento entre dos usuarios 
const createFollowerRequest = async (token, userId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    
    const url = `${config.route}/api/request/create`;
    const requestBody = {
        id_followed: userId
    };
    
    try {
        const response = await axios.post(url, requestBody, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al crear la solicitud:', error);
        throw error;
    }
}


// 2. GET /api/request/my/?page={page} -> Obtiene las solicitudes de seguimiento pendientes que ha recibido el usuario autenticado.
const getFollowerRequests = async (token, page = 1) => {
    try {
        const configaxios = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };
        const url = `${config.route}/api/request/my/?page=${page}`;
        const response = await axios.get(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error fetching follower requests:', error);
        throw error;
    }
};

// 3. DELETE /api/request/{request_id} -> Elimina una solicitud existente (rechaza la solicitud)
const deleteFollowerRequest = async (token, requestId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/request/${requestId}`;
    try {
        const response = await axios.delete(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar la solicitud:', error);
        throw error; // Manejo del error
    }
};

// 4. PUT /api/request/{request_id} -> Aceptar una solicitud
const acceptFollowerRequest = async (token, requestId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/request/${requestId}`;
    try {
        const response = await axios.put(url, {}, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al aceptar la solicitud:', error);
        throw error;
    }
}

// 5. GET /api/request/followers/{user_id}/?page={page}&page_size={page_size} -> Obtiene los seguidores de un usuario
const getFollowers = async (token, page = 1, pageSize = 100) => {
    const userInfo = await fetchUserData(token);
    const userId = userInfo.id_user;

    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    const url = `${config.route}/api/request/followers/${userId}/?page=1&page_size=${pageSize}`;
    const response = await axios.get(url, configaxios);

    const followers = response.data.map(follower => ({
        ...follower,
        profile_link: follower.profile_link ? `${config.images_route}/${follower.profile_link}` : 'https://via.placeholder.com/50',
    }));

    return followers;
};


// Para obtener seguidos en perfil
// 5. GET /api/request/followers/{user_id}/?page={page}&page_size={page_size}
const getFollowersProfile = async (token, userId, page, pageSize) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/request/followers/${userId}/?page=${page}&page_size=${pageSize}`;
    const response = await axios.get(url, configaxios);
    return response.data;
};


// 6. GET /api/request/followed/{user_id}/?page={page}&page_size={page_size} -> Obtiene todos los seguidos de un usuario solicitado.
const getFollowingProfile = async (token, userId, page, pageSize) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/request/followed/${userId}/?page=${page}&page_size=${pageSize}`;
    const response = await axios.get(url, configaxios);
    return response.data;
};

// Para obtener seguidores invitables de un evento
// 7. GET /api/request/search/followers/{event_id}/?name={name}&page={page}&page_size={page_size}
// -> Busca entre de los seguidores del organizador a aquellos cuyo nombre de usuario, primer nombre o apellido contenga un string solicitado.
export const getFollowersAbleInviteEvent = async (token, eventId, pageSize = 100) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/request/search/followers/${eventId}/?name=&page=1&page_size=${pageSize}`;
    try {
        const response = await axios.get(url, configaxios);

       
        // Filtrar solo los usuarios que pueden ser invitados
        const followersAbleToInvite = response.data.filter(follower => follower.can_be_invited);

        // Modificar el profile_link de los usuarios
        const followers = followersAbleToInvite.map(follower => ({
            ...follower,
            profile_link: follower.profile_link ? `${config.images_route}/${follower.profile_link}` : 'https://via.placeholder.com/50',
        }));
        return followers;
    } catch (error) {
        console.error('Error fetching followers:', error);
        throw error;
    }
};

/// ENDPOINTS DE CATEGORIA

// 1. GET /api/category/ -> Obtiene todas las categorías disponibles en la base de datos.
export const fetchCategories = async (token) => {
    const response = await axios.get(`${config.route}/api/category/`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
};


/// ENDPOINTS DE ASISTENCIA

// 2. POST /api/attendee/invite -> El usuario invita a otros usuarios a un evento privado
const inviteFollowersToEvent = async (token, eventId, followerIds) => {
    const url = `${config.route}/api/attendee/invite`;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    const requestBody = {
        id_event: eventId,
        id_user: followerIds
    };

    const response = await axios.post(url, requestBody, configaxios);
    return response.data;
};

// 3. GET /api/attendee/accepted/{event_id}/?page={page}&page_size={page_size} -> Para obtener asistentes de un evento en particular
const getAcceptedAttendees = async (token, eventId, page = 1, pageSize = 100) => {
    const response = await axios.get(`${config.route}/api/attendee/accepted/${eventId}/?page=${page}&page_size=${pageSize}`, {
        headers: { "Authorization": `Bearer ${token}` },
    });

    const attendees = response.data.map(attendee => ({
        ...attendee,
        profile_link: attendee.profile_link ? `${config.images_route}/${attendee.profile_link}` : null,
    }));
    return attendees;
};


/// ENDPOINTS DE REVIEW

// 5. GET /api/review/event/{id_event}/?page={page}&page_size={page_size} -> Para obtener las reseñas de un evento
export const getReviews = async (token, reviewId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/review/event/${reviewId}`;
    const response = await axios.get(url, configaxios);
    return response.data;
};

// Obtener el promedio de todas las reseñas asociadas a un evento
export const getAverageReviewRate = async (token, eventId) => {
    let totalRate = 0; // Para acumular la suma de las valoraciones
    let totalReviews = 0; // Para contar cuántas reseñas hay
    let currentPage = 1;
    const pageSize = 20; // Tamaño de página por defecto
  
    try {
      while (true) {
        // Obtener las reseñas de la página actual
        const configaxios = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const url = `${config.route}/api/review/event/${eventId}/?page=${currentPage}&page_size=${pageSize}`;
        const response = await axios.get(url, configaxios);
        const reviews = response.data;
  
        // Si no hay más reseñas, salimos del bucle
        if (reviews.length === 0) break;
  
        // Acumular la suma de valoraciones y contar reseñas
        totalRate += reviews.reduce((sum, review) => sum + review.rate, 0);
        totalReviews += reviews.length;
  
        // Pasar a la siguiente página
        currentPage++;
      }
  
      // Si no hay reseñas, devolver null o un mensaje adecuado
      if (totalReviews === 0) {
        return { averageRate: null, message: 'No hay reseñas disponibles para este evento.' };
      }
  
      // Calcular el promedio
      const averageRate = totalRate / totalReviews;
      return { averageRate, message: 'Promedio calculado exitosamente.' };
  
    } catch (error) {
      console.error('Error al obtener las reseñas:', error);
      return { averageRate: null, message: 'Error al obtener las reseñas.' };
    }
  };

/// ENDPOINTS DE ESTADÍSTICAS

// 1. GET /analytics/event/{event_id} -> Obtiene las estadísticas de un evento.
const getEventAnalytics = async (token, eventId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/analytics/event/${eventId}`;
    try {
        const response = await axios.get(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las estadísticas del evento:', error);
        throw error;
    }
};

// 2. GET /analytics/organizer/{organizer_id} -> Obtiene las estadísticas de un organizador.
const getOrganizerAnalytics = async (token) => {
    const userInfo = await fetchUserData(token);
    const organizerId = userInfo.id_user;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/analytics/organizer/${organizerId}`;
    try {
        const response = await axios.get(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las estadísticas del organizador:', error);
        throw error;
    }
};

/// ENDPOINTS DE POST

// 1. POST /api/post/create -> Crea un nuevo post en un evento.
export const createPost = async (token, postData) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/post/create`;
    const response = await axios.post(url, postData, configaxios);
    return response.data;
};

// 2. GET /api/post/event/{event_id} -> Obtiene los posts de un evento específico.
export const getEventPosts = async (token, eventId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/post/event/${eventId}`;
    const response = await axios.get(url, configaxios);
    return response.data;
};

// 4. PUT /api/post/{post_id} -> Editar un post existente mediante su ID.
export const updatePost = async (token, postId, postData) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/post/${postId}`;
    const response = await axios.put(url, postData, configaxios);
    return response.data;
};

// 5. DELETE /api/post/{post_id} -> Para eliminar un post de aviso
export const deletePost = async (token, postId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${config.route}/api/post/${postId}`;
    const response = await axios.delete(url, configaxios);
    return response.data;
};


/// ENDPOINTS DE EVENT TEMPLATES

// 1. POST /api/event_template/create -> Crea una nueva plantilla de evento.
export const createTemplate = async (token, templateData) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    
    const url = `${config.route}/api/event_template/create`;
    const response = await axios.post(url, templateData, configaxios);
    return response.data;
};

// 2. PUT /api/event_template/{template_id} -> Actualiza una plantilla de evento existente.
export const updateTemplate = async (token, templateId, data) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/event_template/${templateId}`;
    try {
        const response = await axios.put(url, data, configaxios);
        return response.data; // Devuelve la respuesta del servidor
    } catch (error) {
        console.error('Error updating template:', error.response || error);
        throw error; // Puedes decidir manejar o propagar el error
    }
};

// 4. DELETE /api/event_template/{template_id} -> Elimina una plantilla de evento existente.
export const deleteTemplate = async (token, templateId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    //const url2 = `${config.route}/api/event_template/allmy`;
    //console.log(url2);
    const url = `${config.route}/api/event_template/${templateId}`;
    //const url2 = `${config.route}/api/event_template/${templateId}`;
    //console.log(url2);
    //const response2 = await axios.get(url, configaxios);
    //console.log(response2.data);
    const response = await axios.delete(url, configaxios);
    return response.data;
};

// 5. GET /api/event_template/allmy -> btiene todos los templates de eventos creados por el usuario autenticado.
export const fetchTemplates = async (token) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    
    const url = `${config.route}/api/event_template/allmy`;
    const response = await axios.get(url, configaxios);
    return response.data;
};


//SearchBar
// Buscar usuarios en la base de datos por nombre o apellido
export const searchUsers = async (token, searchText, page = 1) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/user/search/?name=${searchText}&page=${page}`;
    const response = await axios.get(url, configaxios);
    return response.data.users; // Suponiendo que la respuesta contiene un array de usuarios
};

// Buscar eventos por descripción y si el usuario sigue o no los eventos
export const searchEvents = async (token, searchText, page = 1, onlyFollowed = false) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/event/search/?description=${searchText}&page=${page}&only_followed=${onlyFollowed}`;
    const response = await axios.get(url, configaxios);
    return response.data.events; // Suponiendo que la respuesta contiene un array de eventos
};


// Función para obtener eventos filtrados
export const getFilteredEvents = async ({
  token,
  description = "",
  page = 1,
  onlyFollowed = false,
  location = "",
  startDate = "",
  endDate = "",
  categories = "",
}) => {
  const configAxios = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };



  // Construcción dinámica de la URL
  let urlFilteredEvents = `${config.route}/api/event/search/?description=${description}&page=${page}&only_followed=${onlyFollowed}`;

  if (location) urlFilteredEvents += `&location=${location}`;
  if (startDate) urlFilteredEvents += `&start_date=${convertUTCToLocal(startDate)}`;
  if (endDate) urlFilteredEvents += `&end_date=${convertUTCToLocal(endDate)}`;
  if (categories) urlFilteredEvents += `&categories=${categories}`;

  try {
    const response = await axios.get(urlFilteredEvents, configAxios);

    // Verificamos si hay eventos y los ordenamos por fecha de inicio
    if (Array.isArray(response.data.events)) {
      const sortedEvents = response.data.events.sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date)
      );

      // Ajustamos las fechas y las rutas de las imágenes
      const updatedEvents = sortedEvents.map((event) => ({
        ...event,
        start_date: convertUTCToLocal(event.start_date),
        end_date: convertUTCToLocal(event.end_date),
        link_photo: `${config.images_route}/${event.link_photo}`,
      }));

      return updatedEvents;
    } else {
      throw new Error("No se encontraron eventos en la respuesta");
    }
  } catch (error) {
    console.error("Error fetching filtered events:", error.response || error.message);
    throw error;
  }
};


// DENUNCIAS

// POST /api/reported_user/create -> Crea un reporte de un usuario
export const ReportUser = async (token, idUserReported, reason) => {
    try {
        const response = await axios.post(
            `${config.route}/api/reported_user/create`,
            {
                id_user_reported: idUserReported,
                reason: reason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        return response.data; // Devuelve el reporte creado
    } catch (error) {
        if (error.response?.status === 400) {
            if (error.response?.data?.detail) {
                if (error.response.data.detail === "Ya has reportado a este usuario anteriormente") {
                    return { alreadyReported: true, message: error.response.data.detail }; // Retorna un indicador de reporte duplicado
                }
            }
        } else {
            console.error('Error al crear el reporte de pregunta:', error);
            throw error;
        }
    }
};

// POST /api/reported_event/create -> Crea un reporte de un evento
export const ReportEvent = async (token, idEventReported, reason) => {
    try {
        const response = await axios.post(
            `${config.route}/api/reported_event/create`,
            {
                id_event: idEventReported,
                reason: reason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        return response.data; // Devuelve el reporte creado
    } catch (error) {
        if (error.response?.status === 400) {
            if (error.response?.data?.detail === "Ya has reportado a este usuario anteriormente") {
                return { alreadyReported: true, message: error.response.data.detail }; // Retorna un indicador de reporte duplicado
            }
        } else {
            console.error('Error al crear el reporte de pregunta:', error);
            throw error;
        }
    }
}

// POST /api/reported_review/create -> Crea un reporte de una reseña
export const ReportEventReview = async (token, idReviewReported, reason) => {
    try {
        const response = await axios.post(
            `${config.route}/api/reported_review/create`,
            {
                id_review: idReviewReported,
                reason: reason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        return response.data; // Devuelve el reporte creado
    } catch (error) {
        if (error.response?.status === 400) {
            if (error.response?.data?.detail === "Ya has reportado esta reseña anteriormente") {
                return { alreadyReported: true, message: error.response.data.detail }; // Retorna un indicador de reporte duplicado
            }
        } else {
            console.error('Error al crear el reporte de pregunta:', error);
            throw error;
        }
    }
}


export const ReportEventQuestion = async (token, idQuestionReported, reason) => {
    try {
        const response = await axios.post(
            `${config.route}/api/reported_question/create`,
            {
                id_question: idQuestionReported,
                reason: reason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        return response.data;
    } catch (error) {
        if (error.response?.status === 400) {
            // Manejar error de reporte duplicado
            if (error.response?.data?.detail === "Ya has reportado esta pregunta anteriormente") {
                return { alreadyReported: true, message: error.response.data.detail }; // Retorna un indicador de reporte duplicado
            }
        } else {
            console.error('Error al crear el reporte de pregunta:', error);
            throw error;
        }
    }
}

// PREGUNTAS
// GET /api/questions/event/{event_id}
export const getEventQuestions = async (token, eventId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/questions/event/${eventId}`;
    const response = await axios.get(url, configaxios);
    return response.data;
}

// POST /api/questions/create/{event_id}
export const createQuestion = async (token, eventId, question) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/questions/create/${eventId}`;
    try {
        const response = await axios.post(url, { question }, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al crear la pregunta:', error);
        throw error;
    }
}

// POST /api/questions/answer/{id_question}
export const answerQuestion = async (token, questionId, answer) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    const url = `${config.route}/api/questions/answer/${questionId}`;
    try {
        const response = await axios.post(url, { reply: answer }, configaxios); // Cambia 'answer' a 'reply'
        return response.data;
    } catch (error) {
        console.error('Error al responder la pregunta:', error);
        throw error;
    }
}

// DELETE /api/questions/remove/{id_question}
export const deleteQuestion = async (token, questionId) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/questions/remove/${questionId}`;
    try {
        const response = await axios.delete(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar la pregunta:', error);
        throw error;
    }
}


// GRUPOS DE INVITADOS

// Crear un grupo de usuarios
export const createUserGroup = async (token, groupName, userIds) => {
    const url = `${config.route}/api/user_group/create`;
    //console.log(url);
    //console.log(token);
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };
    const requestBody = {
        name: groupName,
        id_users: userIds,
    };

    try {
        const response = await axios.post(url, requestBody, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al crear grupo de usuarios:', error.response || error);
        throw error;
    }
};

// Obtener todos los grupos del usuario autenticado
export const fetchUserGroups = async (token) => {
    const url = `${config.route}/api/user_group/allmy`;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.get(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al obtener grupos de usuarios:', error.response || error);
        throw error;
    }
};

// Eliminar un grupo de usuarios
export const deleteUserGroup = async (token, groupId) => {
    const url = `${config.route}/api/user_group/delete/${groupId}`;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.delete(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar grupo de usuarios:', error.response || error);
        throw error;
    }
};

// Agregar un miembro a un grupo
export const addMemberToGroup = async (token, groupId, userId) => {
    const url = `${config.route}/api/user_group/add_member`;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };
    const requestBody = {
        group_id: groupId,
        id_user: userId,
    };

    try {
        const response = await axios.post(url, requestBody, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al agregar miembro al grupo:', error.response || error);
        throw error;
    }
};

// Eliminar un miembro de un grupo
export const removeMemberFromGroup = async (token, groupId, userId) => {
    const url = `${config.route}/api/user_group/delete_member/${groupId}/${userId}`;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.delete(url, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar miembro del grupo:', error.response || error);
        throw error;
    }
};

// Actualizar el nombre de un grupo
export const updateGroupName = async (token, groupId, newName) => {
    const url = `${config.route}/api/user_group/update_group_name/${groupId}/${newName}`;
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.put(url, {}, configaxios);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar nombre del grupo:', error.response || error);
        throw error;
    }
};


// Recomended Users
export const getRecommendedUsers = async (token) => {
    try {
      const response = await axios.get(
        `${config.route}/feed/recommended_users`, // URL del endpoint
        {
          headers: {
            Authorization: `Bearer ${token}`, // Token de autorización
          },
        }
      );
      return response.data; // Retorna la data obtenida
    } catch (error) {
      console.error("Error al obtener usuarios recomendados:", error);
      throw error; // Propaga el error para manejarlo externamente
    }
  };
  
// Resend Verification Email
export const resendVerificationEmail = async (email) => {
    try {
        const response = await axios.post(
            `${config.route}/api/user/resend-verification-email?email=${encodeURIComponent(email)}`
        );
        return response.data;
    } catch (error) {
        console.error('Error al reenviar el correo de verificación:', error);
        throw error;
    }
};

// Horarios de usuario
export const getUserAvailability = async (token, userId, startDate, endDate) => {
    const configaxios = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${config.route}/api/event/user_schedule/${userId}?start_date=${startDate}&end_date=${endDate}`;
    try {
        console.log(`Fetching availability for user ${userId} with URL:`, url);

        const response = await axios.get(url, configaxios);

        return response.data;
    } catch (error) {
        if (error.response) {
            console.warn(`Error ${error.response.status} fetching user ${userId}:`, error.response.data);

            if (error.response.status === 403) {
                return {
                    detail: `User ${userId} is private or inaccessible`,
                    status: error.response.status
                };
            }

            return {
                detail: `Error fetching user ${userId}: ${error.response.data.detail || 'Unknown error'}`,
                status: error.response.status
            };
        }

        console.error(`Unknown error fetching user ${userId}:`, error.message);
        return {
            detail: `Unknown error fetching user ${userId}`,
            status: 500 
        };
    }
};

// Actualizar descuento de un evento
export const updateEventDiscount = async (eventId, token, discountType, discountDetail, linkPhoto) => {
    try {
      const response = await axios.put(
        `${config.route}/api/event/${eventId}`, // URL del endpoint con el ID del evento
        {
          discount_type: discountType,
          discount_detail: discountDetail,
          link_photo: linkPhoto, // Agregamos link_photo al cuerpo de la solicitud
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Token de autorización
          },
        }
      );
      return response.data; // Retorna la data obtenida
    } catch (error) {
      console.error("Error al actualizar el descuento del evento:", error);
      throw error; // Propaga el error para manejarlo externamente
    }
  };
  

export { getEvents, getAllEvents, getFollowers, getUserInfo, fetchProfileData, updateProfile, fetchUserData, getEventDetails,
    deleteEvent, createEvent, inviteFollowersToEvent, getAcceptedAttendees, getFollowingProfile, getFollowersProfile, getOrganizerAnalytics, getEventAnalytics,
    getUserInfoById, getFeed, reloadFeed, getFollowerRequests, deleteFollowerRequest, acceptFollowerRequest, createFollowerRequest, getUserEvents, updateEvent
};