import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { useNavigate } from 'react-router-dom';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { createEvent, fetchCategories, getFollowers, getUserInfo, createTemplate, fetchTemplates, 
    deleteTemplate, updateTemplate, fetchUserGroups, getUserInfoById } from '../../services/api';
import FollowersModal from '../../components/ModalAsistence/FollowersModal';
import GroupsModal from '../../components/ModalAsistence/GroupsModal';
import GroupDetailsModal from '../../components/ModalAsistence/GroupDetailsModal';
import { formatDateWithoutZ } from '../../services/format';
import { IoMdInformationCircleOutline } from "react-icons/io";


const CreateEvent = () => {
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [dates, setDates] = useState([{ start_date: null, end_date: null }]);
    const [location, setLocation] = useState('');
    const [url, setUrl] = useState('');
    const [typeOfEvent, setTypeOfEvent] = useState('public');
    const [capacity, setCapacity] = useState('');
    const [price, setPrice] = useState('');

    const [autocomplete, setAutocomplete] = useState(null);
    const [imageFiles, setImageFiles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [followers, setFollowers] = useState([])
    const [selectedFollowers, setSelectedFollowers] = useState([]);
    const [isFollowersModalOpen, setFollowersModalOpen] = useState(false);
    const [isTemplateActive, setTemplateActive] = useState(false); // Estado para el ticket de plantilla
    const [templateName, setTemplateName] = useState(''); // Nombre de la plantilla
    const [activationDate, setActivationDate] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [isActivationExpirationActive, setActivationExpirationActive] = useState(false);
    const [templates, setTemplates] = useState([]); 
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
    const [userPrivacy, setUserPrivacy] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [isGroupsModalOpen, setGroupsModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isGroupDetailsOpen, setGroupDetailsOpen] = useState(false);   

    const [errors, setErrors] = useState({});

    const [remainingTitleChars, setRemainingTitleChars] = useState(50); // Máximo 30
    const [remainingDescChars, setRemainingDescChars] = useState(800); // Máximo 400

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleApiKey,
        libraries: ["places"],
    });
    

    // Nombre del evento
    const handleEventName = (e) => {
        const value = e.target.value.slice(0, 50); // Limita a 30 caracteres
        setEventName(value);
        setRemainingTitleChars(50 - value.length);
    };
    
    // Descripción del evento
    const handleEventDescription = (e) => {
        const value = e.target.value.slice(0, 800); // Limita a 400 caracteres
        setEventDescription(value);
        setRemainingDescChars(800 - value.length);
    };

    // Ubicación del evento 
    const handleLocation = (e) => setLocation(e.target.value);

    // Selección de lugar autocompletado
    const handlePlaceSelect = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            setLocation(place.formatted_address);
        }
    };

    // URL del evento
    const handleUrl = (e) => setUrl(e.target.value);

    // Tipo de evento
    const typeOptions = userPrivacy
    ? { 
        public: { label: 'Público', description: 'Todos tus seguidores podrán ver este evento' }, 
        private: { label: 'Privado', description: 'Solo usuarios invitados podrán ver este evento' } 
      }
    : { 
        public: { label: 'Público', description: 'Cualquier usuario podrá ver este evento' }, 
        private: { label: 'Privado', description: 'Solo usuarios invitados podrán ver este evento' }, 
      };


    const handleType = (e) => {
        setTypeOfEvent(e.target.value);
    };

    // Manejo de imagenes
    const handleImage = (e) => {
        const selectedFiles = Array.from(e.target.files); // Convierte los archivos seleccionados en un array
            
        // Acumula las nuevas imágenes con las anteriores y limita a 4 en total
        setImageFiles((prevImages) => {
            const updatedImages = [...prevImages, ...selectedFiles] // Sumar y limitar a 4 imágenes
            // Evitar agregar duplicados (comparar por nombre de archivo)
        const uniqueImages = updatedImages.reduce((acc, current) => {
            if (!acc.some(file => file.name === current.name)) {
                acc.push(current);
            }
            return acc;
        }, []);
        //console.log('Imágenes únicas actualizadas:', uniqueImages);
        return uniqueImages.slice(0, 4);
        });
    };

    const handleRemoveImage = (index) => {
        setImageFiles(prevImages => prevImages.filter((_, i) => i !== index)); // Elimina la imagen por índice
    };

    // Capacidad del evento
    const handleCapacity = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && (value === '' || parseInt(value) <= 2000)) {
            setCapacity(value);
        }
    };

    // Precio del evento
    const handlePrice = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setPrice(value);
        }
    };

    // Cateogorías del evento
    const handleCategorySelect = (categoryId) => {
        setSelectedCategories(prevSelectedCategories => {
            if (prevSelectedCategories.includes(categoryId)) {
                return prevSelectedCategories.filter(id => id !== categoryId);
            } else if (prevSelectedCategories.length < 3) {
                return [...prevSelectedCategories, categoryId];
            }
            return prevSelectedCategories;
        });
    };

    // Manejo de fechas
    const handleDate = (index, field, value) => {
        setDates(currentDates => {
            const newDates = [...currentDates];
            const currentEntry = { ...newDates[index] };

            if (field === 'start_date') {
                const now = new Date();
                if (value < now) {
                    alert("La fecha y hora de inicio no puede ser en el pasado.");
                    return currentDates;
                }
                currentEntry.start_date = value;
                if (!currentEntry.end_date || value >= currentEntry.end_date) {
                    const newEndDate = new Date(value);
                    newEndDate.setHours(newEndDate.getHours());
                    currentEntry.end_date = newEndDate;
                }
            } else if (field === 'end_date') {
                if (value < currentEntry.start_date) {
                    value = new Date(currentEntry.start_date.getTime());
                    value.setHours(value.getHours());
                }
                currentEntry.end_date = value;
            }
            newDates[index] = currentEntry;
            return newDates;
        });
    };

    const addDate = () => setDates([...dates, { start_date: '', end_date: '' }]);
    const removeDate = index => setDates(dates.filter((_, idx) => idx !== index))

    // Activar opcion de activación y expiración
    const handleActivationExpirationToggle = () => {
        setActivationExpirationActive(!isActivationExpirationActive);
        if (isActivationExpirationActive) {
            setActivationDate(null);
            setExpirationDate(null);
            errors.activationDate = '';
            errors.expirationDate = '';
        }
    };

    // Manejo de fechas de activación (opcional)
    const handleActivationDateChange = (date) => {
        const now = new Date();
        if (date < now) {
            alert("La fecha y hora de activación no puede ser en el pasado.");
            return;
        }
        setActivationDate(date);
    };

    // Manejo de fechas de expiración (opcional)
    const handleExpirationDateChange = (date) => {
        setExpirationDate(date);
    };

    const handleAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };


    const handleSubmit = async (e) => {
        //console.log('submit');
        e.preventDefault();
        if (!validateForm()) return;
    
        setIsCreating(true);

        // Campos no obligatorios
        //console.log("type", price.type)
        const finalPrice = price.trim() === '' ? null : parseInt(price, 10);
        const finalCapacity = capacity.trim() === '' ? null : parseInt(capacity, 10);
        const finalUrl = url ? url.trim() === '' ? null : url : null;
        

        
        //console.log("start_date", dates[0].start_date)
        //console.log("end_date", dates[0].end_date)
        
        const formattedDates = dates.map(date => ({
            start_date: formatDateWithoutZ(date.start_date),
            end_date: formatDateWithoutZ(date.end_date)
        }));
        
        //console.log("formattedDates", formattedDates)
    
        const requestBody = {
            title: eventName,
            description: eventDescription,
            location: location,
            url: finalUrl,
            event_type: typeOfEvent,
            capacity: finalCapacity,
            status: "active",
            price: finalPrice,
            open_chat: true,
            visibility_attendees: true,
            dates: formattedDates,
            categories: selectedCategories,
        };



        if (isActivationExpirationActive) {
            requestBody.activation_date = formatDateWithoutZ(activationDate);
            requestBody.expiration_date = formatDateWithoutZ(expirationDate);
        }

        try {
            const token = await getAccessTokenSilently();

            //console.log('requestBody:', requestBody);
            const response = await createEvent(token, requestBody, imageFiles, selectedFollowers, typeOfEvent);
            //console.log('Evento creado:', response);


            // Crear plantilla si está activa
            if (isTemplateActive && templateName.trim()) {
                const finalPriceTemplate = (price == null || price.trim() === '') ? 0 : parseInt(price.trim(), 10);
                const finalCapacityTemplate = (capacity == null || capacity.trim() === '') ? 2000 : parseInt(capacity.trim(), 10);

                const templateRequestBody = {
                    title: templateName,
                    description: eventDescription,
                    location: location,
                    event_type: typeOfEvent,
                    capacity: finalCapacityTemplate,
                    status: "active",
                    price: finalPriceTemplate,
                    open_chat: true,
                    visibility_attendees: true,
                    banner: "default_banner.png"
                };
                await createTemplate(token, templateRequestBody);
            }

            navigate('/my_events');
        } catch (error) {
            console.error('Error creating event:', error);
            setIsCreating(false);
            alert('No se pudo crear el evento. Por favor revise los campos adjuntos e inténtelo de nuevo.');
        }
    };

    // Obligatorio -> Nombre, descripción, Ubicación, tipo, status, open_chat, visibility_attendees, fecha, categorias
    const validateForm = () => {
        const errors = {};
        let errorMessage = '';

        // Validación del nombre del evento
        if (!eventName.trim()) {
            errors.eventName = 'Debe ingresar un nombre para el evento';
            errorMessage += errors.eventName + '\n';
        }

    // Validación de fechas
    if (!dates.length) {
        errors.dates = 'Debe agregar al menos una fecha de inicio y término';
        errorMessage += errors.dates + '\n';
    } else {
        dates.forEach((date, index) => {
            if (!date.start_date) {
                errors[`dates_start_${index}`] = `Debe ingresar una fecha de inicio`;
                errorMessage += errors[`dates_start_${index}`] + '\n';
            }
            if (!date.end_date) {
                errors[`dates_end_${index}`] = `Debe ingresar una fecha de término`;
                errorMessage += errors[`dates_end_${index}`] + '\n';
            }
            if (date.start_date && date.end_date && date.start_date >= date.end_date) {
                errors[`dates_${index}`] = `La fecha de inicio debe ser anterior a la fecha de término`;
                errorMessage += errors[`dates_${index}`] + '\n';
            }
        });
    }

    // Validación de categorías
    if (selectedCategories.length === 0) {
        errors.categories = 'Debe seleccionar al menos una categoría para el evento';
        errorMessage += errors.categories + '\n';
    }

    // Validación de la ubicación
    if (!location.trim()) {
        errors.location = 'Debe ingresar una ubicación para el evento';
        errorMessage += errors.location + '\n';
    }

    // Validación de la descripción
    if (!eventDescription.trim()) {
        errors.eventDescription = 'Debe ingresar una descripción para el evento';
        errorMessage += errors.eventDescription + '\n';
    }

    // Validación de activación/expiración (opcional)
    if (isActivationExpirationActive) {
        if (!activationDate) {
            errors.activationDate = 'Debe ingresar una fecha de activación';
            errorMessage += errors.activationDate + '\n';
        }
        if (!expirationDate) {
            errors.expirationDate = 'Debe ingresar una fecha de expiración';
            errorMessage += errors.expirationDate + '\n';
        }
        if (activationDate && expirationDate && expirationDate <= activationDate) {
            errors.expirationDate = 'La fecha de expiración debe ser posterior a la fecha de activación';
            errorMessage += errors.expirationDate + '\n';
        }
    }


        if (errorMessage) {
            alert('Por favor, complete lo siguiente:\n' + errorMessage);
            setErrors(errors); // Actualizar los errores para mostrar en el formulario
        }

        //console.log('errors:', errors);
        // Actualizar los errores y devolver el resultado
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    useEffect(() => {
        const loadCategories = async () => {
            try {
                const token = await getAccessTokenSilently();
                const fetchedCategories = await fetchCategories(token);
                setCategories(fetchedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const loadFollowers = async () => {
            try {
                const token = await getAccessTokenSilently();
                const userInfo = await getUserInfo(token);
                const fetchedFollowers = await getFollowers(token, userInfo.id);
                setFollowers(fetchedFollowers);
                const nonOrganizers = await Promise.all(
                    fetchedFollowers.map(async (follower) => {
                        try {
                            const userDetails = await getUserInfoById(token, follower.id_user); 
                            if (!userDetails.organizer) {
                                return follower; // Solo devolver si no es organizador
                            }
                        } catch (error) {
                            console.error(`Error fetching user details for ID ${follower.id_user}:`, error);
                        }
                        return null; 
                    })
                );
        
                // Filtrar valores nulos y establecer en el estado
                setFollowers(nonOrganizers.filter(Boolean));

            } catch (error) {
                console.error('Error fetching followers:', error);
            }
        };
        
        const loadTemplates = async () => {
            try {
                const token = await getAccessTokenSilently();
                const fetchedTemplates = await fetchTemplates(token); // Llamada a la API para obtener las plantillas
                setTemplates(fetchedTemplates);
                setIsLoadingTemplates(false);
            } catch (error) {
                console.error('Error fetching templates:', error);
                setIsLoadingTemplates(false);
            }
        };

        const fetchuserPrivacy = async () => {
            
            const token = await getAccessTokenSilently();
            const userInfo = await getUserInfo(token);
            setUserPrivacy(userInfo.profile_privacy);            
        };

        const loadGroups = async () => {
            try {
                const token = await getAccessTokenSilently();
                const data = await fetchUserGroups(token);
                // Mapea la respuesta para incluir el `group_id`
                const groupsArray = Object.entries(data).map(([groupId, group]) => ({
                    ...group,
                    group_id: parseInt(groupId, 10), // Agrega el `group_id` usando la clave del objeto
                }));
                setGroups(groupsArray); 
            } catch (error) {
                console.error('Error al cargar los grupos:', error);
            }
        };

        if (isGroupDetailsOpen) {
            loadGroups(); // Si el modal está abierto, recarga los grupos
        } else {
            loadCategories();
            loadFollowers();
            loadTemplates();
            fetchuserPrivacy();
            loadGroups(); 
        }
    }, [getAccessTokenSilently, isGroupDetailsOpen]);

    const resetForm = () => {
        setEventName('');
        setEventDescription('');
        setDates([{ start_date: null, end_date: null }]);
        setLocation('');
        setUrl('');
        setTypeOfEvent('public');
        setCapacity('');
        setPrice('');
        setSelectedCategories([]);
        setSelectedTemplate(null);
        setImageFiles([]);
        setActivationDate(null);
        setExpirationDate(null);
    };


    // TEMPLATE

    const handleTemplateSelectionChange = (e) => {
        const templateId = parseInt(e.target.value);
        if (!templateId) {
            setSelectedTemplate(null);
            resetForm();  
            return;
        }
        const template = templates.find(t => t.id_template === templateId);
        handleTemplateSelect(template);
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setEventName(template.title);
        setEventDescription(template.description);
        setLocation(template.location);
        setUrl(template.url);
        setTypeOfEvent(template.event_type);
        setCapacity(template.capacity.toString());
        setPrice(template.price.toString());
        setSelectedCategories(template.categories || []);
    };

    const handleUpdateTemplate = async () => {
        if (!selectedTemplate) {
            alert('No template selected');
            return;
        }

        const finalPricetemplate = (price == null || price.trim() === '') ? 0 : parseInt(price.trim(), 10);
        const finalCapacitytemplate = (capacity == null || capacity.trim() === '') ? 2000 : parseInt(capacity.trim(), 10);

        const updateData = {            
            title: selectedTemplate.title,
            description: eventDescription,
            location: location,
            event_type: typeOfEvent,
            link_photo: "2c6d569a8b02423aabd0e9c8e7e65f22evento_default_foto.png",
            capacity: finalCapacitytemplate,
            status: selectedTemplate.status,
            price: finalPricetemplate,
            open_chat: selectedTemplate.open_chat,
            visibility_attendees: selectedTemplate.visibility_attendees,
            banner: selectedTemplate.banner
        };
    
        try {
            const token = await getAccessTokenSilently(); 
            const updatedTemplate = await updateTemplate(token, selectedTemplate.id_template, updateData);
            alert('Template actualizado correctamente');
        } catch (error) {
            alert('Error al actualizar template');
        }
    };

    const handleDeleteTemplate = async (templateId) => {
        try {
            const token = await getAccessTokenSilently();
            await deleteTemplate(token, templateId);
            setTemplates(templates.filter(template => template.id_template !== templateId));
            if (selectedTemplate && selectedTemplate.id_template === templateId) {
                setSelectedTemplate(null);
                resetForm();
            }
        } catch (error) {
            console.error('Error deleting template:', error);
        }
    };

    const handleFollowerSelect = (followerId) => {
        setSelectedFollowers(prevSelectedFollowers => {
            if (prevSelectedFollowers.includes(followerId)) {
                return prevSelectedFollowers.filter(id => id !== followerId);
            } else {
                return [...prevSelectedFollowers, followerId];
            }
        });
    };

    const handleGroupSelect = (groupId) => {
        setSelectedGroups(prevSelectedGroups => {
            if (prevSelectedGroups.includes(groupId)) {
                return prevSelectedGroups.filter(id => id !== groupId);
            } else {
                return [...prevSelectedGroups, groupId];
            }
        });
    };

    const handleGroupCreated = (newGroup) => {
        setGroups((prevGroups) => [...prevGroups, newGroup]);
    };

    const handleOpenGroupDetails = (groupId) => {
        setSelectedGroupId(groupId); 
        setGroupDetailsOpen(true);
    };
    
    const handleGroupUpdate = (updatedGroup) => {
        setGroups((prevGroups) =>
            prevGroups.map((group) =>
                group.group_id === updatedGroup.group_id ? updatedGroup : group
            )
        );
        setSelectedGroupId(updatedGroup);
    };

    const handleGroupDelete = (deletedGroupId) => {
        setGroups((prevGroups) =>
            prevGroups.filter((group) => group.group_id !== deletedGroupId)
        );
        setGroupDetailsOpen(false);
    };


    



    return (
        <div className="min-h-screen bg-gray-100 pt-4 flex justify-center">
            {isCreating && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl font-semibold mb-4">Creando evento...</h2>
                        <p className="text-gray-700">Por favor, espera mientras se crea el evento.</p>
                    </div>
                </div>
            )}
            <div className="max-w-4xl p-6 bg-white rounded-lg shadow-lg">
                <div className="bg-white-700 p-4 rounded-md mb-6 text-center">
                    <h1 className="text-4xl font-bold mb-8 text-custom-blue text-center">Crear Evento</h1>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nombre del evento(<span className="text-red-500">*</span>):</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={eventName}
                            onChange={handleEventName}
                            placeholder="Escribe el título del evento"
                        />
                        {errors.eventName && <p className="text-red-500 text-sm">{errors.eventName}</p>}
                        <p className="text-gray-500 text-xs mt-1">
                            {remainingTitleChars} caracteres restantes
                        </p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Descripción del evento: (<span className="text-red-500">*</span>):</label>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded-md text-justify"
                            value={eventDescription}
                            onChange={handleEventDescription}
                            rows={3}
                            placeholder="Escribe una breve descripción del evento"
                        />
                        {errors.eventDescription && <p className="text-red-500 text-sm">{errors.eventDescription}</p>}
                        <p className="text-gray-500 text-xs mt-1">
                            {remainingDescChars} caracteres restantes
                        </p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Ubicación del evento: (<span className="text-red-500">*</span>):
                        </label>
                        {isLoaded ? (
                            <Autocomplete
                                onLoad={handleAutocompleteLoad}
                                onPlaceChanged={handlePlaceSelect}
                            >
                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    placeholder="Ingrese la ubicación del evento"
                                    value={location}
                                    onChange={handleLocation}
                                />
                            </Autocomplete>
                        ) : (
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Cargando ubicación..."
                                disabled
                            />
                        )}
                        {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
                    </div>


                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">URL del evento:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-md" value={url} onChange={handleUrl} placeholder="" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Tipo de evento(<span className="text-red-500">*</span>):</label>
                        {/* Selector de tipo de evento */}
                        <select 
                            id="eventType" 
                            value={typeOfEvent} 
                            onChange={handleType} 
                            className="w-full bg-white border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 text-back-700 text-sm"
                        >
                            {Object.entries(typeOptions).map(([value, { label }]) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>

                        {/* Mostrar todas las descripciones disponibles según la privacidad del usuario*/}
                        <div className="mt-2">
                            {Object.entries(typeOptions).map(([_, { label, description }]) => (
                                <p key={label} className="text-gray-500 text-xs mt-1">
                                    <strong>{label}:</strong> {description}
                                </p>
                            ))}
                        </div>
                    </div>

                    {(typeOfEvent === 'private') ? (
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb">Invitar a seguidores:</label>
                            <button
                                type="button"
                                className="mt-2 bg-custom-primary text-white p-2 rounded-md"
                                onClick={() => setFollowersModalOpen(true)}
                            >
                                ¿Desea invitar a seguidores?
                            </button>
                        </div>
                    ) : null}

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Foto del evento:</label>
                        <div>
                            {/* Input para seleccionar las imágenes */}
                            <input
                                type="file"
                                multiple
                                className="w-full p-2 border border-gray-300 rounded-md"
                                accept="image/png, image/jpeg"
                                onChange= {handleImage}
                            />

                            {/* Mostrar las imágenes seleccionadas */}
                            <div className="mt-4">
                                {imageFiles.length > 0 && (
                                    <ul>
                                        {imageFiles.map((file, index) => (
                                            <li key={index} className="mb-2">
                                                {file.name} {/* Nombre de la imagen */}
                                                <button
                                                    onClick={() => handleRemoveImage(index)} // Botón para eliminar la imagen
                                                    className="ml-4 text-red-600"
                                                >
                                                    Eliminar
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <p className="text-gray-500 text-xs mt-1">Formatos aceptados: jpg, jpeg, png.</p>
                        <p className="text-gray-500 text-xs mt-1">Si no se proporciona una imagen válida, se usará una imagen por defecto.</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Capacidad del evento:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-md" value={capacity} onChange={handleCapacity} />
                        {errors.capacity && <p className="text-red-500 text-sm">{errors.capacity}</p>}
                        <p className="text-gray-500 text-xs mt-1">La capacidad máxima permitida es de 2000 personas.</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Precio del evento:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-md" value={price} onChange={handlePrice} />
                        <p className="text-gray-500 text-xs mt-1">Si no se proporciona un precio, por defecto el evento tendrá precio cero.</p>
                    </div>

                    <div className="mb-8">
                        <label className="block text-gray-700 text-sm font-bold">Categorías del evento(<span className="text-red-500">*</span>):</label>
                        <p className="text-gray-500 text-xs mt-1 mb-4">Puedes seleccionar hasta 3 categorías.</p>
                        <div className="flex flex-wrap gap-2">
                            {categories.map((category) => (
                                <button
                                    key={category.id_category}
                                    type="button"
                                    className={`w-24 h-10 flex items-center justify-center rounded-full font-bold text-xs ${
                                        selectedCategories.includes(category.id_category) ? 'bg-custom-primary text-white' : 'bg-custom-tertiary text-custom-secondary'
                                    }`}
                                    onClick={() => handleCategorySelect(category.id_category)}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                        {errors.categories && <p className="text-red-500 text-sm mt-2">{errors.categories}</p>}
                    </div>

                    {dates.map((date, index) => (
                        <div key={index}>
                            <div className="mb-4 flex">
                                <div className="mr-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Fecha y hora de inicio(<span className="text-red-500">*</span>):</label>
                                    <DatePicker
                                        selected={date.start_date}
                                        onChange={date => handleDate(index, 'start_date', date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Hora"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        locale={es}
                                        minDate={new Date()}
                                    />
                                    {errors[`dates_start_${index}`] && <p className="text-red-500 text-sm mt-2">{errors[`dates_start_${index}`]}</p>}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Fecha y hora de término(<span className="text-red-500">*</span>):</label>
                                    <DatePicker
                                        selected={date.end_date}
                                        onChange={date => handleDate(index, 'end_date', date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Hora"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        locale={es}
                                        minDate={date.start_date}
                                    />
                                    {errors[`dates_end_${index}`] && <p className="text-red-500 text-sm mt-2">{errors[`dates_end_${index}`]}</p>}
                                </div>
                            </div>
                            {errors[`dates_${index}`] && <p className="text-red-500 text-sm mt-2">{errors[`dates_${index}`]}</p>}

                            {index > 0 && (
                                <button type="button" onClick={() => removeDate(index)} className="mb-4 bg-red-500 text-white p-2 rounded-md">Eliminar fecha {index + 1}</button>
                            )}
                        </div>
                    ))}

                    { /* <button type="button" onClick={addDate} className="mb-4 bg-custom-primary text-white p-2 rounded-md hover:bg-[#2C4570]">Agregar otra fecha</button> */}
                    

                    {(typeOfEvent != 'private') && (
                        <div className="mb-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    checked={isActivationExpirationActive}
                                    onChange={handleActivationExpirationToggle}
                                />
                                <span className="ml-2">¿Desea configurar fechas de activación y expiración?</span>
                            </label>
                            <p className="text-gray-500 text-xs mt-1">Fecha de activación corresponde a la fecha mínima de inscripción. Fecha de expiración corresponde a la fecha máxima de inscripción</p>
                        </div>
                    )}
                    
                    {isActivationExpirationActive && (
                        <div className="mb-4 flex">
                            <div className="mr-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Fecha de Activación:(<span className="text-red-500">*</span>)</label>
                                <DatePicker
                                    selected={activationDate}
                                    onChange={handleActivationDateChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Hora"
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    locale={es}
                                    minDate={new Date()}
                                />
                                {errors.activationDate && <p className="text-red-500 text-sm">{errors.activationDate}</p>}
                            </div>
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">Fecha de Expiración:(<span className="text-red-500">*</span>)</label>
                                <DatePicker
                                    selected={expirationDate}
                                    onChange={handleExpirationDateChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Hora"
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    locale={es}
                                    minDate={new Date()}
                                />
                                {errors.expirationDate && <p className="text-red-500 text-sm">{errors.expirationDate}</p>}
                            </div>
                        </div>
                    )}

                    <div className="mb-4">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox"
                                checked={isTemplateActive}
                                onChange={() => setTemplateActive(!isTemplateActive)}
                            />
                            <span className="ml-2">¿Desea crear el evento como plantilla?</span>
                        </label>
                        <p className="text-gray-500 text-xs mt-1">De esta manera podrá crear eventos similares de manera mas fácil.</p>
                    </div>

                    {isTemplateActive && (
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Nombre de la plantilla:</label>
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                                placeholder="Nombre de la plantilla"
                            />
                        </div>
                    )}

                    <button type="submit" className="w-full bg-custom-primary text-white p-2 rounded-md hover:bg-[#2C4570]">Crear</button>
                </form>
            </div>

            <div className="p-6">
                <div className="p-6">
                    <div className="bg-gray-200 p-6 rounded-md shadow">
                        <h2 className="text-lg font-semibold mb-3 text-center">Grupos de Invitados</h2>
                        
      
                        {groups && groups.length > 0 ? (
                            <ul>
                                {groups.map((group) => (
                                    <li
                                        key={group.group_id}
                                        className="flex justify-between items-center mb-4"
                                    >
                                        <span>{group.group_name}</span>
                                        <IoMdInformationCircleOutline
                                            className="text-custom-blue cursor-pointer"
                                            style={{
                                                fontSize: '1.2vw', // Tamaño proporcional al ancho de la ventana
                                                minWidth: '12px', // Tamaño mínimo para pantallas muy pequeñas
                                                minHeight: '12px',
                                                maxWidth: '32px', // Tamaño máximo para pantallas grandes
                                                maxHeight: '32px',
                                            }}
                                            onClick={() => handleOpenGroupDetails(group.group_id)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-gray-700 text-center mt-4">
                                Crea un grupo para facilitar la invitación de múltiples personas a tus eventos.
                            </p>
                        )}
                        <div className="flex flex-col items-center">
                            <button
                                className="mt-4 bg-custom-primary text-white px-4 py-2 rounded-md"
                                onClick={() => setGroupsModalOpen(true)}
                            >
                                Crear Grupo
                            </button>
                        </div>
                    </div>
                
                </div>




                <br></br>
                <div className="bg-gray-200 p-6 rounded-md shadow">
                    <h3 className="text-lg font-semibold mb-3">¿Desea utilizar un template?</h3>
                    {isLoadingTemplates ? (
                        <div className="text-center">
                            <p className="text-gray-500">...</p> 
                        </div>
                    ) : (
                        templates.length > 0 ? (
                            <select
                                value={selectedTemplate ? selectedTemplate.id_template : ''}
                                onChange={handleTemplateSelectionChange}
                                className="block appearance-none w-full bg-white border border-gray-400 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-4"
                            >
                                <option value="">Seleccionar Plantilla</option>
                                {templates.map((template) => (
                                    <option key={template.id_template} value={template.id_template}>{template.title}</option>
                                ))}
                            </select>
                        ) : (
                            <div> 
                                <p className="text-gray-500 text-center">Para utilizar plantillas:</p>
                                <p className="text-gray-500 text-center">Debe crear al menos 1 template.</p>
                            </div>
                        )
                    )}
                    {selectedTemplate && (
                        <div className="flex flex-col items-center w-full px-4">
                            <button
                                onClick={handleUpdateTemplate}
                                className="bg-custom-primary hover:bg-[#2C4570] text-white font-bold py-2 px-4 rounded w-full"  // w-full para hacer el botón de ancho completo
                            >
                                Actualizar Plantilla
                            </button>
                            <div className="my-2"></div> 
                            <button
                                onClick={() => handleDeleteTemplate(selectedTemplate.id_template)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full"  // w-full para hacer el botón de ancho completo
                            >
                                Eliminar Plantilla
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <FollowersModal
                isOpen={isFollowersModalOpen}
                onClose={() => setFollowersModalOpen(false)}
                followers={followers}
                selectedFollowers={selectedFollowers}
                handleFollowerSelect={handleFollowerSelect}
                groups={groups}
                selectedGroups={selectedGroups}
                handleGroupSelect={handleGroupSelect}
            />

            <GroupsModal
                isOpen={isGroupsModalOpen}
                onClose={() => setGroupsModalOpen(false)}
                followers={followers}
                onGroupCreated={handleGroupCreated}
            />

            <GroupDetailsModal
                isOpen={isGroupDetailsOpen}
                onClose={() => {
                    setGroupDetailsOpen(false);
                    setSelectedGroupId(null);
                }}
                groupId={selectedGroupId}
                groups={groups}
                followers={followers}
                onGroupUpdated={handleGroupUpdate}
                onGroupDeleted={handleGroupDelete}
            />

        </div>
    );
};

export default CreateEvent;