import React from 'react';

const AlertReportModal = ({ isOpen, title = "Mensaje", message, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-xl shadow-xl max-w-md w-full">
                <div className="text-center">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
                    <p className="text-gray-600 mb-6">{message}</p>
                    <button
                        onClick={onClose}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full transition-colors duration-300 shadow-md"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
        
    );
};

export default AlertReportModal;
