import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFollowingProfile } from '../services/api';
import config from '../configroutes';
import { useAuth0 } from "@auth0/auth0-react";
import { SearchIcon } from '@heroicons/react/solid';

const FollowingMyEvents = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { userId } = useParams();
    const navigate = useNavigate();
    const [following, setFollowing] = useState([]);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const pageSize = 20;
    const [isLoading, setIsLoading] = useState(true);

    const fetchFollowing = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            const data = await getFollowingProfile(token, userId, page, pageSize);
            if (data.length === 0 && page === 1) {
                setError('No tienes seguidos.');
            } else {
                setFollowing(data);
                setError('');
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setError('Usuario no encontrado.');
            } else {
                setError('Error al cargar los seguidos.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFollowing();
    }, [page]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleUserClick = (userId) => {
        navigate(`/profile/${userId}`);
    };

    const filteredFollowing = following.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (error) {
        return (
            <div className="container mx-auto text-center mt-20">
                <h1 className="text-3xl font-semibold mb-4">{error}</h1>
                <button onClick={handleGoBack} className="bg-custom-primary hover:bg-custom-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none">
                    Volver
                </button>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="container mx-auto text-center mt-20">
                <div className="inline-block animate-spin rounded-full h-16 w-16 border-t-4 border-custom-primary"></div>
                <h1 className="text-2xl font-medium mt-4">Cargando seguidos...</h1>
            </div>
        );
    }

    return (
        <div className="container mx-auto mt-10 text-center">
            <h1 className="text-3xl font-semibold mb-6 text-custom-primary">Seguidos</h1>
            <div className="mb-6 flex justify-center">
                <div className="relative w-2/3 md:w-1/2">
                    <input
                        type="text"
                        placeholder="Buscar usuario..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="p-3 border border-gray-300 rounded w-full pl-10 focus:outline-none focus:ring-2 focus:ring-custom-primary"
                        autoComplete="off"
                    />
                    <SearchIcon className="h-5 w-5 text-gray-500 absolute left-3 top-3" />
                </div>
            </div>
            <div className="overflow-auto max-h-96 px-4">
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredFollowing.map(user => (
                        <li key={user.id_user}
                            className="bg-white border rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                            onClick={() => handleUserClick(user.id_user)}>
                            <div className="flex items-center space-x-4">
                                <div className="relative">
                                    <img
                                        src={user.profile_link ? `${config.images_route}/${user.profile_link}` : '/images/imagen_perfil_default.png'}
                                        alt={`Avatar de ${user.name}`}
                                        className="w-16 h-16 rounded-full object-cover"
                                    />
                                </div>
                                <div className="text-left">
                                    <h2 className="font-semibold text-lg text-gray-800">{user.name}</h2>
                                    {user.description && (
                                        <p className="text-gray-600 text-sm mt-1">{user.description}</p>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-6 flex justify-center">
                <button onClick={handleGoBack} className="bg-custom-primary hover:bg-custom-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none">
                    Volver
                </button>
            </div>
        </div>
    );
};

export default FollowingMyEvents;
