import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { searchUsers, searchEvents } from '../../services/api'; // Funciones de API para la búsqueda
import { FaSearch, FaUser, FaCalendarAlt } from 'react-icons/fa'; // Íconos de usuario y evento
import { Link } from 'react-router-dom'; // Importamos Link para manejar la navegación
import config from '../../configroutes.js'; // Importamos config para las rutas

const SearchBar = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false); // Controlar apertura del dropdown
  const [scrollPosition, setScrollPosition] = useState(0); // Guardar la posición del scroll
  const timeoutIdRef = useRef(null);
  const dropdownRef = useRef(null); // Referencia para el dropdown (lista de resultados)
  const inputRef = useRef(null); // Referencia para el input

  const defaulteventimage = '../../images/evento_default_foto.png';
  const defaultuserimage = '../../images/imagen_perfil_default.png';

  // Función para manejar la búsqueda simultánea de eventos y usuarios
  const handleSearch = async (text) => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();

      // Ejecutar ambas búsquedas simultáneamente
      const [users, events] = await Promise.all([
        searchUsers(token, text),  // Buscar usuarios
        searchEvents(token, text), // Buscar eventos
      ]);

      // Combinar ambos resultados en un solo array con identificación de tipo
      const combinedResults = [
        ...users.map(user => ({ ...user, type: 'user' })),
        ...events.map(event => ({ ...event, type: 'event' })),
      ];

      setSearchResults(combinedResults); // Actualizar resultados
    } catch (error) {
      console.error('Error al realizar la búsqueda:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchDebounced = (text) => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      handleSearch(text);
    }, 500); // Debounce para evitar múltiples llamadas
  };

  // Maneja el clic fuera del dropdown para cerrarlo
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
      setScrollPosition(dropdownRef.current.scrollTop); // Guardar la posición de scroll antes de cerrar
      setDropdownOpen(false);
    }
  };

  // Abrir dropdown cuando el input es enfocado o clickeado y hay texto
  const handleFocusInput = () => {
    if (searchText) {
      setDropdownOpen(true);
    }
  };

  // Agregar y remover el evento de clic fuera del componente
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Restaurar el scroll cuando se vuelve a abrir el dropdown
  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current) {
      dropdownRef.current.scrollTop = scrollPosition;
    }
  }, [isDropdownOpen, scrollPosition]);

  return (
    <div className="relative w-64">
      <input
        ref={inputRef} // Asignar la referencia al input
        type="text"
        className="w-full p-2 rounded-lg text-black bg-white border border-gray-300 focus:outline-none focus:border-blue-500"
        placeholder="Buscar Eventos o Usuarios"
        value={searchText}
        onFocus={handleFocusInput} // Abrir el dropdown al enfocar si hay texto
        onChange={(e) => {
          setSearchText(e.target.value);
          setDropdownOpen(!!e.target.value); // Mostrar el dropdown solo si hay texto
          handleSearchDebounced(e.target.value);
        }}
      />
      <FaSearch className="absolute right-2 top-3 text-gray-500" />

      {/* Mostrar resultados en dropdown */}
      {isDropdownOpen && (
        <ul
          ref={dropdownRef} // Asignar la referencia al dropdown
          className="absolute top-full left-0 w-full bg-white border rounded-lg mt-1 shadow-lg z-[99999] max-h-60 overflow-y-auto"
        >
          {loading ? (
            <li className="p-2 text-gray-500">Cargando...</li>
          ) : searchResults.length === 0 ? (
            <li className="p-2 text-gray-500">Sin resultados</li>
          ) : (
            searchResults.map((result, index) => {
              // Log para inspeccionar los valores de result.link_photo y result.profile_link
              //console.log(`Result ${index} (Tipo: ${result.type}) - link_photo:`, result.link_photo, `profile_link:`, result.profile_link);

              // Extraer el primer elemento si link_photo es un arreglo
              const linkPhoto = Array.isArray(result.link_photo) ? result.link_photo[0] : result.link_photo;

              // Determinar la imagen a mostrar (perfil o evento)
              const imageSrc = result.type === 'user' 
                ? (typeof result.profile_link === 'string' && result.profile_link && !result.profile_link.endsWith('null') 
                    ? `${config.images_route}/${result.profile_link}` 
                    : defaultuserimage) 
                : (typeof linkPhoto === 'string' && linkPhoto && !linkPhoto.endsWith('null') 
                    ? `${config.images_route}/${linkPhoto}` 
                    : defaulteventimage);

              return (
                <li key={index} className="cursor-pointer">
                  <Link
                    to={result.type === 'user' ? `/profile/${result.id_user}` : `/event/${result.id_event}`}
                    className="p-2 text-black hover:bg-gray-100 flex items-center no-underline"
                  >
                    {result.type === 'user' ? (
                      <FaUser className="mr-2 text-blue-500" /> // Ícono de usuario
                    ) : (
                      <FaCalendarAlt className="mr-2 text-green-500" /> // Ícono de evento
                    )}

                    {/* Imagen del usuario/evento */}
                    <img
                      src={imageSrc}
                      alt={result.name || result.title}
                      className="w-8 h-8 rounded-full mr-2" // Hacer la imagen circular
                    />

                    <span>{result.name || result.title}</span> {/* Mostrar nombre de usuario o título del evento */}
                  </Link>
                </li>
              );
            })
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
