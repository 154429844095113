import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarIcon, LocationMarkerIcon, UserIcon } from '@heroicons/react/solid';
import { IoIosPeople } from "react-icons/io"; // Import the icon
import { BiSolidDiscount } from "react-icons/bi";

const EventCard = ({ id_event, link_photo, title, organizer, start_date, end_date, location, capacity, attendeesCount, discount }) => {
  const defaultImage = '../../images/evento_default_foto.png'; // Ruta de la imagen predeterminada
  
  // Verificar si link_photo contiene múltiples URLs separadas por comas y usar solo la primera
  const linkPhoto = link_photo && link_photo.includes(',')
    ? link_photo.split(',')[0].trim() // Tomar la primera URL
    : link_photo;

  const imageUrl = linkPhoto && !linkPhoto.endsWith('null') ? linkPhoto : defaultImage;

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-64 mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-x1 relative">
      {/* Imagen oprimible */}
      <Link to={`/event/${id_event}`} className="block h-48">
        <img 
          src={imageUrl} 
          alt={title} 
          className="w-full h-full object-cover" 
        />
      </Link>

      {/* Parte inferior: Información */}
      <div className="p-4 space-y-3">
        {/* Título oprimible */}
        <Link to={`/event/${id_event}`} className="text-xl font-bold mb-2 block text-custom-blue hover:underline">
          {title}
        </Link>
        {/* Usuario oprimible */}
        <Link to={`/profile/${organizer.id}`} className="flex items-center space-x-2 text-gray-600 block hover:text-custom-blue hover:underline">
          <UserIcon className="h-5 w-5 text-gray-700" /> {/* Icono de usuario de Heroicons */}
          <span>{organizer.name}</span>
        </Link>
        

        <div className="flex items-start text-gray-600 mb-2">
            <CalendarIcon className="h-5 w-5 mr-2 mt-1" />
            <div>
                <p className="font-semibold">{start_date} - </p>
                <p className="font-semibold">{end_date}</p>
            </div>
        </div>

        {/* Ubicación */}
        <div className="flex items-start text-gray-600 mb-2">
          <LocationMarkerIcon className="h-5 w-5 mr-2 mt-1" />
          <div className="w-48">
            <p>{location ? location : 'Por definir'}</p>
          </div>
        </div>

        {/* capacidad e inscritos */}
        <div className="flex items-center text-gray-600">
          <IoIosPeople className="h-5 w-5 mr-2" />
          <span>
            {attendeesCount || 0}
            {capacity != null ? `/${capacity}` : ''}
          </span>
        </div>

        {discount != null && (
          <div className="flex items-center text-gray-600 mt-2">
            <BiSolidDiscount className="h-5 w-5 mr-2" />
            <span>Descuento Disponible</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
