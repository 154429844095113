import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { formatFullDateTime, parseDate } from '../../services/format';
import { getEventDetails, deleteEvent, createPost, getEventPosts, updatePost, deletePost, getReviews, 
    getAcceptedAttendees, getFollowersAbleInviteEvent, getEventQuestions, answerQuestion } from '../../services/api';
import Modal from '../../components/Modal/Modal';
import config from '../../configroutes';
import AttendeesModal from '../../components/ModalAsistence/AttendeesModal'; 
import { UsersIcon, UserGroupIcon, CalendarIcon, LocationMarkerIcon, TicketIcon, GlobeAltIcon } from '@heroicons/react/outline';
import FollowersModalEventInvite from '../../components/ModalAsistence/FollowersModalEventInvite';
import { useLocation } from 'react-router-dom';
import { ReportEventReview, ReportEventQuestion } from '../../services/api'; 
import ReportModal from '../../components/Report/ReportModal'; 
import AlertReportModal from '../../components/Report/AlertReportModal';
import DiscountModal from '../../components/DiscountModal/DiscountModal';
import { BiSolidDiscount } from "react-icons/bi";
import { MdVisibility, MdDescription } from "react-icons/md";

const EventDetails = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [posts, setPosts] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isAttendeesModalOpen, setAttendeesModalOpen] = useState(false);
    const [editingPost, setEditingPost] = useState(null);
    const [errorMessageEvent, setErrorMessageEvent] = useState('');
    const [errorMessagePost, setErrorMessagePost] = useState('');
    const [isFollowersModalOpen, setFollowersModalOpen] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [selectedFollowers, setSelectedFollowers] = useState([]);
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [reportType, setReportType] = useState({ type: null, id: null });
    const [questions, setQuestions] = useState([]);
    const [responseText, setResponseText] = useState({});
    const [reportErrorMessage, setReportErrorMessage] = useState("");
    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);


    const typeOptions = {
        public: 'Público',
        private: 'Privado',
        vip: "VIP"
    };

    // Ruta de la imagen por defecto
    const defaultImage = '/images/evento_default_foto.png';

    const fetchEvent = async () => {
        try {
            const token = await getAccessTokenSilently();
            const updatedEvent = await getEventDetails(token, eventId);
            setEvent(updatedEvent);
        } catch (error) {
            console.error('Error al obtener eventos:', error);
        }
    };

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const token = await getAccessTokenSilently();
                const postsData = await getEventPosts(token, eventId);
                setPosts(postsData);
            } catch (error) {
                console.error('Error al obtener posts:', error);
            }
        };

        const fetchReviews = async () => {
            try {
                const token = await getAccessTokenSilently();
                const reviewsData = await getReviews(token, eventId);
                setReviews(reviewsData);
            } catch (error) {
                console.error('Error al obtener reseñas:', error);
            }
        };

        const fetchAttendees = async () => {
            try {
                const token = await getAccessTokenSilently();
                const attendeesData = await getAcceptedAttendees(token, eventId);
                setAttendees(attendeesData);
                setAttendeesCount(attendeesData.length);
            } catch (error) {
                console.error('Error al obtener asistentes:', error);
            }
        };

        const fetchFollowers = async () => {
            try {
                const token = await getAccessTokenSilently();
                const fetchedFollowers = await getFollowersAbleInviteEvent(token, eventId);
                setFollowers(fetchedFollowers);
            } catch (error) {
                console.error('Error fetching followers:', error);
            }
        };

        fetchEvent();
        fetchPosts();
        fetchReviews();
        fetchAttendees();
        fetchFollowers();
        fetchQuestions();

    }, [eventId, getAccessTokenSilently]);

    const handleEditEvent = () => {
        navigate(`/event/${eventId}/edit`);
    };

    const fetchQuestions = async () => {
        try {
            const token = await getAccessTokenSilently();
            const eventQuestions = await getEventQuestions(token, eventId);
            setQuestions(eventQuestions);
        } catch (error) {
            console.error('Error al obtener las preguntas:', error);
        }
    }

    const handleDeleteEvent = async () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este evento?')) {
            setErrorMessageEvent('');
            try {
                const token = await getAccessTokenSilently();
                await deleteEvent(token, eventId);
                navigate('/my_events');
            } catch (error) {
                console.error('Error al eliminar el evento:', error);
                if (attendeesCount >= 1) {
                    setErrorMessageEvent('No es posible eliminar eventos con asistentes.'); 
                }
                else {
                    setErrorMessageEvent('Error al eliminar el evento. Inténtelo de nuevo'); 
                }
            }
        }
    };
    const renderDiscount = () => {
        if (!event.discount_type || !event.discount_detail) return null;

        const { discount_type, discount_detail, price } = event;

        switch (discount_type) {
            case 'tarjeta bancaria': {
                const [bank, percentage] = discount_detail.split(';');
                const discountPrice = price ? price - (price * (parseInt(percentage, 10) / 100)) : null;
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            {bank} - {percentage}%  
                            {discountPrice !== null && ` (Precio: $${discountPrice.toFixed(0)})`}
                        </p>
                    </div>
                );
            }
            case 'porcentaje': {
                const percentage = parseInt(discount_detail, 10);
                const discountPrice = price ? price - (price * (percentage / 100)) : null;
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            {percentage}% descuento 
                            {discountPrice !== null && ` (Precio: $${discountPrice.toFixed(0)})`}
                        </p>
                    </div>
                );
            }
            case 'combo': {
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            Promoción {discount_detail} 
                        </p>
                    </div>
                );
            }
            case 'fijo': {
                const fixedDiscount = parseInt(discount_detail, 10);
                const discountPrice = price ? price - fixedDiscount : null;
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            Fijo: ${fixedDiscount} 
                            {discountPrice !== null && ` (Precio: $${discountPrice.toFixed(0)})`}
                        </p>
                    </div>
                );
            }
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchFollowers = async () => {
            try {
                const token = await getAccessTokenSilently();
                const fetchedFollowers = await getFollowersAbleInviteEvent(token, eventId);
                setFollowers(fetchedFollowers);
            } catch (error) {
                console.error('Error fetching followers:', error);
            }
        };
        setSelectedFollowers([]);
        fetchFollowers();
    }, [isFollowersModalOpen]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const handlePostSubmit = async ({ title, description }) => {
        if (!title || !description) {
            setErrorMessagePost('El título y la descripción no pueden estar en blanco.');
            closeModal();
            return;
        }
        setErrorMessagePost('');

        try {
            const token = await getAccessTokenSilently();
            if (editingPost) {
                await updatePost(token, editingPost.id_post, {
                    title: title,
                    content: description
                });
            } else {
                await createPost(token, {
                    id_event: eventId,
                    title: title,
                    content: description
                });
            }
            closeModal();
            const postsData = await getEventPosts(token, eventId); 
            setPosts(postsData);
        } catch (error) {
            console.error('Error al crear o actualizar el post:', error);
        }
    };

    const handleEditPost = (post) => {
        setEditingPost(post);
        setModalOpen(true);
    };

    const handleDeletePost = async (postId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este aviso?')) {
            try {
                const token = await getAccessTokenSilently();
                await deletePost(token, postId);
                const postsData = await getEventPosts(token, eventId);
                setPosts(postsData);
            } catch (error) {
                console.error('Error al eliminar el post:', error);
            }
        }
    };

    const handleFollowerSelect = (followerId) => {
        setSelectedFollowers(prevSelectedFollowers => {
            if (prevSelectedFollowers.includes(followerId)) {
                return prevSelectedFollowers.filter(id => id !== followerId);
            } else {
                return [...prevSelectedFollowers, followerId];
            }
        });
    };

    // Función para manejar el cambio en el cuadro de texto de respuesta
    const handleResponseChange = (id, text) => {
        setResponseText(prev => ({ ...prev, [id]: text }));
    };

    const handleReplySubmit = async (questionId) => {
        try {
            const token = await getAccessTokenSilently();
            const answerText = responseText[questionId] || "";
            await answerQuestion(token, questionId, answerText);
            setResponseText(prev => ({ ...prev, [questionId]: "" }));
            await fetchQuestions();
        } catch (error) {
            console.error("Error al enviar la respuesta:", error);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setEditingPost(null);
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'active':
                return 'Activo';
            case 'postponed':
                return 'Pospuesto';
            case 'cancelled':
                return 'Cancelado';
            case 'soldout':
                return 'Agotado';
            default:
                return status;
        }
    };

    const formatUrl = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return 'http://' + url;
        }
        return url;
    };

    const formatDateWithOffset = (dateString, offsetHours) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + offsetHours);
        return date.toLocaleString();
    };

    // Función para abrir Google Maps con la ubicación del evento
    const openMap = (location) => {
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
        window.open(url, '_blank');
    };

    const handleReportReview = (reviewId) => {
        setIsReportModalOpen(true);  
        setReportType({ type: 'reseña', id: reviewId });
    };

    const handleReportQuestion = (questionId) => {
        setReportType({ type: 'pregunta', id: questionId });
        setIsReportModalOpen(true);  
    };

    const handleReportSubmit = async (reason) => {
        try {
            const token = await getAccessTokenSilently();
            let report;
            if (reportType.type === 'reseña') {
                report = await ReportEventReview(token, reportType.id, reason);
            } else if (reportType.type === 'pregunta') {
                report = await ReportEventQuestion(token, reportType.id, reason);
            }

            if (report.alreadyReported) {
                setReportErrorMessage(report.message);
            } else {
                //console.log("Reporte enviado exitosamente");
            }

            setIsReportModalOpen(false);
            setReportType({ type: null, id: null });
    
        } catch (error) {
            console.error("Error al enviar el reporte:", error);
        }
    };

    if (!event) {
        return (
            <div className="py-10 flex items-center justify-center">
                <h1 className="text-3xl font-semibold">Cargando...</h1>
            </div>
        );
    }
    const originalLinkPhoto = event.link_photo;
    const isFutureEvent = parseDate(event.start_date) > new Date();

    // Definir y procesar las fotos
    const photos = event.link_photo && event.link_photo.includes(',')
        ? event.link_photo.split(',').map(photo => photo.trim())
        : [event.link_photo]; // Convertir una imagen única en un array

    const validatedPhotos = photos && photos.length > 0
        ? photos.map(photo => {
            if (!photo || photo === 'null' || photo === `${config.images_route}/null`) {
                return defaultImage;
            }
            return photo.startsWith('http')
                ? photo  
                : `${config.images_route}/${photo.trim()}`;
        })
        : [defaultImage]; 

    const totalImages = validatedPhotos.length;

    // Función para avanzar a la siguiente imagen
    const nextImage = () => {
        setCurrentIndex((currentIndex + 1) % totalImages);  // Avanza a la siguiente, vuelve a la primera si está en la última
    };

    // Función para retroceder a la imagen anterior
    const prevImage = () => {
        setCurrentIndex((currentIndex - 1 + totalImages) % totalImages);  // Retrocede, vuelve a la última si está en la primera
    };

    // Función para seleccionar una imagen directamente
    const selectImage = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto px-4 py-1">
                <div className="bg-white shadow-md rounded-lg p-6 flex-grow mt-4">
                    <div className="relative w-full h-64 bg-white rounded-lg shadow-lg">
                        {/* Imagen actual */}
                        <img
                            src={validatedPhotos[currentIndex]}
                            alt={`Imagen ${currentIndex + 1}`}
                            className="w-full h-full object-cover rounded-lg"
                        />

                        {/* Botón para ir a la imagen anterior */}
                        <button
                            onClick={prevImage}
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full"
                        >
                            &lt;
                        </button>

                        {/* Botón para ir a la siguiente imagen */}
                        <button
                            onClick={nextImage}
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full"
                        >
                            &gt;
                        </button>

                        {/* Indicadores de las imágenes */}
                        <div className="absolute bottom-4 right-4 flex space-x-1">
                            {validatedPhotos.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => selectImage(index)}
                                    className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-400'}`}
                                />
                            ))}
                        </div>
                    </div>
                    <p className="text-black-500 font-semibold text-xl mt-4"> {formatFullDateTime(event.start_date)} hrs - {formatFullDateTime(event.end_date)} hrs</p>
                    <h1 className="text-3xl font-bold mb-2">{event.title}</h1>
                    <div className="flex flex-wrap gap-2 mb-2">
                        {event.categories.map((category) => (
                            <button key={category.name} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-1 px-2 rounded-lg inline-flex items-center">
                                {category.name}
                            </button>
                        ))}
                    </div>

                    {/* Sección de Descripción del evento */}
                    <div className="flex items-center mt-4 space-x-2">
                        <MdDescription className="h-5 w-5 text-gray-600" />
                        <p className="text-black-500 font-semibold text-md">{event.description}</p>
                    </div>


                    <div className="flex justify-end space-x-4">
                        {isFutureEvent && (
                            <button
                            onClick={() => handleEditEvent(event.id)}
                            className="bg-custom-primary hover:bg-[#2C4570] text-white font-semibold py-2 px-4 rounded inline-block"
                            >
                            Editar
                            </button>
                        )}
                        {isFutureEvent && (
                            <button
                            onClick={() => setIsDiscountModalOpen(true)}
                            className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded inline-block"
                            >
                            Descuento
                            </button>
                        )}
                        {isFutureEvent && (
                            <button
                            onClick={handleDeleteEvent}
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded inline-block"
                            >
                            Eliminar
                            </button>
                        )}
                        <button
                            onClick={handleGoBack}
                            className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded inline-block"
                        >
                            Volver
                        </button>
                        </div>

                    </div>
    
                {errorMessageEvent && (
                    <div className="mt-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded ml-5">
                        {errorMessageEvent}
                    </div>
                )}
    
                <div className="flex flex-wrap space-x-4 mt-4">
                    <div className="flex flex-wrap w-full gap-4">
                        <div className="bg-white shadow-md rounded-lg p-6 flex-grow flex-1 min-w-[300px]">
                            <h1 className="text-2xl font-semibold mb-6">Detalles</h1>
    
                            {/* Tipo de evento */}
                            <div className="flex items-center mb-4">
                                <div className="flex-shrink-0">
                                    {event.event_type === 'private' ? (
                                        <span className="mr-2 text-2xl text-yellow-500">★</span>
                                    ) : (
                                        <UsersIcon className="h-6 w-6 text-custom-blue" />
                                    )}
                                </div>
                                <p className="ml-3 text-lg font-medium text-gray-700">{typeOptions[event.event_type]}</p>
                            </div>
    
                            {/* Visibilidad de asistentes */}
                            <div className="flex items-center mb-4">
                                <div className="flex-shrink-0">
                                    <MdVisibility className="h-6 w-6 text-custom-blue" />
                                </div>
                                <p className="ml-3 text-lg font-medium text-gray-700">
                                    {event.visibility_attendees ? 'Asistentes son visibles' : 'Asistentes no son visibles'}
                                </p>
                            </div>
    
                            {/* Ubicación del evento (modificado para abrir Google Maps) */}
                            <div className="flex items-center space-x-2 mb-4">
                                <LocationMarkerIcon className="h-6 w-6 text-custom-wine-red" />
                                <span className="text-md font-medium text-gray-700 hover:underline hover:text-custom-blue cursor-pointer" onClick={() => openMap(event.location)}>
                                    {event.location}
                                </span>
                            </div>
    
                            {/* Precio del evento */}
                            <div className="flex items-center mb-4">
                                <div className="flex-shrink-0">
                                    <TicketIcon className="h-6 w-6 text-custom-money-green" />
                                </div>
                                <p className="ml-3 text-lg font-medium text-gray-700">
                                    {event.price !== null ? `$${event.price}` : "Gratis"}
                                </p>
                            </div>
                            
                            {/* Descuento (solo si discount_type no es null) */}
                                {renderDiscount()}
                            {/* Asistentes confirmados */}
                            <div className="flex items-center mb-4">
                                <div className="flex-shrink-0">
                                    <UserGroupIcon className="h-6 w-6 text-custom-blue" />
                                </div>
                                <p className="ml-3 text-lg font-medium text-gray-700 cursor-pointer" onClick={() => setAttendeesModalOpen(true)}>
                                    {event.capacity !== null ? `${attendeesCount} de ${event.capacity} asistentes` : `${attendeesCount} asistentes`}
                                </p>
                            </div>
    
                            {/* Enlace del evento */}
                            {event.url && (
                                <div className="flex items-center mb-4">
                                    <GlobeAltIcon className="h-6 w-6 text-gray-600" />
                                    <a href={formatUrl(event.url)} target="_blank" rel="noopener noreferrer" className="ml-3 text-lg font-medium text-blue-600 hover:underline">
                                        {event.url}
                                    </a>
                                </div>
                            )}
    
                            {(event.event_type === 'private') && (
                            <div className="mb-4">
                                <button
                                    type="button"
                                    className="mt-2 bg-custom-primary text-white p-2 rounded-md"
                                    onClick={() => setFollowersModalOpen(true)}
                                >
                                    ¿Desea invitar a seguidores?
                                </button>
                            </div>
                        )}
                        </div>
    
                        <div className="bg-white shadow-md rounded-lg p-6 flex-grow flex-1 min-w-[300px]">
                            <div className="flex items-center space-x-2 mb-6">
                                <h1 className="text-2xl font-semibold">Avisos</h1>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    className="hover:bg-custom-blue hover:text-white font-semibold p-0.5 rounded-full flex items-center justify-center transition-colors duration-300"
                                    style={{ width: '24px', height: '24px', border: '1px solid currentColor' }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3 w-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                    </svg>
                                </button>
                                <Modal
                                    isOpen={isModalOpen}
                                    onClose={closeModal}
                                    onSubmit={handlePostSubmit}
                                    initialValues={editingPost || { title: '', description: '' }}
                                />
                            </div>
    
                            {errorMessagePost && (
                                <div className="mb-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded">
                                    {errorMessagePost}
                                </div>
                            )}
    
                            <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
                                {posts.length > 0 ? (
                                    posts.map((post) => (
                                        <div key={post.id_post} className="mb-4 p-4 border rounded">
                                            <h3 className="text-xl font-semibold">{post.title}</h3>
                                            <p className="text-gray-600">{post.content}</p>
                                            {post.link_photo && (
                                                <img src={post.link_photo} alt={post.title} className="w-full h-48 object-cover rounded mt-2" />
                                            )}
                                            <p className="text-gray-500 text-sm mt-2 mb-3">
                                                {formatDateWithOffset(post.created_at, 4)}
                                            </p>
                                            <div className="flex space-x-2">
                                                <button
                                                    onClick={() => handleEditPost(post)}
                                                    className="bg-[#3A5CC8] hover:bg-[#2C4570] text-white font-semibold py-2 px-4 rounded inline-block"
                                                >
                                                    Editar
                                                </button>
                                                <button
                                                    onClick={() => handleDeletePost(post.id_post)}
                                                    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                                                >
                                                    Eliminar
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No hay avisos disponibles.</p>
                                )}
                            </div>
                        </div>
    
                        <div className="bg-white shadow-md rounded-lg p-6 flex-grow flex-1 min-w-[300px]">
                            <h1 className="text-2xl font-semibold mb-6">Reseñas</h1>
                            <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
                                {reviews.length > 0 ? (
                                    reviews.map((review) => (
                                        <div key={review.id_review} className="mb-4 p-4 border rounded bg-gray-50 relative">
                                            {/* Botón de reportar */}
                                            <button
                                                onClick={() => handleReportReview(review.id_review)} // Llama a la función de reportar con el ID de la reseña
                                                className="absolute top-2 right-2 bg-red-500 text-white text-sm rounded-md px-2 py-1 hover:bg-red-600"
                                            >
                                                Reportar
                                            </button>
                                            {/* Mostrar las estrellas basadas en el rate */}
                                            <div className="flex items-center mb-2">
                                                <p className="font-bold text-lg">{review.user}</p>
                                                <div className="flex ml-2">
                                                    {Array.from({ length: review.rate }).map((_, i) => (
                                                        <span key={i} className="text-yellow-400">★</span>
                                                    ))}
                                                    {Array.from({ length: 5 - review.rate }).map((_, i) => (
                                                        <span key={i} className="text-gray-300">★</span>
                                                    ))}
                                                </div>
                                            </div>
                                            <p className="text-gray-600">{review.content}</p>
                                            <p className="text-gray-500 text-sm mt-2 mb-3">
                                                {formatDateWithOffset(review.created_at, -4)}
                                            </p>
                                        </div>
                                    ))
                                ) : (
                                    <p>No hay reseñas disponibles.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         
            <div className="container mx-auto px-4 py-1">
                <div className="bg-white shadow-md rounded-lg p-6 flex-grow mt-4">
                    <h1 className="text-2xl font-semibold mb-6">Preguntas</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-auto" style={{ maxHeight: '300px' }}>
                    {questions.length > 0 ? (
                    questions
                        .filter(question => question.is_active)
                        .map((question) => (
                            <div key={question.id_question} className="relative mb-4 p-4 border rounded bg-gray-50">
                                    <button
                                        onClick={() => handleReportQuestion(question.id_question)}
                                        className="absolute top-2 right-2 bg-red-500 text-white text-sm rounded-md px-2 py-1 hover:bg-red-600"
                                    >
                                        Reportar
                                    </button>

                                <h3 className="text-xl font-semibold">
                                    <Link to={`/profile/${question.id_user}`} className="hover:underline">
                                        {question.question}
                                    </Link>
                                </h3>
                                <p className="text-gray-600">{question.content}</p>

                                <span className="text-xs text-gray-400">
                                    {`${new Date(question.created_at).toLocaleDateString('es-ES', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}`}
                                </span>

                                {/* Mostrar la respuesta o un campo para responder */}
                                {question.reply ? (
                                    <p className="mt-2 text-gray-700 break-words">{question.reply}</p> // Clase break-words para ajustarse al contenido
                                ) : (
                                    <div className="mt-2">
                                        <textarea
                                            className="w-full border border-gray-300 rounded-md p-2 mb-2 focus:outline-none focus:border-blue-500"
                                            rows="1"
                                            maxLength="500"
                                            placeholder="Escribe tu respuesta aquí..."
                                            value={responseText[question.id_question] || ""}
                                            onChange={(e) => handleResponseChange(question.id_question, e.target.value)}
                                        ></textarea>
                                        <div className="flex justify-between items-center text-sm text-gray-600 mb-2">
                                            <span>{500 - (responseText[question.id_question]?.length || 0)} caracteres restantes</span>
                                        </div>

                                        <button
                                            onClick={() => handleReplySubmit(question.id_question)}
                                            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
                                        >
                                            Enviar respuesta
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No hay preguntas disponibles.</p>
                    )}
                </div>
            </div>
            {/* Mostrar mensaje de error en caso de que haya */}
            {errorMessageEvent && (
                <div className="mt-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded ml-5">
                    {errorMessageEvent}
                </div>
            )}
        </div>


    
            <AttendeesModal isOpen={isAttendeesModalOpen} onClose={() => setAttendeesModalOpen(false)} attendees={attendees} />
            <FollowersModalEventInvite
                isOpen={isFollowersModalOpen}
                onClose={() => setFollowersModalOpen(false)}
                followers={followers}
                selectedFollowers={selectedFollowers}
                handleFollowerSelect={handleFollowerSelect}
                eventId={eventId}
            />
            <ReportModal 
                isOpen={isReportModalOpen} 
                onClose={() => setIsReportModalOpen(false)} 
                onSubmit={handleReportSubmit} 
            />
            <AlertReportModal
                isOpen={!!reportErrorMessage}
                title="Error"
                message={reportErrorMessage}
                onClose={() => setReportErrorMessage("")}
            />

            <DiscountModal
                isOpen={isDiscountModalOpen}
                onClose={() => setIsDiscountModalOpen(false)}
                eventId={eventId}
                initialDiscountType={event.discount_type}
                initialDiscountDetail={event.discount_detail}
                linkPhoto={originalLinkPhoto}
                fetchEvent={fetchEvent}
            />

        </div>
    );
    
};



export default EventDetails;