import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData, checkOrganizerStatus } from '../../services/api';
import { LogoutIcon } from '@heroicons/react/outline';

const Login = () => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const navigate = useNavigate();
  const [isUserDeactivated, setIsUserDeactivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Captura el mensaje de error desde la URL si está presente
    const params = new URLSearchParams(window.location.search);
    const errorDescription = params.get('error_description');

    if (errorDescription) {
      // Decodifica y verifica si el error es de correo no verificado
      const decodedError = decodeURIComponent(errorDescription);

      if (decodedError.includes('Debe verificar su correo electrónico antes de continuar')) {
        // Redirige al usuario al componente ResendVerification
        navigate('/resend-verification');
      } else {
        alert(decodedError);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          // Obtiene los datos del usuario
          const userData = await fetchUserData(token);

          if (!userData.phone) {
            // Si el usuario no ha completado el formulario, redirige al formulario inicial
            navigate(`/form_inicial/${userData.id_user}`);
            return;
          }

          // Verifica si el usuario es organizador
          const isOrganizer = await checkOrganizerStatus(token);
          if (!isOrganizer) {
            setErrorMessage('Página solo para Organizadores. Cerrando sesión...');
            setTimeout(() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }, 2000);
            return;
          }

          // Si todo está bien, navega al dashboard
          navigate('/dashboard');
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.error('Usuario desactivado');
            setIsUserDeactivated(true);
          } else {
            console.error('Error fetching data: ', error);
          }
        }
      };
      fetchData();
    }
  }, [isAuthenticated, getAccessTokenSilently, navigate, logout]);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  if (isLoading) {
    return <div></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="tabla">
        <div className="flex min-h-screen w-full">
          <div
            className="w-1/2 flex justify-center items-center"
            style={{ backgroundImage: 'url("../images/inicio1.webp")', backgroundSize: 'cover' }}
          >
            <div className="text-white mx-12"></div>
          </div>
          <div className="w-1/2 flex justify-center items-center">
            <div className="bg-white bg-opacity-75 rounded px-8 py-6 mx-12 text-center">
              <div className="text-black mx-12">
                <h1 className="text-5xl font-bold mb-6">Evexx Web</h1>
                <p className="text-2xl mb-4">¡Organiza tus eventos y potencia tu negocio!</p>
                <p className="text-2xl mb-4">
                  Plataforma de gestión, planificación y control de eventos.
                </p>

                {/* Botón Para Registrarse */}
                <button
                  className="bg-custom-blue hover:bg-custom-lightblue text-white font-bold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline"
                  style={{
                    marginTop: '15px',
                    fontSize: '1.2rem',
                    marginLeft: '10px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }}
                  onClick={() =>
                    loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })
                  }
                >
                  Registrarse como Organizador
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Mostrar mensaje de error si el usuario no es organizador
  if (errorMessage) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-red-600 mb-6">{errorMessage}</h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Modal para usuario desactivado */}
      {isUserDeactivated && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Fondo bloqueador */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Contenido del modal */}
          <div className="relative bg-white p-6 rounded shadow-md text-center z-60">
            <h2 className="text-2xl font-bold mb-4">Usuario Desactivado</h2>
            <p className="mb-4">
              Tu usuario ha sido desactivado. Contacta al administrador para más información.
            </p>
            <div className="flex justify-center mt-4">
              {/* Botón de cerrar sesión con estilo rojo */}
              <button
                onClick={logoutWithRedirect}
                className="flex items-center bg-red-500 text-white hover:bg-red-700 font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out"
              >
                Cerrar Sesión
                <LogoutIcon className="h-6 w-6 ml-2" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
