import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getEventAnalytics, getOrganizerAnalytics, getUserInfo, getEvents } from '../../services/api';
import OrganizerMetrics from './OrganizerMetrics';
import EventMetrics from './EventMetrics';
import { formatDate, formatFullDateTime } from '../../services/format';

const MetricsView = () => {
    const { getAccessTokenSilently, user, isLoading: authLoading } = useAuth0();
    const [metrics, setMetrics] = useState({});
    const [eventMetrics, setEventMetrics] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showingOrg, setShowingOrg] = useState(true);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [organizationInfo, setOrganizationInfo] = useState({
        name: '',
        photo: ''
    });
    const [events, setEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(true);

    useEffect(() => {
        if (authLoading || !user) {
            return;
        }

        const fetchOrganizationData = async () => {
            try {
                const token = await getAccessTokenSilently();
                const userInfo = await getUserInfo(token, user.sub);
                setOrganizationInfo({
                    name: userInfo.name,
                    photo: userInfo.profileLink
                });
            } catch (error) {
                console.error("Error fetching organization info:", error);
                setError("Error fetching organization info");
            }
        };

        const fetchMetrics = async () => {
            setLoading(true);
            setError(null);
            try {
                const token = await getAccessTokenSilently();
                const fetchedMetrics = await getOrganizerAnalytics(token, user.sub);
                setMetrics(fetchedMetrics);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching metrics:", error);
                setError("Error fetching metrics");
            } finally {
                setLoading(false);
            }
        };

        const fetchEvents = async () => {
            setEventsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const updatedEvents = await getEvents(token, user.sub);
                setEvents(updatedEvents);
            } catch (error) {
                console.error("Error fetching events:", error);
            } finally {
                setEventsLoading(false);
            }
        };

        fetchOrganizationData();
        fetchMetrics();
        fetchEvents();
    }, [getAccessTokenSilently, user, authLoading]);

    const handleEventDetails = async (id) => {
        setShowingOrg(false);
        setSelectedEventId(id);
        setLoading(true);
        setError(null);
        try {
            const token = await getAccessTokenSilently();
            const fetchedMetrics = await getEventAnalytics(token, id);
            setEventMetrics(fetchedMetrics);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching event metrics:", error);
            setError("Error fetching metrics");
        } finally {
            setLoading(false);
        }
    };

    const handleOrganizationDetails = () => {
        setShowingOrg(true);
        setSelectedEventId(null);
    };

    const renderEvents = () => {
        if (eventsLoading) {
            return <p>Cargando Eventos...</p>;
        }

        if (events.length === 0) {
            return <p>No tienes Eventos.</p>;
        }

        const defaultImage = '../../images/evento_default_foto.png'; // Ruta de la imagen predeterminada

        return (
            <div className="flex flex-col space-y-4 max-h-screen overflow-y-auto">
                {events.map((event) => {
                    // Verificar si link_photo contiene múltiples URLs separadas por comas y usar solo la primera
                    const linkPhoto = event.link_photo && event.link_photo.includes(',')
                        ? event.link_photo.split(',')[0].trim() // Tomar la primera URL
                        : event.link_photo;

                    const imageUrl = linkPhoto && !linkPhoto.endsWith('null') ? linkPhoto : defaultImage;

                    return (
                        <div
                            key={event.id_event}
                            className={`cursor-pointer flex items-center space-x-4 p-4 rounded-lg shadow-md ${selectedEventId === event.id_event ? 'bg-blue-200' : 'bg-white'}`}
                            onClick={() => handleEventDetails(event.id_event)}
                        >
                            <img
                                src={imageUrl}
                                className="w-12 h-12 rounded-full object-cover"
                                alt="Evento"
                            />
                            <div>
                                <h2 className="text-xl font-semibold">{event.title}</h2>
                                <p className="text-gray-600">{formatFullDateTime(event.start_date)}</p>
                                <p className="text-gray-600">{formatFullDateTime(event.end_date)}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="flex min-h-screen">
            <div className="w-full lg:w-1/4 bg-gray-200 p-4">
                {/* Título para el organizador */}
                <h3 className="text-lg font-semibold mb-2">Organizador</h3>

                {/* Tarjeta para el organizador */}
                <div
                    className="bg-white p-4 rounded-lg shadow-md cursor-pointer flex items-center space-x-4"
                    onClick={handleOrganizationDetails}
                >
                    <img
                        src={organizationInfo.photo || '../../images/default_organizer.png'}
                        alt="Organizador"
                        className="w-12 h-12 rounded-full"
                    />
                    <h2 className="text-lg font-semibold">{organizationInfo.name}</h2>
                </div>

                {/* Sección de eventos */}
                <h3 className="text-lg font-semibold mb-2 mt-4">Eventos</h3>
                {renderEvents()}
            </div>
            
            <div className="w-full lg:w-3/4 bg-white p-4">
                <h1 className="text-2xl font-semibold mb-4">Métricas</h1>
                {loading ? (
                    <p className="text-lg font-semibold">Cargando...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    showingOrg ? (
                        <OrganizerMetrics metrics={metrics} />
                    ) : (
                        <EventMetrics eventMetrics={eventMetrics} />
                    )
                )}
            </div>
        </div>
    );
};

export default MetricsView;
