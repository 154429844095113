import React, { useState, useEffect } from "react";
import {
  getAllEvents,
  getFeed,
  reloadFeed,
  getFilteredEvents,
  getRecommendedUsers,
} from "../../services/api";
import EventCard from "../../components/EventCard/EventCard";
import RecommendedUserCard from "../../components/RecommendedUserCard/RecommendedUserCard";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FaCalendarAlt,
  FaUsers,
  FaTools,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { SlUserFollowing } from "react-icons/sl";
import CustomFilterPopup from "./CustomFilterPopup";
import { formatDateTime } from "../../services/format";

const Feed = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [showCustomFilters, setShowCustomFilters] = useState(false);
  const [recommendedUsers, setRecommendedUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  // Estados para la paginación (solo para "Todos")
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchAllEvents = async (pageNumber = 1) => {
    setLoading(true);
    setMessage("");
    setSelectedFilter("all");
    try {
      const token = await getAccessTokenSilently();
      const result = await getAllEvents(token, pageNumber);
      const { events: allEvents, total_pages } = result;
      if (allEvents.length === 0) {
        setMessage(
          <span className="text-black font-bold">
            No hay eventos disponibles.
          </span>
        );
      }
      setEvents(allEvents);
      setTotalPages(total_pages);
      setPage(pageNumber); // Actualizar el estado de la página actual
    } catch (error) {
      console.error("Error al obtener eventos:", error);
      setMessage(
        <span className="text-black font-bold">
          No se pudieron obtener los eventos.
        </span>
      );
    }
    setLoading(false);
  };

  const fetchRecommendedEvents = async () => {
    setLoading(true);
    setMessage("");
    setSelectedFilter("recommended");
    try {
      const token = await getAccessTokenSilently();
      await reloadFeed(token);
      const recommendedEvents = await getFeed(token, false, true);
      if (recommendedEvents.length === 0) {
        setMessage(
          <span className="text-black font-bold">
            No hay eventos recomendados disponibles.
          </span>
        );
      }
      setEvents(recommendedEvents);
    } catch (error) {
      console.error("Error al obtener eventos recomendados:", error);
      setMessage(
        <span className="text-black font-bold">
          No se pudieron obtener los eventos recomendados
        </span>
      );
    }
    setLoading(false);
  };

  const fetchFollowedEvents = async () => {
    setLoading(true);
    setMessage("");
    setSelectedFilter("followed");
    try {
      const token = await getAccessTokenSilently();
      const followedEvents = await getFeed(token, true, false);
      if (followedEvents.length === 0) {
        setMessage(
          <span className="text-black font-bold">
            No hay eventos de los usuarios seguidos.
          </span>
        );
      }
      setEvents(followedEvents);
    } catch (error) {
      console.error("Error al obtener eventos de usuarios seguidos:", error);
      setMessage(
        <span className="text-black font-bold">
          No se pudieron obtener los eventos de los usuarios seguidos.
        </span>
      );
    }
    setLoading(false);
  };

  const fetchRecommendedUsers = async () => {
    setUsersLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const users = await getRecommendedUsers(token);
      setRecommendedUsers(users);
    } catch (error) {
      console.error("Error al obtener usuarios recomendados:", error);
    }
    setUsersLoading(false);
  };

  const applyCustomFilters = async (filters) => {
    setLoading(true);
    setMessage("");
    setSelectedFilter("custom");
    try {
      const token = await getAccessTokenSilently();
      const filteredEvents = await getFilteredEvents({
        token,
        description: filters.description,
        page: 1,
        onlyFollowed: filters.onlyFollowed,
        location: filters.location,
        startDate: filters.startDate,
        endDate: filters.endDate,
        categories: filters.categories,
      });
      if (filteredEvents.length === 0) {
        setMessage(
          <span className="text-black font-bold">
            No se encontraron eventos con los filtros seleccionados.
          </span>
        );
      }
      setEvents(filteredEvents);
    } catch (error) {
      console.error("Error al aplicar filtros personalizados:", error);
      setMessage(
        <span className="text-black font-bold">
          No se pudieron aplicar los filtros personalizados.
        </span>
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllEvents();
    fetchRecommendedUsers();
  }, []);

  // Handlers para la paginación (solo para "Todos")
  const handleNextPage = () => {
    if (page < totalPages) {
      fetchAllEvents(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      fetchAllEvents(page - 1);
    }
  };

  return (
    <div className="overflow-auto p-4 bg-gray-50 min-h-screen">
      <div className="grid grid-cols-12 gap-6">
        {/* Barra lateral izquierda */}
        <div className="col-span-12 md:col-span-2 bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Filtros</h2>
          <ul>
            <li>
              <button
                onClick={() => {
                  setPage(1); // Reiniciar a la primera página
                  fetchAllEvents(1);
                }}
                className={`flex items-center mb-4 font-medium transition-colors duration-200 ${
                  selectedFilter === "all"
                    ? "text-custom-blue font-bold"
                    : "text-gray-700 hover:text-custom-blue"
                }`}
              >
                <FaCalendarAlt className="mr-3 text-custom-blue" />
                <span>Todos</span>
              </button>
            </li>
            <li>
              <button
                onClick={fetchFollowedEvents}
                className={`flex items-center mb-4 font-medium transition-colors duration-200 ${
                  selectedFilter === "followed"
                    ? "text-custom-blue font-bold"
                    : "text-gray-700 hover:text-custom-blue"
                }`}
              >
                <SlUserFollowing className="mr-3 text-custom-blue" />
                <span>Seguidos</span>
              </button>
            </li>
            <li>
              <button
                onClick={fetchRecommendedEvents}
                className={`flex items-center mb-4 font-medium transition-colors duration-200 ${
                  selectedFilter === "recommended"
                    ? "text-custom-blue font-bold"
                    : "text-gray-700 hover:text-custom-blue"
                }`}
              >
                <FaUsers className="mr-3 text-custom-blue" />
                <span>Para Tí</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setShowCustomFilters(!showCustomFilters);
                  setSelectedFilter("custom");
                }}
                className={`flex items-center mb-4 font-medium transition-colors duration-200 ${
                  selectedFilter === "custom"
                    ? "text-custom-blue font-bold"
                    : "text-gray-700 hover:text-custom-blue"
                }`}
              >
                <FaTools className="mr-3 text-custom-blue" />
                <span>Personalizados</span>
                <span className="ml-auto">
                  {showCustomFilters ? (
                    <FaChevronUp className="text-custom-blue" />
                  ) : (
                    <FaChevronDown className="text-custom-blue" />
                  )}
                </span>
              </button>
            </li>
          </ul>
          {showCustomFilters && (
            <div className="-mx-2 mt-2">
              <div className="border border-gray-200 rounded-lg p-2 shadow-sm">
                <CustomFilterPopup onApplyFilters={applyCustomFilters} />
              </div>
            </div>
          )}
        </div>

        {/* Sección central */}
        <div className="col-span-12 md:col-span-8">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Eventos</h2>
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <p className="text-gray-500">Cargando eventos...</p>
            </div>
          ) : message ? (
            <div className="flex justify-center items-center h-64">
              <p className="text-black font-bold">{message}</p>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-3">
                {events.map((event) => (
                  <EventCard
                    key={event.id_event}
                    id_event={event.id_event}
                    link_photo={event.link_photo}
                    title={event.title}
                    organizer={{
                      id: event.id_organizer,
                      name: event.organizer,
                    }}
                    start_date={formatDateTime(event.start_date)}
                    end_date={formatDateTime(event.end_date)}
                    location={event.location}
                    capacity={event.capacity}
                    attendeesCount={event.participants}
                    discount={event.discount_type}
                  />
                ))}
              </div>
              {/* Controles de paginación (solo para "Todos") */}
              {selectedFilter === "all" && totalPages > 1 && (
                <div className="flex justify-center items-center mt-4">
                  <button
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="mx-2 px-4 py-2 bg-custom-blue text-white rounded disabled:opacity-50"
                  >
                    Anterior
                  </button>
                  <span className="mx-2 text-gray-700">
                    Página {page} de {totalPages}
                  </span>
                  <button
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                    className="mx-2 px-4 py-2 bg-custom-blue text-white rounded disabled:opacity-50"
                  >
                    Siguiente
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* Barra lateral derecha */}
        <div className="col-span-12 md:col-span-2 bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">
            Perfiles que quizás conoces
          </h2>
          <div className="flex flex-col space-y-4">
            {usersLoading ? (
              <p className="text-gray-500">Cargando usuarios...</p>
            ) : recommendedUsers.length > 0 ? (
              recommendedUsers.map((user) => (
                <RecommendedUserCard
                  key={user.id_user}
                  idUser={user.id_user}
                  profileLink={user.profile_link}
                  name={user.name}
                />
              ))
            ) : (
              <p className="text-gray-500">
                No hay usuarios recomendados disponibles.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;
