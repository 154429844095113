import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const RecommendedUserCard = ({ idUser, profileLink, name }) => {
  const navigate = useNavigate();
  const defaultImage = "/images/imagen_perfil_default.png";

  const imageUrl = profileLink
    ? `${process.env.REACT_APP_IMAGE_CLOUD_URL}/${profileLink}`
    : defaultImage;

  const handleCardClick = () => {
    navigate(`/profile/${idUser}`);
  };

  return (
    <>
      <div
        className="flex items-center p-2 bg-gray-100 rounded-full shadow-sm cursor-pointer hover:bg-gray-200 transition"
        onClick={handleCardClick}
        data-tooltip-id={`tooltip-${idUser}`} // Tooltip asociado por ID único
        data-tooltip-content={name} // Contenido del tooltip
        data-tooltip-delay-show="100" // Retraso para mostrar el tooltip
      >
        <img src={imageUrl} alt={name} className="w-8 h-8 rounded-full mr-4" />
        <span className="font-medium text-gray-800 truncate">{name}</span>
      </div>
      {/* Componente Tooltip */}
      <Tooltip id={`tooltip-${idUser}`} place="top" effect="solid" />
    </>
  );
};

export default RecommendedUserCard;
