import React, { useState, useEffect } from "react";
import { fetchCategories } from "../../services/api";
import { useAuth0 } from "@auth0/auth0-react";

const CustomFilterPopup = ({ onApplyFilters }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [categories, setCategories] = useState([]); // Lista de todas las categorías
  const [selectedCategories, setSelectedCategories] = useState([]); // Categorías seleccionadas
  const [onlyFollowed, setOnlyFollowed] = useState(false);

  // Obtener categorías desde el endpoint
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await fetchCategories(token); // Obtener las categorías con el token
        setCategories(data);
      } catch (error) {
        console.error("Error al obtener categorías:", error);
      }
    };
    loadCategories();
  }, [getAccessTokenSilently]);

  const toggleCategorySelection = (id) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((catId) => catId !== id));
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  const handleApplyFilters = () => {
    onApplyFilters({
      description,
      location,
      startDate,
      endDate,
      categories: selectedCategories.join(";"), // Convertir a cadena separada por ;
      onlyFollowed,
    });
  };

  return (
    <div className="w-full mt-0">
      <label className="block mb-2">
        Título o Descripción:
        <input
          type="text"
          className="border p-2 w-full rounded"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </label>

      <label className="block mb-2">
        Ubicación:
        <input
          type="text"
          className="border p-2 w-full rounded"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </label>

      <label className="block mb-2">
        Fecha de Inicio:
        <input
          type="datetime-local"
          className="border p-2 w-full rounded"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </label>

      <label className="block mb-2">
        Fecha de Finalización:
        <input
          type="datetime-local"
          className="border p-2 w-full rounded"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </label>

      <div className="block mb-2">
        <p className="font-semibold">Categorías:</p>
        <div className="max-h-40 overflow-y-auto border p-2 rounded">
          {categories.map((category) => (
            <label key={category.id_category} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={selectedCategories.includes(category.id_category)}
                onChange={() => toggleCategorySelection(category.id_category)}
                className="mr-2"
              />
              {category.name}
            </label>
          ))}
        </div>
      </div>

      <label className="block mb-4">
        <input
          type="checkbox"
          checked={onlyFollowed}
          onChange={() => setOnlyFollowed(!onlyFollowed)}
          className="mr-2"
        />
        Solo Eventos de Seguidos
      </label>

      <div className="flex justify-center space-x-4">
        <button
          className="bg-custom-primary text-white p-2 rounded font-semibold hover:bg-blue-700"
          onClick={handleApplyFilters}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
};

export default CustomFilterPopup;
