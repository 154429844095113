import React from 'react';
import { useParams } from 'react-router-dom';

const Mobile = () => {
  const { eventId } = useParams();

  return (
    <div className="flex flex-col min-h-screen w-full items-center justify-center bg-gray-100">
      <div className="bg-white bg-opacity-75 rounded px-4 py-6 text-center w-full max-w-md">
      <img
          src="/images/logo_texto.png"
          alt="Evexx Logo"
          className="w-3/4 max-w-xs mx-auto mb-6"
        />

        <div className="flex flex-col gap-4">
          <a
            href={`evexx://mobile/event/${eventId}`}
            className="bg-custom-primary hover:bg-blue-500 text-white font-bold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline"
            style={{ fontSize: '1.2rem' }}
          >
            Abrir App
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.sociably"
            className="flex items-center justify-center text-white font-bold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline w-full"
            style={{ fontSize: '1.2rem' }}
          >
            <img
              src="/images/GooglePlay_Badge.png"
              alt="Download Android"
              className="w-3/4 max-w-xs h-auto object-contain mx-auto"
            />
          </a>
          <a
            href="https://apps.apple.com/"
            className="flex items-center justify-center text-white font-bold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline w-full"
            style={{ fontSize: '1.2rem' }}
          >
            <img
              src="/images/AppStore_Badge.svg"
              alt="Download iOS"
              className="w-3/4 max-w-xs h-auto object-contain mx-auto"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
