import React, { useState } from 'react';

const ReportModal = ({ isOpen, onClose, onSubmit }) => {
    const [reportReason, setReportReason] = useState('');
    const [remainingChars, setRemainingChars] = useState(500);

    if (!isOpen) return null;

    const handleSubmit = () => {
        onSubmit(reportReason);
        setReportReason(''); // Limpiar el campo después de enviar
        setRemainingChars(500); // Restablecer el contador
        onClose(); // Cerrar el modal después de enviar
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setReportReason(value);
        setRemainingChars(500 - value.length);
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-lg font-bold mb-4">Reportar</h2>
                <textarea
                    className="w-full h-24 p-2 border rounded-md mb-2"
                    placeholder="Describe la razón del reporte"
                    value={reportReason}
                    maxLength={500}
                    onChange={handleChange}
                />
                <div className="text-sm text-gray-600 mb-4">
                    {remainingChars > 0
                        ? `Caracteres Restantes: ${remainingChars}`
                        : 'Máximo de 500 caracteres alcanzado.'}
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                    >
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportModal;
