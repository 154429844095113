import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import config from '../../configroutes';
import { useNavigate, useParams } from 'react-router-dom';

// Función para validar RUT chileno
const validarRut = (rut) => {
    if (typeof rut !== "string") {
        return false;
    }

    // Eliminar puntos y guiones
    rut = rut.replace(/[.-]/g, '');

    if (rut.length < 2) {
        return false;
    }

    const cuerpo = rut.slice(0, -1);
    const dv = rut.slice(-1).toUpperCase();

    if (!/^\d+$/.test(cuerpo)) {
        return false;
    }

    let suma = 0;
    let multiplo = 2;

    for (let i = cuerpo.length - 1; i >= 0; i--) {
        suma += parseInt(cuerpo.charAt(i), 10) * multiplo;
        multiplo = multiplo === 7 ? 2 : multiplo + 1;
    }

    const resto = suma % 11;
    const dvEsperado = resto === 1 ? 'K' : resto === 0 ? '0' : (11 - resto).toString();

    return dv === dvEsperado;
};

// Función para formatear el RUT sin puntos y con guión
const formatRutForBackend = (rut) => {
    // Eliminar puntos y guiones
    rut = rut.replace(/[.-]/g, '');

    // Convertir DV a mayúscula
    rut = rut.toUpperCase();

    // Separar cuerpo y DV
    let cuerpo = rut.slice(0, -1);
    let dv = rut.slice(-1);

    return cuerpo + '-' + dv;
};

// Función para formatear el RUT mientras el usuario escribe (con puntos y guión)
const formatRut = (rut) => {
    // Eliminar puntos y guiones
    rut = rut.replace(/[.-]/g, '');

    // Convertir DV a mayúscula
    rut = rut.toUpperCase();

    // Separar cuerpo y DV
    let cuerpo = rut.slice(0, -1);
    let dv = rut.slice(-1);

    // Formatear cuerpo con puntos cada tres dígitos
    let formattedCuerpo = '';
    while (cuerpo.length > 3) {
        formattedCuerpo = '.' + cuerpo.slice(-3) + formattedCuerpo;
        cuerpo = cuerpo.slice(0, -3);
    }
    formattedCuerpo = cuerpo + formattedCuerpo;

    return formattedCuerpo + '-' + dv;
};

const FirstEditProfile = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { id_user } = useParams(); 
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({
        name: '',
        phone: '', 
        website: '',
        description: '',
        rut: '',
        how_did_you_know_us: '',
    });
    const [imageFile, setImageFile] = useState(null);
    const [rutError, setRutError] = useState(''); // Estado para el error del RUT
    const [errorMessage, setErrorMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            if (/^[0-9+()\s]*$/.test(value) && value.length <= 15 || value === "") {
                setProfileData(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            }
        } else if (name === "rut") {
            // Eliminar caracteres inválidos
            let cleanedValue = value.replace(/[^0-9kK]/g, '');

            // Formatear el RUT mientras escribe
            let formattedRut = formatRut(cleanedValue);

            setProfileData(prevData => ({
                ...prevData,
                [name]: formattedRut
            }));

            // Validar el RUT en tiempo real
            if (cleanedValue.length >= 2 && !validarRut(formattedRut)) {
                setRutError("RUT inválido.");
            } else {
                setRutError("");
            }
        } else {
            setProfileData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validar que todos los campos estén llenos
        const allFieldsFilled = Object.values(profileData).every(x => x.trim() !== '');
        if (!allFieldsFilled) {
            setErrorMessage("Por favor, rellena todos los campos antes de enviar.");
            setShowPopup(true);
            return;
        }
    
        // Validar el RUT
        if (!validarRut(profileData.rut)) {
            setErrorMessage("El RUT ingresado no es válido. Por favor, corrige el RUT.");
            setShowPopup(true);
            return;
        }
    
        try {
            const token = await getAccessTokenSilently();
            const configaxios = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };
    
            const requestBody = {
                ...profileData,
                profile_privacy: false,
                organizer: true,
            };
    
            // Formatear el RUT para el backend (sin puntos y con guión)
            requestBody.rut = formatRutForBackend(profileData.rut);
    
            let imageURL = "d2bae96a60d5427186b2ab808a19d493imagen_perfil_default.png";
    
            if (imageFile) {
                const imageData = new FormData();
                imageData.append('image', imageFile);
                
                const imageUploadConfig = {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                };
                const uploadResponse = await axios.post(`${config.route}/api/image/upload`, imageData, imageUploadConfig);
                imageURL = uploadResponse.data.image_url; 
                requestBody.profile_link = imageURL;
            }
    
            requestBody.profile_link = imageURL;
    
            const response = await axios.put(`${config.route}/api/user/${id_user}`, requestBody, configaxios);
            navigate('/dashboard');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                const errorDetail = error.response.data.detail;
    
                if (errorDetail.includes('duplicate key value violates unique constraint "users_phone_key"')) {
                    setErrorMessage("El número de teléfono ya está en uso. Por favor, utiliza otro número.");
                    setShowPopup(true);
                } else if (errorDetail.includes('Invalid website format')) {
                    setErrorMessage("El sitio web es inválido. Recuerda utilizar el formato correcto.");
                    setShowPopup(true);
                } else {
                    setErrorMessage(errorDetail || "Error actualizando el perfil. Por favor, inténtalo de nuevo más tarde.");
                    setShowPopup(true);
                }
            } else {
                console.error('Error actualizando el perfil:', error);
                setErrorMessage("Error actualizando el perfil. Por favor, inténtalo de nuevo más tarde.");
                setShowPopup(true);
            }
        }
    };
    

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">

                <div className="bg-custom-primary p-4 rounded-md mb-6 text-center">
                    <h2 className="text-xl text-white font-bold">Configuración de Perfil</h2>
                    <p className="text-white">Debe rellenar la siguiente información para continuar</p>
                </div>
                    
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Nombre de la Organización (<span className="text-red-500">*</span>)</label>
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            value={profileData.name} 
                            onChange={handleChange} 
                            placeholder="Nombre" 
                            className="form-input block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="rut" className="block text-gray-700 font-semibold mb-2">RUT (<span className="text-red-500">*</span>)</label>
                        <input 
                            type="text"
                            id="rut"
                            name="rut"
                            value={profileData.rut}
                            onChange={handleChange}
                            placeholder="12.345.678-K"
                            className="form-input block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                        {rutError && <p className="text-red-500 text-sm mt-1">{rutError}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phone" className="block text-gray-700 font-semibold mb-2">Número de Contacto (<span className="text-red-500">*</span>)</label>
                        <input 
                            type="text" 
                            id="phone" 
                            name="phone" 
                            value={profileData.phone} 
                            onChange={handleChange} 
                            placeholder="912345678" 
                            className="form-input block w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="website" className="block text-gray-700 font-semibold mb-2">Sitio web de la Organización (<span className="text-red-500">*</span>)</label> 
                        <input 
                            type="text" 
                            id="website" 
                            name="website" 
                            value={profileData.website} 
                            onChange={handleChange} 
                            placeholder="www.organizacion.cl"
                            className="form-input block w-full p-2 border rounded" 
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">Descripción de la Organización (<span className="text-red-500">*</span>)</label>
                        <textarea
                            id="description"
                            name="description"
                            value={profileData.description}
                            onChange={handleChange}
                            className="form-input block w-full p-2 border rounded"
                            rows="4"
                            placeholder="Descripción del rubro y tipo de eventos que gestiona y realiza la organización"
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="how_did_you_know_us" className="block text-gray-700 font-semibold mb-2">¿Cómo nos conociste? (<span className="text-red-500">*</span>)</label>
                        <select
                            id="how_did_you_know_us"
                            name="how_did_you_know_us"
                            value={profileData.how_did_you_know_us}
                            onChange={handleChange}
                            className="form-input block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            <option value="" disabled>Seleccione una opción</option>
                            <option value="Redes Sociales">Redes Sociales</option>
                            <option value="por otra persona">Por Recomendación</option>
                            <option value="Búsqueda en Internet">Búsqueda en Internet</option>
                            <option value="Publicidad">Publicidad</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Foto del Perfil:</label>
                        <input 
                            type="file"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            accept="image/png, image/jpeg"
                            onChange={e => setImageFile(e.target.files[0])}
                        />
                        <p className="text-gray-500 text-xs mt-1">Formatos aceptados: jpg, jpeg, png.</p>
                        <p className="text-gray-500 text-xs mt-1">Si no se proporciona una imagen válida, se usará una imagen por defecto.</p>
                    </div>
                    <button type="submit" className="w-full bg-custom-primary text-white p-2 rounded-md hover:bg-[#2C4570]">Continuar</button>
                </form>
            </div>

            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-md text-center">
                        <p className="text-red-500 mb-4">{errorMessage}</p>
                        <button
                            className="bg-custom-primary text-white px-4 py-2 rounded hover:bg-[#2C4570]"
                            onClick={() => setShowPopup(false)}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FirstEditProfile;
