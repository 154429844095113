import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resendVerificationEmail } from '../../services/api';

const ResendVerification = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const data = await resendVerificationEmail(email);
      setMessage(data.message || 'Correo de verificación reenviado exitosamente.');
      setError('');
      // Esperar 1 segundo y redirigir al usuario a la ruta "/"
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage =
        error.response?.data?.detail || 'Error al reenviar el correo de verificación.';
      setError(errorMessage);
      setMessage('');
      setEmail(''); // Limpiar el formulario
    }
  };

  return (
    <div className="flex min-h-screen justify-center items-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Reenviar Verificación de Correo</h2>
        {message && (
          <p className="mb-4 text-green-600 text-center">
            {message}
          </p>
        )}
        {error && (
          <p className="mb-4 text-red-600 text-center">
            {error}
          </p>
        )}
        <form onSubmit={handleResend}>
          <div className="mb-4">
            <label className="block text-gray-700">Correo Electrónico:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
          >
            Reenviar Email de Verificación
          </button>
        </form>
        <button
          onClick={() => navigate('/')}
          className="w-full mt-4 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
        >
          Volver
        </button>
      </div>
    </div>
  );
};

export default ResendVerification;
