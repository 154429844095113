import React from 'react';

export default function NotFoundpage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <h2 className="text-3xl font-semibold text-gray-700 mb-6">Página no encontrada</h2>
        <p className="text-lg text-gray-600 mb-8">
          Lo sentimos, la página que estás buscando no existe.
        </p>
        <a
          href="/dashboard"
          className="bg-custom-primary text-white font-bold py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
        >
          Volver al Inicio
        </a>
      </div>
    </div>
  );
}