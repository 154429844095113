import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getEvents, getAcceptedAttendees, getReviews } from '../../../services/api'; 
import { formatDayMonthYear, parseDate } from '../../../services/format'; 
import config from '../../../configroutes';
import { Link } from 'react-router-dom';

const Metrics = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalEvents, setTotalEvents] = useState(0);
  const [pastEvents, setPastEvents] = useState(0);
  const [futureEvents, setFutureEvents] = useState([]);
  const [attendeesCounts, setAttendeesCounts] = useState({});
  const [reviewAverages, setReviewAverages] = useState({}); 
  const [showPastEvents, setShowPastEvents] = useState(true);
  const [showFutureEvents, setShowFutureEvents] = useState(true);
  const [showCurrentEvents, setShowCurrentEvents] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const today = new Date();

  // Define la ruta de la imagen por defecto
  const defaultImage = '/images/evento_default.png';

  const fetchEvents = async () => {
    setLoading(true);
    try {
      if (!user) {
        console.error("User is undefined");
        setLoading(false);
        return;
      }
      const token = await getAccessTokenSilently();
      const updatedEvents = await getEvents(token, user.sub);
      
      setEvents(updatedEvents);
      setTotalEvents(updatedEvents.length);

      const past = updatedEvents.filter(event => parseDate(event.end_date) < today);
      const future = updatedEvents.filter(event => parseDate(event.start_date) >= today);
      const current = updatedEvents.filter(event => parseDate(event.start_date) <= today && parseDate(event.end_date) >= today);

      setPastEvents(past.length);
      setFutureEvents(future);
      setCurrentEvents(current);

      const reviewPromises = past.map(async (event) => {
        const ReviewsEvent = await getReviews(token, event.id_event);
        if (ReviewsEvent.length > 0) {
          const addRates = ReviewsEvent.reduce((total, review) => total + review.rate, 0);
          const averageRate = addRates / ReviewsEvent.length;
          return { id_event: event.id_event, averageRate };
        } else {
          return { id_event: event.id_event, averageRate: 0 };
        }
      });

      const attendeesPromises = future.map(async (event) => {
        const attendees = await getAcceptedAttendees(token, event.id_event);
        return { id_event: event.id_event, attendeesCount: attendees.length };
      });

      const [reviewsData, attendeesData] = await Promise.all([
        Promise.all(reviewPromises),
        Promise.all(attendeesPromises)
      ]);

      const reviewsMap = reviewsData.reduce((acc, { id_event, averageRate }) => {
        acc[id_event] = averageRate;
        return acc;
      }, {});

      const attendeesMap = attendeesData.reduce((acc, { id_event, attendeesCount }) => {
        acc[id_event] = attendeesCount;
        return acc;
      }, {});

      setReviewAverages(reviewsMap);
      setAttendeesCounts(attendeesMap);
      
    } catch (error) {
      //console.log(error, "Error al obtener eventos");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [user]);

  return (
    <div className="p-4 bg-white rounded-lg">
      {loading ? (
        <p>Cargando métricas...</p>
      ) : (
        <div className="space-y-4">
          {/* Total de eventos */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h2 className="text-lg font-semibold">Total de Eventos: {totalEvents}</h2>
          </div>

                    {/* Eventos Actuales */}
                    <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Eventos Actuales: {currentEvents.length}</h2>
              <button
                className="text-custom-primary hover:underline"
                onClick={() => setShowCurrentEvents(!showCurrentEvents)}
              >
                {showCurrentEvents ? 'Minimizar' : 'Expandir'}
              </button>
            </div>
            {showCurrentEvents && (
            <div className="mt-2 overflow-y-auto max-h-[400px]">
              {currentEvents.length > 0 ? (
                currentEvents.map(event => {
                  const linkPhoto = event.link_photo && event.link_photo.includes(',')
                    ? event.link_photo.split(',')[0].trim()
                    : event.link_photo;

                  const validatedPhoto = linkPhoto && linkPhoto !== 'null' && linkPhoto !== `${config.images_route}/null`
                    ? (linkPhoto.startsWith('http') ? linkPhoto : `${config.images_route}/${linkPhoto.trim()}`)
                    : defaultImage;

                  return (
                    <div key={event.id_event} className="p-2 mt-2 bg-white shadow-sm rounded-lg flex items-center">
                      <img 
                        src={validatedPhoto} 
                        alt={event.title} 
                        className="w-12 h-12 rounded-full mr-4 object-cover" 
                      />
                      <div>
                        <Link to={`/detail/${event.id_event}`} className="text-md font-bold text-custom-primary hover:underline">
                          {event.title}
                        </Link>
                        <p><strong>Fecha:</strong> {formatDayMonthYear(event.start_date)} - {formatDayMonthYear(event.end_date)}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No hay eventos actuales.</p>
              )}
            </div>
          )}
          </div>

          {/* Eventos Futuros */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Eventos Futuros: {futureEvents.length}</h2>
              <button
                className="text-custom-primary hover:underline"
                onClick={() => setShowFutureEvents(!showFutureEvents)}
              >
                {showFutureEvents ? 'Minimizar' : 'Expandir'}
              </button>
            </div>
            {showFutureEvents && (
              <div className="mt-2 overflow-y-auto max-h-[400px]">
                {futureEvents.length > 0 ? (
                  futureEvents.map(event => {
                    const linkPhoto = event.link_photo && event.link_photo.includes(',')
                      ? event.link_photo.split(',')[0].trim()
                      : event.link_photo;

                    const validatedPhoto = linkPhoto && linkPhoto !== 'null' && linkPhoto !== `${config.images_route}/null`
                      ? (linkPhoto.startsWith('http') ? linkPhoto : `${config.images_route}/${linkPhoto.trim()}`)
                      : defaultImage;

                    return (
                      <div key={event.id_event} className="p-2 mt-2 bg-white shadow-sm rounded-lg flex items-center">
                        <img 
                          src={validatedPhoto} 
                          alt={event.title} 
                          className="w-12 h-12 rounded-full mr-4 object-cover" 
                        />
                        <div>
                          <Link to={`/detail/${event.id_event}`} className="text-md font-bold text-custom-primary hover:underline">
                            {event.title}
                          </Link>
                          <p><strong>Fecha:</strong> {formatDayMonthYear(event.start_date)} - {formatDayMonthYear(event.end_date)}</p>
                          <p>
                            <strong>Inscritos:</strong> {attendeesCounts[event.id_event] || 0}
                            {event.capacity != null ? `/${event.capacity}` : ''}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No hay eventos futuros.</p>
                )}
              </div>
            )}
          </div>
          
                    {/* Eventos Pasados */}
                    <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Eventos Pasados: {pastEvents}</h2>
              <button
                className="text-custom-primary hover:underline"
                onClick={() => setShowPastEvents(!showPastEvents)}
              >
                {showPastEvents ? 'Minimizar' : 'Expandir'}
              </button>
            </div>
            {showPastEvents && (
              <div className="mt-2 overflow-y-auto max-h-[400px]">
                {pastEvents > 0 ? (
                  events
                    .filter(event => parseDate(event.end_date) < today)
                    .map(event => {
                      const linkPhoto = event.link_photo && event.link_photo.includes(',')
                        ? event.link_photo.split(',')[0].trim()
                        : event.link_photo;

                      const validatedPhoto = linkPhoto && linkPhoto !== 'null' && linkPhoto !== `${config.images_route}/null`
                        ? (linkPhoto.startsWith('http') ? linkPhoto : `${config.images_route}/${linkPhoto.trim()}`)
                        : defaultImage;

                      return (
                        <div key={event.id_event} className="p-2 mt-2 bg-white shadow-sm rounded-lg flex items-center">
                          <img 
                            src={validatedPhoto} 
                            alt={event.title} 
                            className="w-12 h-12 rounded-full mr-4 object-cover" 
                          />
                          <div>
                            <Link to={`/detail/${event.id_event}`} className="text-md font-bold text-custom-primary hover:underline">
                              {event.title}
                            </Link>
                            <p><strong>Fecha:</strong> {formatDayMonthYear(event.start_date)} - {formatDayMonthYear(event.end_date)}</p>
                            <p><strong>Calificación: </strong> 
                              {reviewAverages[event.id_event] !== undefined && reviewAverages[event.id_event] > 0 
                                ? reviewAverages[event.id_event].toFixed(2) 
                                : 'Sin reseñas'}
                            </p>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <p>No hay eventos pasados.</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Metrics;
