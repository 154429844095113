import React, { useState, useEffect } from 'react';
import { updateEventDiscount } from '../../services/api'; 
import { useAuth0 } from "@auth0/auth0-react";
import config from '../../configroutes';

const DiscountModal = ({ isOpen, onClose, eventId, initialDiscountType, initialDiscountDetail, fetchEvent, linkPhoto }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [discountType, setDiscountType] = useState(initialDiscountType || '');
  const [discountDetail, setDiscountDetail] = useState(initialDiscountDetail || '');
  const [bankName, setBankName] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const discountOptions = [
    'tarjeta bancaria',
    'porcentaje',
    'combo',
    'fijo',
  ];
  // Efecto para inicializar los campos si el tipo es "tarjeta bancaria"
  useEffect(() => {
    if (initialDiscountType === 'tarjeta bancaria' && initialDiscountDetail) {
      const [initialBankName, initialPercentage] = initialDiscountDetail.split(';');
      setBankName(initialBankName || '');
      setDiscountPercentage(initialPercentage || '');
    } else {
      setBankName('');
      setDiscountPercentage('');
    }
  }, [initialDiscountType, initialDiscountDetail]);

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
    setDiscountDetail(''); // Reiniciar el detalle cuando cambia el tipo
    setBankName('');
    setDiscountPercentage('');
    setErrorMessage('');
  };

  const handleDiscountDetailChange = (e) => {
    setDiscountDetail(e.target.value);
    setErrorMessage('');
  };

  // Función para procesar linkPhoto
// Function to process linkPhoto
const processLinkPhoto = (linkPhoto) => {
    const baseUrl = config.images_route;
    if (!linkPhoto || linkPhoto === 'null' || linkPhoto === '' || linkPhoto === `${baseUrl}/null`) {
      return null;
    } else {
      let photoArray = linkPhoto.split(',').map(photo => photo.trim());
      let processedPhotos = photoArray.map(photo => {
        if (photo.startsWith(baseUrl)) {
          // Remove baseUrl from the photo URL
          let processedPhoto = photo.substring(baseUrl.length);
          // Remove any leading '/' from the processedPhoto
          processedPhoto = processedPhoto.replace(/^\/+/, '');
          return processedPhoto;
        }
        return photo;
      });
      return processedPhotos;
    }
  };
  

  const handleSubmit = async () => {
    if (!discountType) {
      setErrorMessage('Por favor, selecciona un tipo de descuento.');
      return;
    }

    let discountDetailToSend = '';

    if (discountType === 'tarjeta bancaria') {
      // Validar campos para "tarjeta bancaria"
      if (!bankName || !discountPercentage) {
        setErrorMessage('Por favor, completa el nombre del banco y el porcentaje de descuento.');
        return;
      }
      const percentage = parseInt(discountPercentage, 10);
      if (isNaN(percentage) || percentage < 1 || percentage > 100) {
        setErrorMessage('El porcentaje de descuento debe ser un número entero entre 1 y 100.');
        return;
      }
      discountDetailToSend = `${bankName};${percentage}`;
    } else {
      // Validar campos para otros tipos de descuento
      if (!discountDetail) {
        setErrorMessage('Por favor, completa el detalle del descuento.');
        return;
      }
      discountDetailToSend = discountDetail;
    }

    try {
      const token = await getAccessTokenSilently();
      // Procesar linkPhoto antes de enviarlo
      let linkPhotoToSend = processLinkPhoto(linkPhoto);
      await updateEventDiscount(eventId, token, discountType, discountDetailToSend, linkPhotoToSend);
      await fetchEvent(); // Actualizar la información del evento
      onClose(); // Cerrar el modal después de actualizar
    } catch (error) {
      console.error('Error al actualizar el descuento:', error);
      setErrorMessage('Error al actualizar el descuento. Inténtalo de nuevo.');
    }
  };

  const handleRemoveDiscount = async () => {
    try {
      const token = await getAccessTokenSilently();
      // Procesar linkPhoto antes de enviarlo
      let linkPhotoToSend = processLinkPhoto(linkPhoto);
      await updateEventDiscount(eventId, token, null, null, linkPhotoToSend);
      await fetchEvent(); // Actualizar la información del evento
      onClose(); // Cerrar el modal después de eliminar
    } catch (error) {
      console.error('Error al eliminar el descuento:', error);
      setErrorMessage('Error al eliminar el descuento. Inténtalo de nuevo.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Administrar Descuento</h2>

        {errorMessage && (
          <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
            {errorMessage}
          </div>
        )}

        <div className="mb-4">
          <label className="block mb-1 font-medium">Tipo de Descuento</label>
          <select
            value={discountType}
            onChange={handleDiscountTypeChange}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">Selecciona un tipo</option>
            {discountOptions.map((option) => (
              <option key={option} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}
          </select>
        </div>

        {discountType === 'tarjeta bancaria' && (
          <>
            <div className="mb-4">
              <label className="block mb-1 font-medium">Nombre del Banco</label>
              <input
                type="text"
                placeholder="Banco de Chile"
                value={bankName}
                onChange={(e) => { setBankName(e.target.value); setErrorMessage(''); }}
                className="w-full border border-gray-300 rounded p-2"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-medium">Porcentaje de Descuento</label>
              <input
                type="number"
                placeholder="30"
                value={discountPercentage}
                onChange={(e) => { setDiscountPercentage(e.target.value); setErrorMessage(''); }}
                min="1"
                max="100"
                className="w-full border border-gray-300 rounded p-2"
              />
            </div>
          </>
        )}

        {discountType && discountType !== 'tarjeta bancaria' && (
          <div className="mb-4">
            <label className="block mb-1 font-medium">Detalle del Descuento</label>
            {discountType === 'porcentaje' && (
              <input
                type="number"
                placeholder="40"
                value={discountDetail}
                onChange={handleDiscountDetailChange}
                className="w-full border border-gray-300 rounded p-2"
              />
            )}
            {discountType === 'combo' && (
              <input
                type="text"
                placeholder="2X1"
                value={discountDetail}
                onChange={handleDiscountDetailChange}
                className="w-full border border-gray-300 rounded p-2"
              />
            )}
            {discountType === 'fijo' && (
              <input
                type="number"
                placeholder="3000"
                value={discountDetail}
                onChange={handleDiscountDetailChange}
                className="w-full border border-gray-300 rounded p-2"
              />
            )}
          </div>
        )}

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
          >
            Cancelar
          </button>
          {initialDiscountType && initialDiscountDetail && (
            <button
              onClick={handleRemoveDiscount}
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
            >
              Quitar Descuento
            </button>
          )}
          <button
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscountModal;
