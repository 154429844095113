// Profile.js
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { MailIcon, PhoneIcon, GlobeAltIcon, KeyIcon, UserIcon } from '@heroicons/react/solid';
import { useAuth0 } from "@auth0/auth0-react"; 
import { getUserInfo } from '../../services/api';
import { UserContext } from '../../components/NavBar/UserContex';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, LogoutIcon } from '@heroicons/react/outline';
const Profile = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { logout} = useAuth0();
    const { setUserName } = useContext(UserContext);
    const [userInfo, setUserInfo] = useState({
        id: '',
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        website: '',
        description: '',
        profileLink: '',
        followers: 0, 
        following: 0,
        profile_privacy: false
    });
    const [isProfileChecked, setIsProfileChecked] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const navigate = useNavigate();

    const fetchUserInfo = async () => {
        try {
            const token = await getAccessTokenSilently(); 
            const userInfo = await getUserInfo(token);
            setUserInfo(userInfo);
            setUserName(userInfo.name); // Actualiza el nombre de usuario en el contexto
            setIsProfileChecked(true);
        } catch (error) {
            //console.log(error, "Error al obtener la información del usuario");
        }
    }

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    }

    const handleImageError = () => {
        setIsImageLoaded(false);
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleProfile = () => {
        navigate(`/profile/${userInfo.id}`)
    }

    const formatUrl = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return 'http://' + url;
        }
        return url;
    }

    if (!isProfileChecked) {
        return (
            <div className="py-10 flex items-center justify-center">
                <h1 className="text-3xl font-semibold">Cargando...</h1>
            </div>
        );
    }

    const defaultProfileImage = '../../../images/imagen_perfil_default.png'; 

    const logoutWithRedirect = () =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          }
        });

    return (
        <div className="min-h-screen bg-gray-100 py-6">
            <div className="container mx-auto px-4 py-8">
            <div className="flex items-center mb-4">
                
            </div>
                <div className="bg-white shadow-md rounded-lg p-6 mx-auto max-w-4xl">
                    <button onClick={handleGoBack} className="flex items-center text-gray-500 hover:text-black">
                        <ArrowLeftIcon className="h-6 w-6" />
                        <span className="ml-2">Volver</span>
                    </button>
                    <div className="flex flex-col items-center">
                        {/* Imagen de perfil */}
                        <div className="relative">
                            {!isImageLoaded && (
                                <div className="w-40 h-40 rounded-full bg-gray-200 flex items-center justify-center">
                                    <UserIcon className="h-20 w-20 text-gray-500" />
                                </div>
                            )}
                            <img
                                src={userInfo.profileLink || defaultProfileImage}
                                className={`w-40 h-40 object-cover rounded-full border-4 border-white shadow-md ${!isImageLoaded ? 'hidden' : ''}`}
                                onLoad={handleImageLoad}
                                onError={handleImageError}
                                alt="Perfil"
                            />
                        </div>
                        {/* Nombre de usuario */}
                        <h1 className="text-4xl font-bold text-gray-800 mt-4">{userInfo.name}</h1>
                        {/* Descripción */}
                        {userInfo.description && (
                            <p className="text-gray-600 mt-2 text-center max-w-2xl">{userInfo.description}</p>
                        )}
                        {/* Seguidores y Siguiendo */}
                        <div className="flex justify-center mt-6 space-x-8">
                            <div className="text-center">
                                <span className="text-2xl font-bold text-gray-800">{userInfo.followers}</span>
                                <Link to={`/followers/${userInfo.id}`} className="block text-gray-600 hover:text-gray-800">Seguidores</Link>
                            </div>
                            <div className="text-center">
                                <span className="text-2xl font-bold text-gray-800">{userInfo.following}</span>
                                <Link to={`/following/${userInfo.id}`} className="block text-gray-600 hover:text-gray-800">Siguiendo</Link>
                            </div>
                        </div>
                        {/* Información de contacto */}
                        <div className="mt-10 w-full">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Información de Contacto</h2>
                            <div className="space-y-4">
                                <p className="flex items-center text-gray-600">
                                    <MailIcon className="h-5 w-5 mr-2 text-gray-500" /> {userInfo.email}
                                </p>
                                {userInfo.phone && (
                                    <p className="flex items-center text-gray-600">
                                        <PhoneIcon className="h-5 w-5 mr-2 text-gray-500" /> {userInfo.phone}
                                    </p>
                                )}
                                {userInfo.website && (
                                    <p className="flex items-center text-gray-600">
                                        <GlobeAltIcon className="h-5 w-5 mr-2 text-gray-500" />
                                        <a href={formatUrl(userInfo.website)} target="_blank" rel="noopener noreferrer" className="text-custom-primary hover:underline font-semibold">
                                            {userInfo.website}
                                        </a>
                                    </p>
                                )}
                                <p className="flex items-center text-gray-600">
                                    <KeyIcon className="h-5 w-5 mr-2 text-gray-500" /> {userInfo.profile_privacy ? 'Privado' : 'Público'}
                                </p>
                            </div>
                        </div>
                        {/* Botón de edición */}
                        <div className="flex justify-center mt-10">
                            <Link to="/edit-profile">
                                <button className="bg-custom-primary text-white hover:bg-blue-700 font-semibold py-2 px-6 rounded-full">Editar Perfil</button>
                            </Link>
                        </div>

                        <div className="flex justify-center mt-4">
                            <button onClick={handleProfile} className="hover:bg-blue-300 bg-blue-400 text-white  font-semibold py-2 px-6 rounded-full">
                                Visualizar mi perfil
                            </button>
                        </div>

                        {/* Botón para cerrar sesión */}
                        <div className="flex justify-center mt-4">
                            <button onClick={logoutWithRedirect} className="flex items-center bg-custom-blood-red text-white hover:bg-custom-wine-red-light font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out">
                                Cerrar Sesión
                                <LogoutIcon className="h-6 w-6 ml-2" />
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
