import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line
} from 'recharts';

const OrganizerMetrics = ({ metrics }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

    let isPlaceholder = false;
    if (!metrics || !metrics.organizer_rating_distribution) {
        isPlaceholder = true;
        // Métricas por defecto
        metrics = {
          organizer_events: 9,
          organizer_attendees: 100,
          organizer_ages_distribution: { '18-24': 40, '25-34': 30, '35-44': 20, '45-54': 10 },
          organizer_genders_distribution: { male: 50, female: 45, other: 5 },
          organizer_accepted_invitations: 20,
          organizer_rating_distribution: {
            rating_distribution: { '1': 5, '2': 10, '3': 20, '4': 30, '5': 35 },
            average_rating: 4.0
          },
          organizer_followers: [
            { date: '2023-09-01', followers: 10 },
            { date: '2023-09-02', followers: 12 },
            { date: '2023-09-03', followers: 15 },
            { date: '2023-09-04', followers: 17 },
            { date: '2023-09-05', followers: 20 },
          ]
        };
    }

    const formatData = (data, key) => {
        if (!data) return [];
        const genderMapping = {
            male: 'Hombre',
            female: 'Mujer',
            other: 'Otros'
        };
        return Object.entries(data).map(([label, value]) => ({
            [key]: genderMapping[label] || label,
            porcentaje: parseFloat(value.toFixed(1)),
        }));
    };

    const ageDistributionData = formatData(metrics.organizer_ages_distribution, 'ageRange');
    const genderDistributionData = formatData(metrics.organizer_genders_distribution, 'gender');
    const ratingDistributionData = formatData(metrics.organizer_rating_distribution.rating_distribution, 'rating');
    const averageRating = metrics.organizer_rating_distribution.average_rating;

    return (
        <div className="relative">
            {isPlaceholder && (
                <div className="absolute top-0 left-0 right-0 mt-20 flex justify-center z-10">
                    <div className="bg-white bg-opacity-90 p-6 rounded border border-gray-300 shadow-lg">
                        <h2 className="text-3xl font-bold text-gray-800">Aquí Aparecerán tus Métricas</h2>
                    </div>
                </div>
            )}
            <div style={{ opacity: isPlaceholder ? 0.15 : 1 }}>
                <h2 className="text-xl font-semibold mb-4">Métricas del Organizador</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="bg-gray-100 p-6 rounded-lg text-center shadow-lg">
                        <h3 className="text-lg font-semibold">Total de Eventos</h3>
                        <p className="text-4xl font-bold text-custom-primary">{metrics.organizer_events}</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg text-center shadow-lg">
                        <h3 className="text-lg font-semibold">Total de Asistentes</h3>
                        <p className="text-4xl font-bold text-custom-primary">{metrics.organizer_attendees}</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                    <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
                        <h3 className="text-lg font-semibold mb-4">Distribución por Edad</h3>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={ageDistributionData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ageRange" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="porcentaje" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
                        <h3 className="text-lg font-semibold mb-4">Distribución por Género</h3>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={genderDistributionData}
                                    dataKey="porcentaje"
                                    nameKey="gender"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                    label
                                >
                                    {genderDistributionData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                {averageRating !== null && (
                    <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-4">
                        <h3 className="text-lg font-semibold mb-4">Distribución de Calificaciones</h3>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={ratingDistributionData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="rating" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="porcentaje" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                        <p className="text-lg font-semibold">Calificación Promedio: {averageRating.toFixed(1)} <span role="img" aria-label="star">⭐</span></p>
                    </div>
                )}
                {metrics.organizer_accepted_invitations !== null && (
                    <div className="bg-gray-100 p-6 rounded-lg text-center shadow-lg mt-4">
                        <h3 className="text-lg font-semibold">Invitaciones Aceptadas</h3>
                        <p className="text-4xl font-bold text-custom-primary">{metrics.organizer_accepted_invitations?.toFixed(1)}%</p>
                    </div>
                )}
                {metrics.organizer_followers && (
                    <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-4">
                        <h3 className="text-lg font-semibold mb-4">Seguidores del Organizador (últimos 30 días)</h3>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={metrics.organizer_followers}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="followers" stroke="#8884d8" name="seguidores" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrganizerMetrics;
