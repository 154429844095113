import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getFollowerRequests, deleteFollowerRequest, acceptFollowerRequest} from '../../services/api';
import { useNavigate } from 'react-router-dom';
import config from '../../configroutes';

const InboxView = () => {
  const [followerRequests, setFollowerRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  // Función para cargar las solicitudes de seguidores
  const fetchFollowerRequests = async () => {
    setLoading(true);
    setError(null);
    //console.log("Cargando solicitudes...");
    try {
      const token = await getAccessTokenSilently(); // Obtener el token para autenticación
      const data = await getFollowerRequests(token);

      setFollowerRequests(data);
    } catch (err) {
      console.error('Error al cargar las solicitudes:', err.message);
      setError('Error al cargar las solicitudes.');
    } finally {
      setLoading(false);
    }
  };

  // Para ir al perfil del usuario que me quiere seguir
  const handleFollowerClick = (followerId) => {
    navigate(`/profile/${followerId}`);
  };

  // Rechazar una solicitud de seguidor
  const declineRequest = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await deleteFollowerRequest(token, id);
      fetchFollowerRequests();
    } catch (error) {
      console.error('Error al rechazar la solicitud:', error);
    }
  };
  
  // Aceptar una solicitud de seguidor
  const acceptRequest = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await acceptFollowerRequest(token, id);
      fetchFollowerRequests();
    } catch (error) {
      console.error('Error al aceptar la solicitud:', error);
    }
  };
  
  // Efecto para cargar las solicitudes
  useEffect(() => {
    fetchFollowerRequests();
  }, []);

  return (
    <div className="max-w-3xl w-full mx-auto p-5 bg-gray-50 border border-gray-300 rounded-lg mt-8">
      <div className="text-center text-gray-800">
        {/* Título de las solicitudes */}
        <h2 className="text-2xl font-bold mb-4">Solicitudes de Seguidores</h2>

        {loading ? (
          <p>Cargando solicitudes...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            {followerRequests.length === 0 ? (
              <p className="text-gray-500">No tienes solicitudes</p>
            ) : (
              <ul className="space-y-2">
                {followerRequests.map(follower => (
                  <li 
                    key={follower.id_follower} 
                    className="flex items-center py-1 px-2 border border-gray-300 rounded-lg bg-white shadow-sm"
                    onClick={() => handleFollowerClick(follower.id_follower)}>
                    <img 
                      src={follower.requester.profile_link ? `${config.images_route}/${follower.requester.profile_link}` : '/images/imagen_perfil_default.png'} 
                      alt={`Avatar de ${follower.name}`} 
                      className="w-10 h-10 rounded-full object-cover" 
                    />
                    <div className="flex-grow ml-4">
                      <p className="font-bold font-semibold text-gray-800">{follower.requester.name}</p>
                      <p className="text-xs text-gray-500">{follower.requester.description}</p>
                    </div>
                    <button
                      className="py-1 px-2 text-xs bg-custom-primary text-white rounded-md hover:bg-blue-600 mr-1"
                      onClick={(e) => {
                        e.stopPropagation();  // Evita que se dispare el evento de click del contenedor padre
                        acceptRequest(follower.id_request);
                      }} > Aceptar </button>

                    <button
                      className="py-1 px-2 text-xs bg-custom-blood-red text-white rounded-md hover:bg-custom-wine-red-light mr-1"
                      onClick={(e) => {
                        e.stopPropagation();  // Evita que se dispare el evento de click del contenedor padre
                        declineRequest(follower.id_request);
                      }} > Rechazar </button>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InboxView;
