
const convertUTCToLocal = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
    const formattedLocalDate = localDate.toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    });

    return formattedLocalDate;
};

const formatDateWithoutZ = (dateString) => {
    const date = new Date(dateString); // Crea una instancia de Date desde el string
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Meses van de 0-11, se ajusta sumando 1
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

const extractDateParts = (dateString) => {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    return [day, month, year, timePart];
};


const formatFullDateTime = (dateString) => {
    const [day, month, year, time] = extractDateParts(dateString);
    const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return `${day} de ${months[month - 1]}, ${year}, ${time.split(':')[0]}:${time.split(':')[1]}`;
};

const formatDayMonth = (dateString) => {
    const [day, month] = extractDateParts(dateString);
    const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return `${day} ${months[month - 1]}`;
};

const formatDayMonthYear = (dateString) => {
    const [day, month, year] = extractDateParts(dateString); // Usamos la función extractDateParts para separar la fecha
    return `${day}/${month < 10 ? '0' + month : month}/${year}`; // Formateamos el mes con dos dígitos si es necesario
};

const formatTime = (dateString) => {
    const [, , , time] = extractDateParts(dateString);
    return time.split(':')[0] + ':' + time.split(':')[1];
};

const formatDateTime = (dateString) => {
    // Convertir "DD/MM/YYYY, HH:mm:ss" a "YYYY-MM-DDTHH:mm:ss"
    const [datePart, timePart] = dateString.split(', ');
    const [day, monthNumber, year] = datePart.split('/');
    const isoFormat = `${year}-${monthNumber}-${day}T${timePart}`;
    
    const date = new Date(isoFormat);


    if (isNaN(date.getTime())) {
        return "Fecha inválida"; // Manejar errores de conversión
    }

    // Nombres de los meses en español
    const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];

    // Extraer día, mes y hora
    const dayNumber = date.getDate();
    const monthName = months[date.getMonth()]; // Índice del mes
    const hours = String(date.getHours()).padStart(2, '0'); // Asegurar formato HH
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Asegurar formato MM

    return `${dayNumber} ${monthName}, ${hours}:${minutes}`;
};


const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');

    // Convierte a formato compatible: YYYY-MM-DDTHH:mm:ss
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
};


const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long' };
    return new Date(date).toLocaleString('es-ES', options);
};

const formatTimeRange = (start, end) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };
    const startTime = new Date(start).toLocaleString('es-ES', options);
    const endTime = new Date(end).toLocaleString('es-ES', options);
    return `${startTime} - ${endTime}`;
};

const formatDateShort = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
};




export { formatDate, formatTimeRange, formatDateShort, formatDateTime, convertUTCToLocal, formatFullDateTime, formatDayMonth, formatTime, parseDate, formatDayMonthYear, formatDateWithoutZ }; // Exportar todas las funciones