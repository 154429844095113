import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { getEvents, fetchCategories } from '../../services/api';
import { formatDateTime, parseDate } from '../../services/format';
import { SearchIcon, CalendarIcon } from '@heroicons/react/solid';
import config from '../../configroutes';
import { IoIosPeople } from "react-icons/io";

const MyEvents = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    
    // Búsqueda
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchChecked, setIsSearchChecked] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateError, setDateError] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isCategoryChecked, setIsCategoryChecked] = useState(false);
    const [isStartDateChecked, setIsStartDateChecked] = useState(false);
    const [isEndDateChecked, setIsEndDateChecked] = useState(false);

    const navigate = useNavigate();

    //Ruta de la imagen por defecto
    const defaultImage = '/images/evento_default_foto.png';

    const fetchEvents = async () => {
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const updatedEvents = await getEvents(token, user.sub);
            setEvents(updatedEvents);
            setLoading(false);
        } catch (error) {
            //console.log(error, "Error al obtener eventos");
            setLoading(false);
        } 
    };

    // Fetch categories
    const loadCategories = async () => {
        try {
            const token = await getAccessTokenSilently();
            const fetchedCategories = await fetchCategories(token);
            setCategories(fetchedCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleEventDetails = (id) => {
        navigate(`/detail/${id}`, { state: { from: 'MyEvents' } });
    };

    useEffect(() => {
        fetchEvents();
        loadCategories();
    }, []);

    const handleSearchTermChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        // Activar automáticamente el filtro de búsqueda cuando el usuario escribe algo
        if (term) {
            setIsSearchChecked(true);
        }
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategories(prevSelectedCategories => {
            const newCategories = prevSelectedCategories.includes(categoryId)
                ? prevSelectedCategories.filter(id => id !== categoryId)
                : [...prevSelectedCategories, categoryId];
    
            // Activar automáticamente el filtro de categorías cuando se selecciona al menos una categoría
            if (newCategories.length > 0) {
                setIsCategoryChecked(true);
            }
    
            return newCategories;
        });
    };

    const filteredEvents = events.filter(event => {
        const eventStartDate = parseDate(event.start_date);
        const eventEndDate = parseDate(event.end_date);

        const startDateObj = startDate ? new Date(startDate) : null;
        let endDateObj = endDate ? new Date(endDate) : null;

        if (endDateObj) {
            endDateObj.setHours(23, 59, 59, 999);
        }

        const isWithinDateRange = (!startDateObj || (eventStartDate >= startDateObj && eventEndDate >= startDateObj)) &&
                                  (!endDateObj || (eventStartDate <= endDateObj && eventEndDate <= endDateObj));

        const isWithinSelectedCategories = !isCategoryChecked || 
                                  selectedCategories.length === 0 || 
                                  selectedCategories.every(catId => event.categories.some(eventCat => eventCat.id_category === catId));
        
        const matchesSearchTerm = !isSearchChecked || event.title.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesSearchTerm && isWithinDateRange && isWithinSelectedCategories;
    });

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
    
        // Activar automáticamente el checkbox si se selecciona una fecha
        if (newStartDate) {
            setIsStartDateChecked(true);
        }
    };
    
    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        if (new Date(newEndDate) < new Date(startDate)) {
            setDateError('La fecha de término no puede ser menor que la fecha de inicio.');
        } else {
            setDateError('');
            setEndDate(newEndDate);
    
            // Activar automáticamente el checkbox si se selecciona una fecha
            if (newEndDate) {
                setIsEndDateChecked(true);
            }
        }
    };

    if (loading) {
        return (
            <div className="py-10 flex items-center justify-center">
                <h1 className="text-3xl font-semibold">Cargando...</h1>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 px-6 py-8">
            <h1 className="text-4xl font-bold mb-8 text-custom-blue text-center">Mis Eventos</h1>
    
            {/* Filtros */}
            <div className="mb-8 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 w-full">
                    {/* Búsqueda */}
                    <div className="col-span-1">
                        <label className="font-semibold mb-2 flex items-center">
                            <input
                                type="checkbox"
                                checked={isSearchChecked}
                                onChange={() => setIsSearchChecked(!isSearchChecked)}
                                className="mr-2"
                            />
                            Buscar por Nombre:
                        </label>
                        <div className="relative flex items-center">
                            <input
                                type="text"
                                placeholder="Buscar por Nombre"
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                className="p-2 pl-10 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-custom-primary"
                            />
                            <SearchIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                        </div>
                    </div>
    
                    {/* Fechas */}
                    <div className="col-span-2 grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label className="font-semibold mb-2 flex items-center">
                                <input
                                    type="checkbox"
                                    checked={isStartDateChecked}
                                    onChange={() => {
                                        setIsStartDateChecked(!isStartDateChecked);
                                        if (isStartDateChecked) setStartDate(''); // Limpiar la fecha si se desactiva el checkbox
                                    }}
                                    className="mr-2"
                                />
                                Fecha de Inicio:
                            </label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-primary"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold mb-2 flex items-center">
                                <input
                                    type="checkbox"
                                    checked={isEndDateChecked}
                                    onChange={() => {
                                        setIsEndDateChecked(!isEndDateChecked);
                                        if (isEndDateChecked) setEndDate(''); // Limpiar la fecha si se desactiva el checkbox
                                    }}
                                    className="mr-2"
                                />
                                Fecha de Término:
                            </label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-primary"
                            />
                        </div>
                    </div>
                    <div className="col-span-1 relative">
                        <label className="font-semibold mb-2 block flex items-center">
                            <input
                                type="checkbox"
                                checked={isCategoryChecked}
                                onChange={() => setIsCategoryChecked(!isCategoryChecked)}
                                className="mr-2"
                            />
                            Filtrar por Categorías:
                        </label>
                        <button
                            type="button"
                            onClick={() => setModalOpen(true)}
                            className="bg-blue-200 border border-gray-300 px-4 py-2 rounded-lg w-full text-left hover:bg-blue-300 hover:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 shadow-md transition-all duration-300 ease-in-out transform hover:scale-105"
                        >
                            Seleccionar Categorías
                        </button>
                    </div>
                </div>
    
                {modalOpen && (
                    <div 
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                        onClick={() => setModalOpen(false)}
                    >
                        <div 
                            className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full"
                            onClick={(e) => e.stopPropagation()} 
                        >
                            <h2 className="text-xl font-bold mb-4">Selecciona Categorías</h2>
                            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
                                {categories.map(category => (
                                    <button
                                        key={category.id_category}
                                        type="button"
                                        className={`px-2 py-2 border border-gray-300 rounded-lg ${
                                            selectedCategories.includes(category.id_category)
                                                ? 'bg-custom-primary text-white'
                                                : 'bg-gray-100 text-gray-800'
                                        }`}
                                        onClick={() => handleCategoryChange(category.id_category)}
                                    >
                                        {category.name}
                                    </button>
                                ))}
                            </div>
                            <div className="mt-4 flex justify-end">
                                <button
                                    onClick={() => setModalOpen(false)}
                                    className="bg-custom-primary text-white px-4 py-2 rounded-lg"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
    
            {dateError && <p className="text-red-500 mt-2">{dateError}</p>}
    
            {/* Eventos */}
            {filteredEvents.length === 0 ? (
                <p className="text-center text-xl">No tienes eventos.</p>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {filteredEvents.map((event) => {
                        // Obtener la primera foto del evento y validarla
                        const linkPhoto = event.link_photo && event.link_photo.includes(',')
                            ? event.link_photo.split(',')[0].trim()
                            : event.link_photo;

                        const validatedPhoto = linkPhoto && linkPhoto !== 'null' && linkPhoto !== `${config.images_route}/null`
                            ? (linkPhoto.startsWith('http') ? linkPhoto : `${config.images_route}/${linkPhoto.trim()}`)
                            : defaultImage;

                        return (
                            <div key={event.id_event} className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
                                <img
                                    src={validatedPhoto}
                                    alt={event.title}
                                    className="w-full h-40 object-cover"
                                />
                                <div className="p-4 flex flex-col justify-between flex-grow">
                                    <div>
                                        <h2 className="text-2xl font-semibold mb-2 truncate">{event.title}</h2>
                                        <div className="flex items-start text-gray-600 mb-2">
                                            <CalendarIcon className="h-5 w-5 mr-2 mt-1" />
                                            <div>
                                                <p className="font-semibold">{formatDateTime(event.start_date)} - </p>
                                                <p className="font-semibold">{formatDateTime(event.end_date)}</p>
                                            </div>
                                        </div>
                                        {/* Capacidad e inscritos */}
                                        {(event.participants != null || event.capacity != null) && (
                                            <div className="flex items-center text-gray-600 mb-4">
                                                <IoIosPeople className="h-5 w-5 mr-2" />
                                                <span>
                                                    {event.participants != null ? event.participants : 'N/A'}
                                                    {event.capacity != null ? `/${event.capacity}` : ''}
                                                </span>
                                            </div>
                                        )}
                                        <p className="text-gray-700 mb-4 overflow-hidden overflow-ellipsis">{event.description}</p>
                                    </div>
                                    <button
                                        onClick={() => handleEventDetails(event.id_event)}
                                        className="w-full bg-custom-primary hover:bg-custom-primary-dark text-white font-semibold py-2 px-4 rounded-md mt-auto"
                                    >
                                        Ver Detalles
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MyEvents;
