import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './calendar_component.css';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getEventsCalendar } from '../../../services/api';
import {formatDate, formatDateShort, parseDate, formatDateTime} from '../../../services/format';

const CalendarView = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedDate, setSelectedDate] = useState(new Date()); // Selección de fecha actual
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getEventsCalendar(getAccessTokenSilently);
        setEvents(events);
      } catch (error) {
        console.error('Error al obtener eventos', error);
      }
    };

    fetchEvents();
  }, [getAccessTokenSilently]);

  const areDatesOnSameDay = (date1, date2) => {
    const d1 = parseDate(date1);
    const d2 = new Date(date2);

    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const tileContent = ({ date, view }) => {
    // Verificar si estamos en la vista de mes
    if (view === 'month') {
      const eventDetails = events.filter(event => areDatesOnSameDay(event.start_date, date));
      
      // Si hay eventos, agregar la clase 'event-day' para colorear el número del día
      if (eventDetails.length > 0) {
        return <abbr className="event-day">{date.getDate()}</abbr>;
      }
    }
    return null;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleEventDetails = (id) => {
    //console.log('Ver detalles del evento', id);
    navigate(`/detail/${id}`)
  };

  const getEventDetails = () => {
    if (!selectedDate) return <p>Selecciona una fecha para ver los eventos.</p>;

    const eventsOnSelectedDate = events.filter(event => areDatesOnSameDay(event.start_date, selectedDate));

    eventsOnSelectedDate.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    if (eventsOnSelectedDate.length === 0) {
      return <p>No tienes eventos creados en esta fecha.</p>;
    }

    return eventsOnSelectedDate.map((event) => (
      <div key={event.id_event} className="bg-white shadow-md rounded-lg p-2 mb-4 w-full">
        <h2 className="text-lg font-semibold mb-1">{event.title}</h2>
        <p className="text-gray-500 font-medium">
          {formatDateTime(event.start_date)} - {formatDateTime(event.end_date)}
        </p>
        <button
          onClick={() => handleEventDetails(event.id_event)}
          className="mt-1 bg-custom-primary hover:bg-blue-600 text-white text-sm font-semibold py-1 px-2 rounded"
        >
          Ver detalles
        </button>
      </div>
    ));
  };

  return (
    <div className="calendar-view-container flex flex-col items-center justify-center p-4">
      <div className="calendar-wrapper flex justify-center mb-8">
        <div className="w-full max-w-md">
          <Calendar
            tileContent={tileContent}
            onClickDay={handleDateClick}
            className="custom-calendar dashboard-calendar" // Ensure this class is added
            value={selectedDate} // Mantener la fecha seleccionada
            navigationLabel={({ date }) => `${format(date, 'MMMM yyyy', { locale: es }).toUpperCase()}`}
            prev2Label={<span className="arrow">‹‹</span>}
            prevLabel={<span className="arrow">‹</span>}
            nextLabel={<span className="arrow">›</span>}
            next2Label={<span className="arrow">››</span>}
            formatShortWeekday={(locale, date) => ['D', 'L', 'M', 'X', 'J', 'V', 'S'][date.getDay()]}
          />
        </div>
      </div>
      <div className="event-details-container text-center">
        <h2 className="text-xl font-semibold mb-4">
          Eventos: {selectedDate ? format(selectedDate, 'PPP', { locale: es }) : 'Selecciona una fecha'}
        </h2>
        <div className="event-list">
          {getEventDetails()}
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
