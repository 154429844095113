import React, { useState, useEffect } from 'react';
import { inviteFollowersToEvent, fetchUserGroups } from '../../services/api.js';
import { useAuth0 } from "@auth0/auth0-react";

const FollowersModalEventInvite = ({ isOpen, onClose, followers, selectedFollowers, handleFollowerSelect, eventId }) => {
    const [groups, setGroups] = useState([]); // Estado para grupos
    const [selectedGroups, setSelectedGroups] = useState([]); // Grupos seleccionados
    const [searchTerm, setSearchTerm] = useState(''); // Para buscar
    const [isInviting, setIsInviting] = useState(false);
    const [inviteError, setInviteError] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    // Cargar los grupos al abrir el modal
    useEffect(() => {
        const loadGroups = async () => {
            try {
                const token = await getAccessTokenSilently();
                const data = await fetchUserGroups(token);
                const groupsArray = Object.entries(data).map(([groupId, group]) => ({
                    ...group,
                    group_id: parseInt(groupId, 10),
                }));
                setGroups(groupsArray);
            } catch (error) {
                console.error('Error al cargar los grupos:', error);
            }
        };

        if (isOpen) {
            loadGroups();
        }
    }, [isOpen, getAccessTokenSilently]);

    const handleGroupSelect = (groupId) => {
        setSelectedGroups(prevSelectedGroups => {
            if (prevSelectedGroups.includes(groupId)) {
                return prevSelectedGroups.filter(id => id !== groupId);
            } else {
                return [...prevSelectedGroups, groupId];
            }
        });
    };

    const toggleGroupSelection = (groupId) => {
        const selectedGroup = groups?.find((g) => g.group_id === groupId); // Validar que `groups` no sea null o undefined
        if (!selectedGroup) return; // Salir si el grupo no se encuentra
    
        if (selectedGroups.includes(groupId)) {
            // Deseleccionar grupo
            handleGroupSelect(groupId);
    
            // Eliminar miembros del grupo solo si no pertenecen a otros grupos seleccionados
            selectedGroup.members?.forEach((member) => {
                const isMemberInOtherSelectedGroups = groups
                    .filter((group) => selectedGroups.includes(group.group_id) && group.group_id !== groupId)
                    .some((group) => group.members.some((m) => m.id_user === member.id_user));
    
                if (!isMemberInOtherSelectedGroups && followers.some((follower) => follower.id_user === member.id_user)) {
                    // Solo deseleccionar si el seguidor está en la lista
                    handleFollowerSelect(member.id_user, false);
                }
            });
        } else {
            // Seleccionar grupo
            handleGroupSelect(groupId);
    
            // Agregar miembros del grupo solo si están en la lista de seguidores
            selectedGroup.members?.forEach((member) => {
                if (!selectedFollowers.includes(member.id_user) && followers.some((follower) => follower.id_user === member.id_user)) {
                    handleFollowerSelect(member.id_user, true);
                }
            });
        }
    };
    
    
    
    

    // Determinar si un seguidor está deshabilitado
    const isFollowerDisabled = (followerId) => {
        return groups.some(group =>
            selectedGroups.includes(group.group_id) &&
            group.members.some(member => member.id_user === followerId)
        );
    };

    const handleInvite = async () => {
        setIsInviting(true);
        setInviteError(null);
        try {
            const token = await getAccessTokenSilently();
            await inviteFollowersToEvent(token, eventId, selectedFollowers);
            alert('Las invitaciones se enviaron correctamente!');
            onClose();
        } catch (error) {
            setInviteError('Error al enviar las invitaciones. Por favor intente de nuevo.');
        } finally {
            setIsInviting(false);
        }
    };

    // Filtrar grupos y seguidores
    const filteredGroups = groups.filter(group =>
        group.group_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredFollowers = followers.filter(follower =>
        follower.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!isOpen) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                                    Invitar Seguidores
                                </h3>
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        placeholder="Buscar por nombre"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="p-2 border border-gray-300 rounded-lg w-full mb-4"
                                    />
                                    <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
                                        {/* Sección de Grupos */}
                                        <h4 className="text-lg font-semibold mb-2">Grupos</h4>
                                        {filteredGroups.length > 0 ? (
                                            <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
                                                {filteredGroups.map((group) => (
                                                    <li
                                                        key={group.group_id}
                                                        className="flex items-center p-2 bg-gray-100 rounded-lg shadow"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedGroups.includes(group.group_id)}
                                                            onChange={() => toggleGroupSelection(group.group_id)}
                                                            className="mr-2"
                                                        />
                                                        <span>{group.group_name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className="text-gray-700 text-center">No hay grupos disponibles</p>
                                        )}
                                        {/* Sección de Seguidores */}
                                        <h4 className="text-lg font-semibold mb-2">Seguidores</h4>
                                        {filteredFollowers.length > 0 ? (
                                            <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                                {filteredFollowers.map((follower) => (
                                                    <li
                                                        key={follower.id_user}
                                                        className="flex items-center p-2 bg-gray-100 rounded-lg shadow"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="mr-2"
                                                            checked={selectedFollowers.includes(follower.id_user)}
                                                            onChange={() => handleFollowerSelect(follower.id_user)}
                                                        />
                                                        <img
                                                            src={follower.profile_link || 'https://via.placeholder.com/50'}
                                                            alt={follower.name}
                                                            className="w-10 h-10 rounded-full object-cover mr-2"
                                                        />
                                                        <span>{follower.name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className="text-gray-700 text-center">No tiene seguidores a quienes invitar</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded"
                            onClick={onClose}
                        >
                            Cerrar
                        </button>
                        <button
                            type="button"
                            className={`bg-custom-primary hover:bg-blue-700 text-white font-semibold py-2 px-4 mr-2 rounded ${isInviting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={handleInvite}
                            disabled={isInviting || selectedFollowers.length === 0}
                        >
                            {isInviting ? 'Invitando...' : 'Invitar'}
                        </button>
                    </div>
                    {inviteError && (
                        <div className="px-4 py-3 text-red-600">
                            {inviteError}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FollowersModalEventInvite;
