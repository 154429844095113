import React, { useState, useEffect } from 'react';
import { createUserGroup, getFollowers } from '../../services/api.js'; // Import required API functions
import { useAuth0 } from "@auth0/auth0-react";

const GroupsModal = ({ isOpen, onClose, followers, onGroupCreated }) => {
    const [groupName, setGroupName] = useState('');
    const [selectedFollowers, setSelectedFollowers] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [message, setMessage] = useState('');
    const { getAccessTokenSilently } = useAuth0();

    
    const handleSelectFollower = (id) => {
        if (selectedFollowers.includes(id)) {
            setSelectedFollowers(selectedFollowers.filter((followerId) => followerId !== id));
        } else {
            setSelectedFollowers([...selectedFollowers, id]);
        }
    };

    const handleCreateGroup = async () => {
        if (!groupName || selectedFollowers.length === 0) {
            setMessage('Debe proporcionar un nombre y seleccionar usuarios.');
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const newGroup = await createUserGroup(token, groupName, selectedFollowers);
            onGroupCreated(newGroup);
            setGroupName('');
            setSelectedFollowers([]);
            setMessage('Grupo creado exitosamente.');
            onClose();
        } catch (error) {
            console.error('Error creating group:', error);
            setMessage('Error al crear el grupo.');
        }
    };

    if (!isOpen) return null;

    const filteredFollowers = followers.filter((follower) =>
        follower.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-lg">
                <h2 className="text-2xl font-semibold mb-4 text-center">Crear Nuevo Grupo</h2>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-md mb-4"
                    placeholder="Nombre del Grupo"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                />
                <div className="overflow-y-auto max-h-64 mb-4">
                    <h3 className="text-lg font-semibold mb-3">Añade miembros:</h3>
                    <input
                        type="text"
                        placeholder="Buscar por nombre"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="p-2 border border-gray-300 rounded-lg w-full mb-4"
                    />
                    {filteredFollowers.length > 0 ? (
                        <ul>
                            {filteredFollowers.map((follower) => (
                                <li key={follower.id_user} className="flex items-center justify-between mb-2">
                                    <div className="flex items-center">
                                        <img
                                            src={follower.profile_link || 'https://via.placeholder.com/50'}
                                            alt={follower.name}
                                            className="w-8 h-8 rounded-full mr-2"
                                        />
                                        <span>{follower.name}</span>
                                    </div>
                                    <button
                                        className={`px-3 py-1 rounded-full ${
                                            selectedFollowers.includes(follower.id_user)
                                                ? 'bg-custom-primary text-white'
                                                : 'bg-gray-300 text-gray-700'
                                        }`}
                                        onClick={() => handleSelectFollower(follower.id_user)}
                                    >
                                        {selectedFollowers.includes(follower.id_user) ? 'Seleccionado' : 'Seleccionar'}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-500">No se encontraron seguidores.</p>
                    )}
                </div>
                {message && <p className="text-red-500 text-sm mb-4">{message}</p>}
                <div className="flex justify-end space-x-4">
                    <button onClick={onClose} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">
                        Cancelar
                    </button>
                    <button onClick={handleCreateGroup} className="bg-custom-primary text-white px-4 py-2 rounded-md">
                        Crear Grupo
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GroupsModal;