import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../configroutes';
import { getEventDetails, getEventPosts, getReviews, getAcceptedAttendees, getUserInfoById, ReportEvent, ReportEventReview, getEventQuestions, createQuestion, getUserInfo, deleteQuestion, ReportEventQuestion} from '../../services/api';
import { UsersIcon, UserGroupIcon, LocationMarkerIcon, TicketIcon, ClockIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import ReportModal from '../../components/Report/ReportModal';
import AlertReportModal from '../../components/Report/AlertReportModal';
import {formatFullDateTime, parseDate} from '../../services/format';
import { BiSolidDiscount } from "react-icons/bi";


const OtherEvent = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const { eventId } = useParams();
    const navigate = useNavigate(); // Inicializa el hook navigate
    const [event, setEvent] = useState(null);
    const [acceptedAttendees, setAcceptedAttendees] = useState([]);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [userProfile, setUserProfile] = useState(null);
    const defaultImage = '../../images/evento_default_foto.png';
    const defaultProfileImage = '../../images/profile_default_foto.png';
    const [currentIndex, setCurrentIndex] = useState(0); // Estado para la imagen actual
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [reportId, setReportId] = useState(null);
    const [reportType, setReportType] = useState({ type: null, id: null });
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState("");
    const [isEventEnded, setIsEventEnded] = useState(false);
    const [currentUserProfile, setCurrentUserProfile] = useState(null);
    const [reportErrorMessage, setReportErrorMessage] = useState("");

    // TODA LA LÓGICA DE OBTENCIÓN DE DATOS DEL EVENTO

    const formatPrice = (price) => {
        return price.toLocaleString('es-CL');
    };

    const renderDiscount = () => {
        if (!event.discount_type || !event.discount_detail) return null;
    
        const { discount_type, discount_detail, price } = event;
    
        switch (discount_type) {
            case 'tarjeta bancaria': {
                const [bank, percentage] = discount_detail.split(';');
                const discountPrice = price ? price - (price * (parseInt(percentage, 10) / 100)) : null;
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            {bank} - {percentage}%  
                            {discountPrice !== null && ` (Precio Final: $${discountPrice.toFixed(0)})`}
                        </p>
                    </div>
                );
            }
            case 'porcentaje': {
                const percentage = parseInt(discount_detail, 10);
                const discountPrice = price ? price - (price * (percentage / 100)) : null;
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            {percentage}% descuento 
                            {discountPrice !== null && ` (Precio Final: $${discountPrice.toFixed(0)})`}
                        </p>
                    </div>
                );
            }
            case 'combo': {
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            Promoción {discount_detail} 
                        </p>
                    </div>
                );
            }
            case 'fijo': {
                const fixedDiscount = parseInt(discount_detail, 10);
                const discountPrice = price ? price - fixedDiscount : null;
                return (
                    <div className="flex items-center mb-4">
                        <BiSolidDiscount className="h-6 w-6 text-custom-money-green" />
                        <p className="ml-3 text-lg font-medium text-gray-700">
                            Fijo: ${formatPrice(fixedDiscount)} 
                            {discountPrice !== null && ` (Precio Final: $${formatPrice(Math.round(discountPrice))})`}
                        </p>
                    </div>
                );
            }
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchEventDetails = async () => {
            setLoading(true);
            setMessage("");
            try {
                const token = await getAccessTokenSilently();
                const eventDetails = await getEventDetails(token, eventId);
                //console.log('Evento:', eventDetails);
                setEvent(eventDetails);
            } catch (error) {
                console.error("Error al obtener los detalles del evento:", error);
                setMessage("No se pudo obtener los detalles del evento.");
            }
            setLoading(false);
        };

        fetchEventDetails();
        fetchAttendeesEvent();
        fetchReviews();
        fetchQuestions();
        fetchCurrentUser();
        
    }, [eventId, getAccessTokenSilently]);
    
    useEffect(() => {
        const fetchUserProfile = async () => {
            if (event) { // Solo ejecuta esta función si event ya no es null
                try {
                    const token = await getAccessTokenSilently(); // Obtener el token para autenticación
                    const userInfo = await getUserInfoById(token, event.id_organizer); // Obtener los datos del usuario
                    setUserProfile(userInfo);
                    //console.log('Perfil del usuario:', userInfo);
                } catch (error) {
                    console.error('Error al cargar el perfil del usuario:', error.message); // Mostrar el error en consola
                }
            }
        };

        fetchUserProfile();
    }, [event, getAccessTokenSilently]); // Ejecuta este useEffect cuando event cambia y ya está cargado

    const isCurrentUser = userProfile && userProfile.id_user === currentUserProfile.id;

    useEffect(() => {
        if (isCurrentUser) {
            fetchPosts();
        }
    }, [userProfile, isCurrentUser, getAccessTokenSilently, eventId]);

    const fetchAttendeesEvent = async () => {
        setLoading(true);
        setMessage("");
        try {
            const token = await getAccessTokenSilently();
            const acceptedAttendeesData = await getAcceptedAttendees(token, eventId);
            setAcceptedAttendees(acceptedAttendeesData);
            setAttendeesCount(acceptedAttendeesData.length);
        } catch (error) {
            console.error('Error fetching attendees', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchQuestions = async () => {
        try {
            const token = await getAccessTokenSilently();
            const eventQuestions = await getEventQuestions(token, eventId);
            setQuestions(eventQuestions);
            //console.log('Preguntas:', eventQuestions);
        } catch (error) {
            console.error('Error al obtener las preguntas:', error);
        }
    }

    const fetchPosts = async () => {
        try {
            const token = await getAccessTokenSilently();
            const eventPosts = await getEventPosts(token, eventId);
            setPosts(eventPosts);
        } catch (error) {
            console.error('Error al obtener los posts:', error);
        }
    };

    const fetchReviews = async () => {
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const reviewsData = await getReviews(token, eventId);
            setReviews(reviewsData);
        } catch (error) {
            console.error('Error al obtener las reseñas:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCurrentUser = async () => {
        try {
            const token = await getAccessTokenSilently();
            const userInfo = await getUserInfo(token);
            setCurrentUserProfile(userInfo);
            //console.log('Usuario actual:', userInfo);
        } catch (error) {
            console.error('Error al obtener la información del usuario:', error);
        }
    }



    // FORMATO
    const formatUrl = (url) => {
        if (!url || !url.startsWith('http://') && !url.startsWith('https://')) {
            return 'http://' + url;
        }
        return url;
    };


    // GOOGLE MAP
    const openMap = (location) => {
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
        window.open(url, '_blank');
    };

    // REPORT
    const handleReport = () => {
        setIsReportModalOpen(true);
        setReportType({ type: 'evento', id: eventId });
    };

    const handleReportReview = (reviewId) => {
        setReportId(reviewId); // Guarda el ID de la reseña a reportar
        setIsReportModalOpen(true);  // Abre el modal de reporte
        setReportType({ type: 'reseña', id: reviewId });
    };

    const handleReportQuestion = (questionId) => {
        setReportId(questionId); // Guarda el ID de la reseña a reportar
        setIsReportModalOpen(true);  // Abre el modal de reporte
        setReportType({ type: 'pregunta', id: questionId });
    };

    const handleReportSubmit = async (reason) => {
        try {
            const token = await getAccessTokenSilently(); // Esperar el token correctamente
            let report;
            if (reportType.type === 'evento') {
                report = await ReportEvent(token, reportType.id, reason);
                //console.log("Reporte de evento enviado:", report);
            } else if (reportType.type === 'reseña') {
                report = await ReportEventReview(token, reportType.id, reason);
                //console.log("Reporte de reseña enviado:", report);
            } else if (reportType.type === 'pregunta') {
                report = await ReportEventQuestion(token, reportType.id, reason); // Implementa esta función en tu backend si no existe.
                //console.log("Reporte de pregunta enviado:", report);
            }

            if (report.alreadyReported) {
                setReportErrorMessage(report.message); // Mensaje controlado
            } else {
                //console.log("Reporte enviado exitosamente");
            }

            setIsReportModalOpen(false); // Cierra el modal
            setReportType({ type: null, id: null }); // Resetea el estado
    
        } catch (error) {
            console.error("Error al enviar el reporte:", error);
        }
    };

    // PREGUNTAS Y RESPUESTAS
    const handleQuestionSubmit = async () => {
        if (!newQuestion) {
            return;
        }
        const token = await getAccessTokenSilently();
        const newQuestionResponse = await createQuestion(token, eventId, newQuestion);
        //console.log(newQuestion)
        //console.log(newQuestionResponse);
        setNewQuestion("");
        await fetchQuestions();
    }

    const EventEnded = () => {
        if (!event || !event.end_date) return false; // Verifica si el evento o la fecha final no están disponibles
        const eventEndDate = parseDate(event.end_date);
        const now = new Date();
        return now > eventEndDate; // Es true si ya paso el evento
    };

    const handleDeleteQuestion = async (questionId) => {
        try {
            const token = await getAccessTokenSilently();
            //console.log("eliminando", questionId)
            await deleteQuestion(token, questionId);
            //await deleteQuestion(token, questionId); // Llama a la API para eliminar la pregunta
            await fetchQuestions(); // Refresca la lista de preguntas después de eliminar
        } catch (error) {
            console.error('Error al eliminar la pregunta:', error);
        }
    };


    // Ir a otras páginas
    const handleBack = () => {
        navigate(-1);
    };

    const handleGoToEventDetails = () => {
        //console.log('Ir a los detalles de mi evento');
        navigate(`/detail/${eventId}`, { state: { from: 'OtherEvent' } });
    };

    

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!event) {
        return <div>No se encontraron detalles del evento.</div>;
    }

    // FOTOS

    const photos = event.link_photo && event.link_photo.includes(',')
    ? event.link_photo.split(',').map(photo => photo.trim())  // Dividimos por comas y aplicamos trim() a cada elemento
    : [event.link_photo];  // Convertimos una imagen única en un array

    const totalImages = photos.length;

    const nextImage = () => {
        setCurrentIndex((currentIndex + 1) % totalImages); // Avanza y si es la última, vuelve a la primera
    };

    const prevImage = () => {
        setCurrentIndex((currentIndex - 1 + totalImages) % totalImages); // Retrocede y si es la primera, va a la última
    };

    const selectImage = (index) => {
        setCurrentIndex(index);
    };

    const validatedPhotos = photos && photos.length > 0
        ? photos.map(photo => {
            if (photo === `${config.images_route}/null`) {
                return defaultImage;
            }
            return photo && photo.startsWith('http')
                ? photo  
                : `${config.images_route}/${photo.trim()}`;
        })
        : [defaultImage]; 

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <div className="flex items-center mb-4">
                <button onClick={handleBack} className="flex items-center text-gray-500 hover:text-black">
                    <ArrowLeftIcon className="h-6 w-6" />
                    <span className="ml-2">Volver</span>
                </button>
            </div>

            <div>
                {/* Mostrar las imágenes del evento */}
                {totalImages > 0 ? (
                    <div className="flex overflow-hidden relative">
                        {/* Imagen actual */} 
                        <img
                            src={validatedPhotos[currentIndex]}
                            alt={`Imagen ${currentIndex + 1}`}
                            className="mb-4 w-full h-80 object-cover rounded-lg shadow-lg"
                        />
                        {/* Botón para ir a la imagen anterior */}
                        <button
                            onClick={prevImage}
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full"
                        >
                            &lt;
                        </button>
                        {/* Botón para ir a la siguiente imagen */}
                        <button
                            onClick={nextImage}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full"
                        >
                            &gt;
                        </button>
                        {/* Indicadores de las imágenes */}
                        <div className="absolute bottom-6 right-2 flex gap-x-1.2">
                            {photos.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => selectImage(index)}
                                    className={`mx-1 w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-400'}`}
                                />
                            ))}
                        </div> 
                    </div>
                ) : (
                    <img
                        src={defaultImage}
                        alt="Evento por defecto"
                        className="mb-4 w-full h-60 object-cover rounded-lg shadow-lg"
                    />
                )}
            </div>

            {/* Botón para ir a los detalles de mi evento si el usuario es el organizador */}
            {isCurrentUser && (
                <button
                    onClick={handleGoToEventDetails}  // Ajusta la ruta si es necesario
                    className="mb-4 bg-custom-blue text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                >
                    Detalles de mi Evento
                </button>
            )}

            {/* Título y descripción del evento */}
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-3xl font-bold">{event.title}</h1>
                {!isCurrentUser && (
                    <button
                        onClick={handleReport}
                        className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
                    >
                        Reportar
                    </button>
                )}
            </div>

            <ReportModal 
                isOpen={isReportModalOpen} 
                onClose={() => setIsReportModalOpen(false)} 
                onSubmit={handleReportSubmit} 
            />
            <p className="text-lg mb-4">{event.description}</p>



            {/* Tipo de evento (Público o Privado) */}
            <div className="flex items-center space-x-2 mb-4">
                {event.event_type === 'public' ? (
                    <UsersIcon className="h-6 w-6 text-green-600" />
                ) : (
                    <UsersIcon className="h-6 w-6 text-custom-wine-red" />
                )}
                <p>{event.event_type === 'public' ? 'Evento Público' : 'Evento Privado'}</p>
            </div>

            {/* Categorías del evento */}
            <div className="flex flex-wrap items-center space-x-2 mb-4">
                {event.categories?.length > 0 ? (
                    event.categories.map((category, index) => (
                        <span
                            key={index}
                            className="bg-blue-100 text-custom-blue font-medium px-3 py-1 rounded-full shadow-sm"
                        >
                            {category.name}
                        </span>
                    ))
                ) : (
                    <p>Sin categorías</p>
                )}
            </div>
            
            {/* Línea separadora */}
            <hr className="my-4" />

            {/* Organizador del evento */}
            <div className="flex items-center space-x-2 mb-4 text-lg font-medium text-gray-700">
                <p className="text-md"><strong>Organizador:</strong></p>
                <Link to={`/profile/${event.id_organizer}`} className="text-gray-700 block hover:text-custom-blue hover:underline">
                    {event.organizer}
                </Link>
            </div>

            <hr className="my-4" />
            {/* Línea separadora */}

            {/* Horario del evento */}
            <div className="flex items-center space-x-2 mb-4 text-lg font-medium text-gray-700">
                <ClockIcon className="h-6 w-6 text-custom-wine-red" />
                <p>{`${formatFullDateTime(event.start_date)} - ${formatFullDateTime(event.end_date)}`}</p>
            </div>

            {/* Ubicación del evento (modificada para abrir Google Maps) */}
            <div className="flex items-center space-x-2 mb-4">
                <LocationMarkerIcon className="h-6 w-6 text-custom-wine-red" />
                <span className="text-md font-medium text-gray-700 hover:underline hover:text-custom-blue cursor-pointer" onClick={() => openMap(event.location)}>
                    {event.location}
                </span>
            </div>

            {/* Capacidad y asistentes confirmados */}
            <div className="flex items-center space-x-2 mb-4">
                <UserGroupIcon className="h-6 w-6 text-custom-blue" />
                <p className="text-lg font-medium text-gray-700">
                    {event.capacity ? `${attendeesCount} de ${event.capacity} Asistentes` : `${attendeesCount} Asistentes`}
                </p>
            </div>

            {/* Precio del evento */}
            <div className="flex items-center space-x-2 mb-4">
                <TicketIcon className="h-6 w-6 text-custom-money-green" />
                <p className="text-lg font-medium text-gray-700">
                    Precio: {event.price ? `$${formatPrice(event.price)}` : 'Gratis'}
                </p>
            </div>
            {/* Descuento (solo si discount_type no es null) */}
            {renderDiscount()}
            
            <hr className="my-4" />
            {/* Línea separadora */}

            {/* Sección de asistentes */}
            <h2 className="text-xl font-semibold mt-6">Asistentes</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
                {acceptedAttendees.length > 0 ? (
                    acceptedAttendees.map((attendee, index) => (
                        <Link 
                            to={`/profile/${attendee.id_user}`} // Redirige a la página de perfil del asistente
                            key={index}
                            className="flex flex-col items-center bg-white border border-gray-200 p-4 rounded-lg shadow-md hover:bg-gray-100 transition-colors duration-300 flex-grow flex-basis-0"
                        >
                            <img 
                                src={attendee.profile_link ? attendee.profile_link : '/images/imagen_perfil_default.png'} 
                                alt={`Avatar de ${attendee.name}`} 
                                className="w-16 h-16 rounded-full object-cover" 
                            />
                            <div className="ml-4">
                                <p className="font-bold text-gray-800 text-lg mb-1 truncate max-w-full overflow-hidden">{attendee.name}</p>
                            </div>
                        </Link>
                    ))
                ) : (
                    <p>No hay asistentes confirmados.</p>
                )}
            </div>

            {/* Sección de posts */}
            {isCurrentUser && (
                <div>
                    <h2 className="text-xl font-semibold mt-6">Avisos</h2>
                    <div className="space-y-4">
                        {posts.length > 0 ? (
                            posts.map((post, index) => (
                                <div key={index} className="p-6 border border-gray-300 rounded-lg shadow-md bg-white">
                                    <h3 className="text-lg font-bold text-blue-600 mb-2">{post.title}</h3>
                                    <p className="text-gray-700 mb-4">{post.content}</p>
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm text-gray-500">Publicado el: {new Date(post.created_at).toLocaleDateString('es-ES', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No hay posts.</p>
                        )}
                    </div>
                </div>
            )}


            {/* Sección de reseñas */}
            <h2 className="text-xl font-semibold mt-6">Reseñas</h2>
            <div className="space-y-4">
                {reviews.length > 0 ? (
                    reviews.map((review, index) => (
                        <div key={index} className="p-4 border rounded shadow-sm bg-gray-50 relative">
                
                            {/* Botón de reportar en la esquina superior derecha de cada reseña */}
                            <button
                                onClick={() => handleReportReview(review.id_review)} // Llama a la función de reportar con el ID de la reseña
                                className="absolute top-2 right-2 hover:text-red-600 py-2 px-3 bg-red-500 text-white text-sm rounded-md "
                            >
                                Reportar
                            </button>

                            <div className="flex items-center space-x-2 mb-2">
                                <p className="font-bold text-lg">{review.user}</p>
                    
                                {/* Mostrar las estrellas basadas en el rate */}
                                <div className="flex">
                                    {Array.from({ length: review.rate }).map((_, i) => (
                                        <span key={i} className="text-yellow-400">★</span>
                                    ))}
                                    {Array.from({ length: 5 - review.rate }).map((_, i) => (
                                        <span key={i} className="text-gray-300">★</span>
                                    ))}
                                </div>
                            </div>
                            <p className="text-gray-700">{review.content}</p>
                            <p className="text-sm text-gray-500 mt-2">
                                {new Date(review.created_at).toLocaleDateString('es-ES', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>No hay reseñas.</p>
                )}
            </div>

            {/* Sección de preguntas */}
            <h2 className="text-xl font-semibold mt-8 mb-4 text-gray-800">Preguntas</h2>
            <div className="space-y-4">
                {questions.length > 0 ? (
                    questions
                        .filter(question => question.is_active)
                        .map((question, index) => (
                        <div key={index} className="p-4 border border-gray-200 rounded-md bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-300">
                            <div className="flex justify-between items-start">
                                {/* Contenido de la pregunta */}
                                <div>
                                    <p className="text-gray-800 font-medium text-sm">{question.question}</p>
                                    <div className="text-xs text-gray-400 mt-1">
                                        {new Date(question.created_at).toLocaleDateString('es-ES', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </div>
                                </div>

                                {/* Botones de acción */}
                                <div className="flex flex-col items-end space-y-2">
                                    {/* Mostrar el botón de eliminar solo si el usuario actual es el creador */}
                                    {user && question.id_user === currentUserProfile.id ? (
                                        <button
                                            onClick={() => handleDeleteQuestion(question.id_question)}
                                            className="bg-blue-500 text-white py-2 px-3 rounded-md hover:bg-blue-600 text-sm"
                                        >
                                            Eliminar
                                        </button>
                                    ) : (
                                        // Mostrar el botón de reportar solo si el usuario no es el creador
                                        <button
                                            onClick={() => handleReportQuestion(question.id_question)}
                                            className="bg-red-500 text-white py-2 px-3 rounded-md hover:bg-red-600 text-sm"
                                        >
                                            Reportar
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Respuesta a la pregunta */}
                            <div className="mt-2 flex flex-col text-sm">
                                {question.reply ? (
                                    <>
                                        <p className="text-gray-700 border-l-4 border-blue-500 pl-2 flex-1">{question.reply}</p>
                                        <div className="text-xs text-gray-400 mt-1">
                                            {new Date(question.updated_at).toLocaleDateString('es-ES', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <p className="text-gray-500 italic">Aún no tiene respuesta</p>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500 italic">No hay preguntas.</p>
                )}
            </div>



            {/* Cuadro de texto para hacer una nueva pregunta con contador de caracteres */}
            {!EventEnded() && !isCurrentUser && (
                <div className="mt-6 p-4 border border-gray-200 rounded-md bg-white shadow-sm">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">Haz una pregunta</h3>
                    <textarea
                        className="w-full border border-gray-300 rounded-md p-2 mb-2 focus:outline-none focus:border-blue-500"
                        rows="3"
                        maxLength="500"
                        placeholder="Escribe tu pregunta aquí..."
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                    ></textarea>
                    <div className="flex justify-between items-center text-sm text-gray-600 mb-2">
                        <span>{500 - newQuestion.length} caracteres restantes</span>
                    </div>
                    <button
                        onClick={handleQuestionSubmit}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                    >
                        Enviar pregunta
                    </button>
                </div>
            )}
            <AlertReportModal
                isOpen={!!reportErrorMessage}
                title="Error"
                message={reportErrorMessage}
                onClose={() => setReportErrorMessage("")}
            />
        </div>
        
    );
};

export default OtherEvent;