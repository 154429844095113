import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { getEvents, getUserInfo } from '../../services/api';
import { formatDateTime, formatTimeRange, parseDate } from '../../services/format';
import CalendarView from './Calendar/dashboard_calendar'; // Componente de calendario
import Metrics from './Metrics/metrics'; // Componente de métricas
import config from '../../configroutes';

const MyEventsWithCalendar = () => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  
  // Estado para el filtro por fecha
  const today = new Date().toLocaleDateString('en-CA');

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');

  const navigate = useNavigate();

  // Definir la ruta de la imagen por defecto
  const defaultImage = '/images/evento_default.png';

  // Fetch events
  const fetchEvents = async () => {
    setLoading(true);
    try {
      if (!user) {
        console.error("User is undefined");
        setLoading(false);
        return;
      }
      const token = await getAccessTokenSilently();
      const updatedEvents = await getEvents(token, user.sub);
      setEvents(updatedEvents);
    } catch (error) {
      //console.log(error, "Error al obtener eventos");
    } finally {
      setLoading(false);
    }
  };

  // Fetch user info
  const fetchUserInfo = async () => {
    try {
      const token = await getAccessTokenSilently();
      const fetchedUserInfo = await getUserInfo(token);
      setUserInfo(fetchedUserInfo);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    if (!isLoading && user) {
      fetchUserInfo();
      fetchEvents();
    }
  }, [isLoading, user]);

  // Filtro por fechas y término de búsqueda
  const filteredEvents = events.filter(event => {
    const eventStartDate = parseDate(event.start_date);
    const eventEndDate = parseDate(event.end_date);
    const startDateObj = startDate ? new Date(`${startDate}T00:00:00`) : null;
    let endDateObj = endDate ? new Date(endDate) : null;
    
    if (endDateObj) {
      endDateObj.setDate(endDateObj.getDate() + 1);
      endDateObj.setHours(23, 59, 59, 999);
    }
    
    const isWithinDateRange = (!startDateObj || (eventStartDate >= startDateObj && eventEndDate >= startDateObj)) &&
                              (!endDateObj || (eventStartDate <= endDateObj && eventEndDate <= endDateObj));

    return event.title.toLowerCase().includes(searchTerm.toLowerCase()) && isWithinDateRange;
  });

  // Control del cambio en fecha de fin
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) < new Date(startDate)) {
      setDateError('La fecha de término no puede ser menor que la fecha de inicio.');
    } else {
      setDateError('');
      setEndDate(newEndDate);
    }
  };

  const handleEventDetails = (id) => {
    navigate(`/detail/${id}`, { state: { from: 'Dashboard' } });
  };

  return (
    <div className="min-h-screen p-4 grid grid-cols-10 gap-6">
      {/* Columna de Métricas */}
      <div className="col-span-10 lg:col-span-3 bg-white p-4 rounded-lg shadow-md">
        <h1 
          className="flex items-center justify-center text-3xl font-bold mb-4 text-center text-custom-blue cursor-pointer hover:underline hover:text-blue-700 transition duration-200"
          onClick={() => navigate('/metrics')}
        >
          Métricas
        </h1>
        <hr className="mb-4 border-gray-300" />
        <Metrics /> 
      </div>

      {/* Columna de Mis Eventos */}
      <div className="col-span-10 lg:col-span-4 bg-white p-4 rounded-lg shadow-md">
        <h1 
          className="flex items-center justify-center text-3xl font-bold mb-4 text-center text-custom-blue cursor-pointer hover:underline hover:text-blue-700 transition duration-200"
          onClick={() => navigate('/my_events')}
        >
          Mis Eventos
        </h1>
        <hr className="mb-4 border-gray-300" />
        {/* Buscador y Filtros de fecha */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Buscar por Nombre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border border-gray-300 rounded-lg w-full"
          />
          <div className="flex space-x-4 items-center mt-4">
            <label className="font-semibold">Fecha de Inicio:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)}}
              className="p-2 border border-gray-300 rounded-lg"
            />
            <label className="font-semibold">Fecha de Término:</label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="p-2 border border-gray-300 rounded-lg"
            />
          </div>
          {dateError && <p className="text-red-500">{dateError}</p>}
        </div>
        {/* Lista de eventos */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {loading ? (
            <p>Cargando...</p>
          ) : filteredEvents.length === 0 ? (
            <p>No tienes eventos en las fechas escogidas</p>
          ) : (
            filteredEvents.map((event) => {
              // Obtener la primera foto del evento y validarla
              const linkPhoto = event.link_photo && event.link_photo.includes(',')
                ? event.link_photo.split(',')[0].trim()
                : event.link_photo;

              const validatedPhoto = linkPhoto && linkPhoto !== 'null' && linkPhoto !== `${config.images_route}/null`
                ? (linkPhoto.startsWith('http') ? linkPhoto : `${config.images_route}/${linkPhoto.trim()}`)
                : defaultImage;

              return (
              <div 
                key={event.id_event} 
                className="bg-cover bg-center relative bg-no-repeat shadow-md rounded-lg overflow-hidden p-4"
                style={{
                  backgroundImage: `url(${validatedPhoto})`
                }}
              >
                <div className="bg-black bg-opacity-50 p-4 rounded-lg">
                  <h2 className="text-xl font-semibold text-white mb-2 truncate">{event.title}</h2>
                  <p className="text-white font-semibold">{formatDateTime(event.start_date)} - </p>
                  <p className="text-white font-semibold">{formatDateTime(event.end_date)} </p>
                  <button
                    onClick={() => handleEventDetails(event.id_event)}
                    className="mt-2 bg-custom-primary hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                  >
                    Ver Detalles
                  </button>
                </div>
              </div>
            )})
          )}
        </div>
      </div>

      {/* Columna de Calendario */}
      <div className="col-span-10 lg:col-span-3 bg-white p-4 rounded-lg shadow-md">
        <h1 
          className="flex items-center justify-center text-3xl font-bold mb-4 text-center text-custom-blue cursor-pointer hover:underline hover:text-blue-700 transition duration-200"
          onClick={() => navigate('/calendar')}
        >
          Calendario
        </h1>
        <hr className="mb-4 border-gray-300" />
        <CalendarView />
      </div>
    </div>
  );
};

export default MyEventsWithCalendar;
