import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import { getEventDetails, getUserInfo, fetchCategories, updateEvent } from '../../services/api';
import { formatDateWithoutZ, parseDate } from '../../services/format';


const EditEvent = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { eventId } = useParams();
    const navigate = useNavigate();
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const [event, setEvent] = useState(null);
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [location, setLocation] = useState('');
    const [url, setUrl] = useState('');
    const [typeOfEvent, setTypeOfEvent] = useState('public');
    const [capacity, setCapacity] = useState('');
    const [price, setPrice] = useState('')
    const [imageFiles, setImageFiles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dates, setDates] = useState([{ start_date: null, end_date: null }]);

    const [autocomplete, setAutocomplete] = useState(null);


    const [remainingTitleChars, setRemainingTitleChars] = useState(50);
    const [remainingDescChars, setRemainingDescChars] = useState(800);
   
   
    const [userPrivacy, setUserPrivacy] = useState(false);
    const [errors, setErrors] = useState({});

    const [isActivationExpirationActive, setActivationExpirationActive] = useState(false);
    const [activationDate, setActivationDate] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [linkOfAPicture, setLinkOfAPicture] = useState(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleApiKey,
        libraries: ["places"],
    });

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const token = await getAccessTokenSilently();
                const fetchedEvent = await getEventDetails(token, eventId);
                setEvent(fetchedEvent);
                //console.log(fetchedEvent);

                
                setEventName(fetchedEvent.title);
                setEventDescription(fetchedEvent.description);
                setLocation(fetchedEvent.location);
                setUrl(fetchedEvent.url);
                setTypeOfEvent(fetchedEvent.event_type);
                setCapacity(fetchedEvent.capacity);
                setPrice(fetchedEvent.price);
                setSelectedCategories(fetchedEvent.categories.map(category => category.id_category));
                setStartDate(parseDate(fetchedEvent.start_date));
                setEndDate(parseDate(fetchedEvent.end_date));
                setDates([
                    { start_date: parseDate(fetchedEvent.start_date), end_date: parseDate(fetchedEvent.end_date) } // Nueva fecha
                ]);
                const defaultPhotoUrl = "7a85703e112c44c486deb44a902d445adefault3";
                setLinkOfAPicture(fetchedEvent.link_photo === defaultPhotoUrl ? '' : fetchedEvent.link_photo);

                //console.log("fetchedEvent", fetchedEvent)

                setRemainingTitleChars(50 - fetchedEvent.title.length); // Ajusta según el límite de caracteres
                setRemainingDescChars(800 - fetchedEvent.description.length); // Ajusta según el límite de caracteres



                


            } catch (error) {
                console.error('Error al obtener evento:', error);
            }
        };

        const loadCategories = async () => {
            try {
                const token = await getAccessTokenSilently();
                const fetchedCategories = await fetchCategories(token);
                setCategories(fetchedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchUserPrivacy = async () => {
            const token = await getAccessTokenSilently();
            const userInfo = await getUserInfo(token);
            setUserPrivacy(userInfo.profile_privacy); 
        }

        fetchEvent();
        loadCategories();
        fetchUserPrivacy();
    }
    , [getAccessTokenSilently, eventId]);


    const handleEventName = (e) => {
        const value = e.target.value.slice(0, 50); // Limita a 30 caracteres
        setEventName(value);
        setRemainingTitleChars(50 - value.length);
    };

    const handleEventDescription = (e) => {
        const value = e.target.value.slice(0, 800); // Limita a 400 caracteres
        setEventDescription(value);
        setRemainingDescChars(800 - value.length);
    };

    const handleLocation = (e) => setLocation(e.target.value);

    const handleAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };
    
    const handlePlaceSelect = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            setLocation(place.formatted_address);
        }
    };

    const handleUrl = (e) => setUrl(e.target.value);

    const handleType = (e) => {
        setTypeOfEvent(e.target.value);
    };

    const typeOptions = userPrivacy
    ? { 
        public: { label: 'Público', description: 'Todos tus seguidores podrán ver este evento' }, 
        private: { label: 'Privado', description: 'Solo usuarios invitados podrán ver este evento' } 
      }
    : { 
        public: { label: 'Público', description: 'Cualquier usuario podrá ver este evento' }, 
        private: { label: 'Privado', description: 'Solo usuarios invitados podrán ver este evento' }, 
      };




           
    const handleCapacity = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && (value === '' || parseInt(value) <= 2000)) {
            setCapacity(value);
        }
    };



    const handlePrice = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setPrice(value);
        }
    };

    // Cateogorías del evento
    const handleCategorySelect = (categoryId) => {
        setSelectedCategories(prevSelectedCategories => {
            if (prevSelectedCategories.includes(categoryId)) {
                return prevSelectedCategories.filter(id => id !== categoryId);
            } else if (prevSelectedCategories.length < 3) {
                return [...prevSelectedCategories, categoryId];
            }
            return prevSelectedCategories;
        });
    };

    const handleDate = (index, field, value) => {
        setDates(currentDates => {
            const newDates = [...currentDates];
            const currentEntry = { ...newDates[index] };

            if (field === 'start_date') {
                const now = new Date();
                if (value < now) {
                    alert("La fecha y hora de inicio no puede ser en el pasado.");
                    return currentDates;
                }
                currentEntry.start_date = value;
                if (!currentEntry.end_date || value >= currentEntry.end_date) {
                    const newEndDate = new Date(value);
                    newEndDate.setHours(newEndDate.getHours());
                    currentEntry.end_date = newEndDate;
                }
            } else if (field === 'end_date') {
                if (value < currentEntry.start_date) {
                    value = new Date(currentEntry.start_date.getTime());
                    value.setHours(value.getHours());
                }
                currentEntry.end_date = value;
            }
            newDates[index] = currentEntry;
            return newDates;
        });
    };

    const removeDate = index => setDates(dates.filter((_, idx) => idx !== index))
    const addDate = () => setDates([...dates, { start_date: '', end_date: '' }]);

    const handleActivationExpirationToggle = () => {
        setActivationExpirationActive(!isActivationExpirationActive);
        if (isActivationExpirationActive) {
            setActivationDate(null);
            setExpirationDate(null);
            errors.activationDate = '';
            errors.expirationDate = '';
        }
    };

    // Manejo de fechas de activación (opcional)
    const handleActivationDateChange = (date) => {
        const now = new Date();
        if (date < now) {
            alert("La fecha y hora de activación no puede ser en el pasado.");
            return;
        }
        setActivationDate(date);
    };

    // Manejo de fechas de expiración (opcional)
    const handleExpirationDateChange = (date) => {
        setExpirationDate(date);
    };

    const handleSubmit = async (e) => {
        //console.log('submit');
        //console.log("price", price)
        //console.log("capacity", capacity)
        //console.log("url", url)
        //console.log(imageFiles)
        e.preventDefault();
        if (!validateForm()) return;

    
        setIsCreating(true);

        // Campos no obligatorios
        console.log("capacity", capacity)
        const finalPrice = (price === '' || price === null || isNaN(price)) ? null : parseInt(price, 10);
        const finalCapacity = (capacity === null || capacity === undefined || String(capacity).trim() === '') 
            ? 2000 
            : parseInt(capacity, 10);
        const finalUrl = url === null || url.trim() === '' ? null : url;
        console.log("capacity 2", finalCapacity)
   

        //console.log("start_date", dates[0].start_date)
        //console.log("end_date", dates[0].end_date)
        

    
        const requestBody = {
            title: eventName,
            description: eventDescription,
            location: location,
            url: finalUrl,
            event_type: typeOfEvent,
            capacity: finalCapacity,
            status: "active",
            price: finalPrice,
            open_chat: true,
            visibility_attendees: true,
            start_date: formatDateWithoutZ(dates[0].start_date),
            end_date: formatDateWithoutZ(dates[0].end_date),
            categories: selectedCategories,
        };

        if (isActivationExpirationActive) {
            requestBody.activation_date = formatDateWithoutZ(activationDate);
            requestBody.expiration_date = formatDateWithoutZ(expirationDate);
        }

        try {
            const token = await getAccessTokenSilently();


            //console.log('requestBody:', requestBody);
            const response = await updateEvent(token, eventId, requestBody, imageFiles);
            //console.log('Evento creado:', response);

            navigate('/my_events');
        } catch (error) {
            console.error('Error creating event:', error);
            setIsCreating(false);
            alert('No se pudo crear el evento. Por favor revise los campos adjuntos e inténtelo de nuevo.');
        }
    };

    // Obligatorio -> Nombre, descripción, Ubicación, tipo, status, open_chat, visibility_attendees, fecha, categorias
    const validateForm = () => {
        const errors = {};
        let errorMessage = '';

        // Validación del nombre del evento
        if (!eventName.trim()) {
            errors.eventName = 'Debe ingresar un nombre para el evento';
            errorMessage += errors.eventName + '\n';
        }

        //console.log("paso")

    // Validación de fechas
    if (!dates.length) {
        errors.dates = 'Debe agregar al menos una fecha de inicio y término';
        errorMessage += errors.dates + '\n';
    } else {
        dates.forEach((date, index) => {
            if (!date.start_date) {
                errors[`dates_start_${index}`] = `Debe ingresar una fecha de inicio para la fecha ${index + 1}`;
                errorMessage += errors[`dates_start_${index}`] + '\n';
            }
            if (!date.end_date) {
                errors[`dates_end_${index}`] = `Debe ingresar una fecha de término para la fecha ${index + 1}`;
                errorMessage += errors[`dates_end_${index}`] + '\n';
            }
            if (date.start_date && date.end_date && date.start_date >= date.end_date) {
                errors[`dates_${index}`] = `La fecha de inicio debe ser anterior a la fecha de término (${index + 1})`;
                errorMessage += errors[`dates_${index}`] + '\n';
            }
        });
    }

    // Validación de categorías
    if (selectedCategories.length === 0) {
        errors.categories = 'Debe seleccionar al menos una categoría para el evento';
        errorMessage += errors.categories + '\n';
    }

    // Validación de la ubicación
    if (!location.trim()) {
        errors.location = 'Debe ingresar una ubicación para el evento';
        errorMessage += errors.location + '\n';
    }

    // Validación de la descripción
    if (!eventDescription.trim()) {
        errors.eventDescription = 'Debe ingresar una descripción para el evento';
        errorMessage += errors.eventDescription + '\n';
    }

    // Validación de activación/expiración (opcional)
    if (isActivationExpirationActive) {
        if (!activationDate) {
            errors.activationDate = 'Debe ingresar una fecha de activación';
            errorMessage += errors.activationDate + '\n';
        }
        if (!expirationDate) {
            errors.expirationDate = 'Debe ingresar una fecha de expiración';
            errorMessage += errors.expirationDate + '\n';
        }
        if (activationDate && expirationDate && expirationDate <= activationDate) {
            errors.expirationDate = 'La fecha de expiración debe ser posterior a la fecha de activación';
            errorMessage += errors.expirationDate + '\n';
        }
    }


        if (errorMessage) {
            alert('Por favor, complete lo siguiente:\n' + errorMessage);
            setErrors(errors); // Actualizar los errores para mostrar en el formulario
        }

        //console.log('errors:', errors);
        // Actualizar los errores y devolver el resultado
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Manejo de imagenes
    const handleImage = (e) => {
        const selectedFiles = Array.from(e.target.files); // Convierte los archivos seleccionados en un array
            
        // Acumula las nuevas imágenes con las anteriores y limita a 4 en total
        setImageFiles((prevImages) => {
            const updatedImages = [...prevImages, ...selectedFiles] // Sumar y limitar a 4 imágenes
            // Evitar agregar duplicados (comparar por nombre de archivo)
        const uniqueImages = updatedImages.reduce((acc, current) => {
            if (!acc.some(file => file.name === current.name)) {
                acc.push(current);
            }
            return acc;
        }, []);
        //('Imágenes únicas actualizadas:', uniqueImages);
        return uniqueImages.slice(0, 4);
        });
    };

    const handleRemoveImage = (index) => {
        setImageFiles(prevImages => prevImages.filter((_, i) => i !== index)); // Elimina la imagen por índice
    };



    

    

    return (
        <div className="min-h-screen bg-gray-100 pt-4 flex justify-center">
            <div className="max-w-4xl p-6 bg-white rounded-lg shadow-lg">
                <div className="bg-white-700 p-4 rounded-md mb-6 text-center">
                    <h1 className="text-4xl font-bold mb-8 text-custom-blue text-center">Editar Evento</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nombre del evento(<span className="text-red-500">*</span>):</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={eventName}
                            onChange={handleEventName}
                            placeholder="Escribe el título del evento"
                        />
                        {errors.eventName && <p className="text-red-500 text-sm">{errors.eventName}</p>} 
                        <p className="text-gray-500 text-xs mt-1">
                            {remainingTitleChars} caracteres restantes
                        </p>
                    </div>


                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Descripción del evento: (<span className="text-red-500">*</span>):</label>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded-md text-justify"
                            value={eventDescription}
                            onChange={handleEventDescription}
                            rows={3}
                            placeholder="Escribe una breve descripción del evento"
                        />
                        {errors.eventDescription && <p className="text-red-500 text-sm">{errors.eventDescription}</p>}
                        <p className="text-gray-500 text-xs mt-1">
                            {remainingDescChars} caracteres restantes
                        </p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Ubicación del evento: (<span className="text-red-500">*</span>):
                        </label>
                        {isLoaded ? (
                            <Autocomplete
                                onLoad={handleAutocompleteLoad}
                                onPlaceChanged={handlePlaceSelect}
                            >
                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    placeholder="Ingrese la ubicación del evento"
                                    value={location}
                                    onChange={handleLocation}
                                />
                            </Autocomplete>
                        ) : (
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Cargando ubicación..."
                                disabled
                            />
                        )}
                        {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">URL del evento:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-md" value={url} onChange={handleUrl} placeholder="" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Tipo de evento(<span className="text-red-500">*</span>):</label>
                    <select 
                            id="eventType" 
                            value={typeOfEvent} 
                            onChange={handleType} 
                            className="w-full bg-white border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 text-back-700 text-sm"
                        >
                            {Object.entries(typeOptions).map(([value, { label }]) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>

                        <div className="mt-2">
                            {Object.entries(typeOptions).map(([_, { label, description }]) => (
                                <p key={label} className="text-gray-500 text-xs mt-1">
                                    <strong>{label}:</strong> {description}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Foto del evento:</label>
                        <div>
                            {/* Input para seleccionar las imágenes */}
                            <input
                                type="file"
                                multiple
                                className="w-full p-2 border border-gray-300 rounded-md"
                                accept="image/png, image/jpeg"
                                onChange= {handleImage}
                            />
                            <p className="text-yellow-500 text-sm mt-2">
                                Al editar este evento, las fotos actuales serán eliminadas. Por favor, sube nuevas imágenes si deseas que se mantengan visibles.
                                </p>

                            {/* Mostrar las imágenes seleccionadas */}
                            <div className="mt-4">
                                {imageFiles.length > 0 && (
                                    <ul>
                                        {imageFiles.map((file, index) => (
                                            <li key={index} className="mb-2">
                                                {file.name} {/* Nombre de la imagen */}
                                                <button
                                                    onClick={() => handleRemoveImage(index)} // Botón para eliminar la imagen
                                                    className="ml-4 text-red-600"
                                                >
                                                    Eliminar
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <p className="text-gray-500 text-xs mt-1">Formatos aceptados: jpg, jpeg, png.</p>
                        <p className="text-gray-500 text-xs mt-1">Si no se proporciona una imagen válida, se usará una imagen por defecto.</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Capacidad del evento:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-md" value={capacity} onChange={handleCapacity} />
                        <p className="text-gray-500 text-xs mt-1">La capacidad máxima permitida es de 2000 personas.</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Precio del evento:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-md" value={price} onChange={handlePrice} />
                        <p className="text-gray-500 text-xs mt-1">Si no se proporciona un precio, por defecto el evento tendrá precio cero.</p>
                    </div>

                    <div className="mb-8">
                        <label className="block text-gray-700 text-sm font-bold">Categorías del evento(<span className="text-red-500">*</span>):</label>
                        <p className="text-gray-500 text-xs mt-1 mb-4">Puedes seleccionar hasta 3 categorías.</p>
                        <div className="flex flex-wrap gap-2">
                            {categories.map((category) => (
                                <button
                                    key={category.id_category}
                                    type="button"
                                    className={`w-24 h-10 flex items-center justify-center rounded-full font-bold text-xs ${
                                        selectedCategories.includes(category.id_category) ? 'bg-custom-primary text-white' : 'bg-custom-tertiary text-custom-secondary'
                                    }`}
                                    onClick={() => handleCategorySelect(category.id_category)}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                        {errors.categories && <p className="text-red-500 text-sm mt-2">{errors.categories}</p>}
                    </div>

                    {dates.map((date, index) => (
                        <div key={index}>
                            <div className="mb-4 flex">
                                <div className="mr-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Fecha y hora de inicio {index + 1}(<span className="text-red-500">*</span>):</label>
                                    <DatePicker
                                        selected={date.start_date}
                                        onChange={date => handleDate(index, 'start_date', date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Hora"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        locale={es}
                                        minDate={new Date()}
                                    />
                                    {errors[`dates_start_${index}`] && <p className="text-red-500 text-sm mt-2">{errors[`dates_start_${index}`]}</p>}
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Fecha y hora de término {index + 1}(<span className="text-red-500">*</span>):</label>
                                    <DatePicker
                                        selected={date.end_date}
                                        onChange={date => handleDate(index, 'end_date', date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Hora"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        locale={es}
                                        minDate={date.start_date}
                                    />
                                    {errors[`dates_end_${index}`] && <p className="text-red-500 text-sm mt-2">{errors[`dates_end_${index}`]}</p>}
                                </div>
                            </div>
                            {errors[`dates_${index}`] && <p className="text-red-500 text-sm mt-2">{errors[`dates_${index}`]}</p>}

                            {index > 0 && (
                                <button type="button" onClick={() => removeDate(index)} className="mb-4 bg-red-500 text-white p-2 rounded-md">Eliminar fecha {index + 1}</button>
                            )}
                        </div>
                    ))}


                    {(typeOfEvent != 'private') && (
                        <div className="mb-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    checked={isActivationExpirationActive}
                                    onChange={handleActivationExpirationToggle}
                                />
                                <span className="ml-2">¿Desea configurar fechas de activación y expiración?</span>
                            </label>
                            <p className="text-gray-500 text-xs mt-1">Fecha de activación corresponde a la fecha mínima de inscripción. Fecha de expiración corresponde a la fecha máxima de inscripción</p>
                        </div>
                    )}
                    
                    {isActivationExpirationActive && (
                        <div className="mb-4 flex">
                            <div className="mr-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Fecha de Activación:(<span className="text-red-500">*</span>)</label>
                                <DatePicker
                                    selected={activationDate}
                                    onChange={handleActivationDateChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Hora"
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    locale={es}
                                    minDate={new Date()}
                                />
                                {errors.activationDate && <p className="text-red-500 text-sm">{errors.activationDate}</p>}
                            </div>
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">Fecha de Expiración:(<span className="text-red-500">*</span>)</label>
                                <DatePicker
                                    selected={expirationDate}
                                    onChange={handleExpirationDateChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Hora"
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    locale={es}
                                    minDate={new Date()}
                                />
                                {errors.expirationDate && <p className="text-red-500 text-sm">{errors.expirationDate}</p>}
                            </div>
                        </div>
                    )}

                    <div className="flex justify-between mt-6 gap-4">
                        <button 
                            type="button" 
                            onClick={() => navigate(-1)} 
                            className="w-1/3 bg-gray-500 text-white py-2 rounded-md hover:bg-gray-600">
                            Cancelar
                        </button>


                        <button 
                            type="submit" 
                            className="w-2/3 bg-custom-primary text-white py-2 rounded-md hover:bg-[#2C4570]">
                            Modificar
                        </button>
                    </div>

                </form>
            </div>



        </div>
    );
};

export default EditEvent;